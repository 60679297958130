import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';

function ButtonUI({
  startIcon,
  variant = 'contained',
  children,
  bgrColorBtn = '#F3F4F6',
  colorText = '#444444',
  borderBtn = '#44444447',
  textTransform = 'none',
  fontWeight = 0,
  borderRadius = 10,
  shadows = 0,
  small = false,
  onClick,
  onDoubleClick,
  ...passProps
}) {
  const props = { onClick, onDoubleClick, ...passProps };
  const themeGrey = createTheme({
    palette: {
      primary: {
        main: bgrColorBtn,
        contrastText: colorText,
      },
    },
    shape: {
      borderRadius: borderRadius,
    },
    shadows: shadows,
    typography: {
      button: {
        textTransform: textTransform,
        border: `1px solid ${borderBtn}`,
        fontWeight: fontWeight,
      },
    },
  });

  const sizeSmall = { fontSize: 10, py: 0, px: 0.5, m: 0.5 };
  return (
    <>
      <ThemeProvider theme={themeGrey}>
        <Button
          variant={variant}
          size="small"
          sx={(small && sizeSmall) || {}}
          color="primary"
          startIcon={startIcon}
          {...props}
        >
          {children}
        </Button>
      </ThemeProvider>
    </>
  );
}

ButtonUI.propTypes = {
  startIcon: PropTypes.node,
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
  bgrColorBtn: PropTypes.string,
  colorText: PropTypes.string,
  borderBtn: PropTypes.string,
  textTransform: PropTypes.string,
  fontWeight: PropTypes.number,
  borderRadius: PropTypes.number,
  shadows: PropTypes.number,
  small: PropTypes.bool,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
};
export default ButtonUI;
