import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import styles from './Datavina.module.scss';
import { Card, CardContent, Container, Grid, Paper, Stack } from '@mui/material';
import * as simService from '../../../services/apiWinServer';
import ThemeLoad from '../../../component/CustomerMUI/ThemeLoad/ThemeLoad';
import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
import * as userIDService from '../../../services/apiUsers';
import { getLocalStorage, setLocalStorage } from '../../../utils/helper';
import FrameDataSim from '../../../component/CustomerMUI/FrameInfoSim/FrameDataSim';
import { useLocation, useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 333,
  bgcolor: '#fff',
  border: '2px solid #f4367f',
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
};
const goicuocDefault = [
  {
    packageCode: 'DT70',
    price: 70000,
    duration: 30,
    description: 'Truy cập mạng 10GB/tháng',
    shortDescription: 'Truy cập mạng 10GB/tháng',
  },
  {
    packageCode: 'DT70 3T',
    price: 210000,
    duration: 90,
    description: 'Truy cập mạng 10GB/tháng',
    shortDescription: 'Truy cập mạng 10GB/tháng',
  },
  {
    packageCode: 'DT70 6T',
    price: 410000,
    duration: 180,
    description: 'Truy cập mạng 10GB/tháng',
    shortDescription: 'Truy cập mạng 10GB/tháng',
  },
  {
    packageCode: 'DT70 12T',
    price: 840000,
    duration: 360,
    description: 'Truy cập mạng 10GB/tháng',
    shortDescription: 'Truy cập mạng 10GB/tháng',
  },
  {
    packageCode: 'DT90',
    price: 90000,
    duration: 30,
    description: 'Truy cập mạng 15GB/tháng',
    shortDescription: 'Truy cập mạng 15GB/tháng',
  },
  {
    packageCode: 'DT90 3T',
    price: 270000,
    duration: 90,
    description: 'Truy cập mạng 15GB/tháng',
    shortDescription: 'Truy cập mạng 15GB/tháng',
  },
  {
    packageCode: 'DT90 6T',
    price: 540000,
    duration: 180,
    description: 'Truy cập mạng 15GB/tháng',
    shortDescription: 'Truy cập mạng 15GB/tháng',
  },
  {
    packageCode: 'DT90 12T',
    price: 1080000,
    duration: 360,
    description: 'Truy cập mạng 15GB/tháng',
    shortDescription: 'Truy cập mạng 15GB/tháng',
  },
  {
    packageCode: 'DT120',
    price: 120000,
    duration: 30,
    description: 'Truy cập mạng 20GB/tháng',
    shortDescription: 'Truy cập mạng 20GB/tháng',
  },
  {
    packageCode: 'DT120 3T',
    price: 360000,
    duration: 90,
    description: 'Truy cập mạng 20GB/tháng',
    shortDescription: 'Truy cập mạng 20GB/tháng',
  },
  {
    packageCode: 'DT120 6T',
    price: 720000,
    duration: 180,
    description: 'Truy cập mạng 20GB/tháng',
    shortDescription: 'Truy cập mạng 20GB/tháng',
  },
  {
    packageCode: 'DT120 12T',
    price: 1440000,
    duration: 360,
    description: 'Truy cập mạng 20GB/tháng',
    shortDescription: 'Truy cập mạng 20GB/tháng',
  },
];

function DataVina() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const tb = urlParams.get('tb');
  const hostname = window.location.hostname;
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [checkTBPackage, setCheckTBPackage] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [messageTotal, setMessageTotal] = useState('');
  const [checkTBPackageDetail, setCheckTBPackageDetail] = useState([]);
  const [menuSim, setMenuSim] = useState(1);
  const [open, setOpen] = useState(false);
  const contextApiUser = useContext(ThemeContext);

  const fetchApiUserID = async () => {
    const resultAPISIM = await userIDService.listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setLocalStorage('khoso', resultAPISIM[0]);
      contextApiUser.toggleDataUser(resultAPISIM[0]);
    }
  };

  useEffect(() => {
    if (hostname === 'sieuthivuongkhang.vn') {
      if (getLocalStorage('khoso')?.link_domain === hostname) {
        contextApiUser.toggleDataUser(getLocalStorage('khoso'));
      } else {
        fetchApiUserID();
      }
      document.title = 'Kiểm tra gói cước thuê bao sim VinaPhone';
    } else {
      navigate('/', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (tb) {
      setLoading(true);
      setTextSearch(tb);
      fetchApiCheckPackageSim(tb);
    }
  }, [tb]);

  const fetchApiCheckPackageSim = async (textSearch) => {
    setCheckTBPackage([]);
    setMessageTotal('');
    const result = await simService.checkPackageSim(textSearch);
    if (result.message === 'success') {
      setLoading(false);
      const arrPackage = [...result['data'], ...goicuocDefault];
      setCheckTBPackage(arrPackage);
      setMessageTotal(`Tổng Gói: ${arrPackage?.length} Gói`);
    } else {
      setLoading(false);
      // setMessageTotal(`Không tìm thấy gói cước`);
      setCheckTBPackage(goicuocDefault);
      setMessageTotal(`Tổng Gói: ${goicuocDefault?.length} Gói`);
    }
  };

  const onClickCheckPackageTb = () => {
    setLoading(true);
    navigate(`?tb=${textSearch}`, { replace: true });
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      navigate(`?tb=${textSearch}`, { replace: true });
    }
  };
  const onChangTextSim = (e) => {
    setTextSearch(e.target.value);
  };

  return (
    <Container sx={{ p: 0, pb: '90px' }}>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 1, p: 1 }}>
          <Paper elevation={0} sx={{ borderRadius: 2 }}>
            <Card variant="outlined" sx={{ borderRadius: 2, border: 0 }}>
              <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('active-cate-ns')}>
                {' '}
                <Stack direction="column" spacing={0} alignItems="flex-start">
                  {textSearch !== '' ? (
                    <h3>
                      Gói cước thuê bao <b className={cx('color-gold')}>{textSearch}</b> bên dưới bạn tham khảo nhé 👇
                    </h3>
                  ) : (
                    <h3>
                      XIN CHÀO! Bạn hãy nhập <b className={cx('color-gold')}>SỐ ĐIỆN THOẠI</b> để kiểm tra gói cước{' '}
                      <b className={cx('color-gold')}>SIÊU TỐT</b> nhé 👇
                    </h3>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item md={12} xs={12}>
          <header className={cx('wrapper-header')}>
            <div className={cx('inner-header')}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <Paper elevation={3} sx={{ p: '0px 2px', display: 'flex', alignItems: 'center', borderRadius: 2 }}>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder={`Nhập số điện thoại`}
                      inputProps={{ 'aria-label': 'Nhập số điện thoại' }}
                      value={textSearch}
                      onChange={onChangTextSim}
                      onKeyPress={handleEnterKey}
                      fullWidth
                    />
                    {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                    <IconButton
                      sx={{ m: '2px' }}
                      className={cx('bgr-default')}
                      aria-label="directions"
                      onClick={onClickCheckPackageTb}
                    >
                      <SearchIcon sx={{ color: 'aliceblue' }} />
                    </IconButton>
                  </Paper>
                </Grid>

                <Grid item xs={12} sx={{ mt: 1 }}>
                  <label className={cx('color-trang')}>
                    <b>Nhập số thuê bao để kiểm tra gói cước data</b>
                  </label>
                </Grid>
              </Grid>
            </div>
          </header>
        </Grid>
      </Grid>
      <div className={cx('main-content-loadtheme')}>{loading && <ThemeLoad />}</div>
      <div className={cx('main-content')}>
        <Grid container sx={{ pb: 1 }}>
          <Grid item xs={12} sx={{ ml: 1 }}>
            <b>{messageTotal}</b>
          </Grid>
          {checkTBPackage?.map((iter, index) => (
            <Grid item xs={6} md={3} key={index}>
              <FrameDataSim
                bgrFrame={'blue'}
                sms={`${iter?.duration / 30 === 1 ? '' : iter?.duration / 30}${iter?.packageCode.split(' ')[0]} DL ${
                  hostname === 'vnpts.com.vn' ? '0912257980' : '0911694694'
                }`}
                resultSimDetail={{
                  title: iter.packageCode,
                  price: iter.price.toString(),
                  dategoi: iter.duration,
                  content: iter.description,
                  contentshot: iter.shortDescription,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </Container>
  );
}

export default DataVina;
