import { useEffect, useRef } from 'react';

const PieChart = ({ data }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(centerX, centerY);
    let startAngle = 0;
    const sum = data.reduce((acc, curr) => acc + parseInt(curr), 0);

    data.forEach((value, index) => {
      const percentage = parseInt(value) / parseInt(sum) || 0;
      const endAngle = startAngle + Math.PI * 2 * percentage;
      context.beginPath();
      context.fillStyle = index === 0 ? '#bf4040' : '#1abc9c';
      context.moveTo(centerX, centerY);
      context.arc(centerX, centerY, radius, startAngle, endAngle);
      if (index === 1 && value !== 0) {
        context.lineWidth = 3;
        context.strokeStyle = '#FFF';
        context.stroke();
      }
      context.closePath();
      context.fill();
      startAngle = endAngle;
    });
  }, [data]);

  return <canvas ref={canvasRef} width={200} height={200} />;
};

export default PieChart;
