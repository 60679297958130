import * as requestAPI from '../utils/requestAPI';
import * as reqAuth from '../utils/requestAuthServer';
export const listSimAll30 = async (
  domain,
  page,
  textSearch,
  nhamang,
  theloai,
  dinhdang,
  menhsim,
  orderby,
  rangePrice,
  isSttTranhso,
  actionTranhso,
  thuebaoSim,
  isLoadPage,
) => {
  const text = textSearch ? `&text=${textSearch}` : ``;
  const mang = nhamang ? `&nhamang=${nhamang}` : ``;
  const cate = theloai ? `&cate=${theloai}` : ``;
  const dd = dinhdang ? `&dinhdang=${dinhdang}` : ``;
  const menh = menhsim ? `&menh=${menhsim}` : ``;
  const orderbys = orderby ? `&orderby=${orderby}` : ``;
  const rangeprice = rangePrice ? `&rangeprice=${rangePrice}` : ``;
  const isTranhso = isSttTranhso ? `&ists=${isSttTranhso}` : ``;
  const Tranhso = actionTranhso ? `&ts=${actionTranhso}` : ``;
  const ThueBao = thuebaoSim ? `&tb=${thuebaoSim}` : ``;
  const isLoadPages = isLoadPage ? `&isloadpage=${isLoadPage}` : ``;
  try {
    const res = await requestAPI.getSim(
      `/listSimAll?domain=${domain}&size=20&page=${page}${text}${mang}${cate}${dd}${menh}${orderbys}${rangeprice}${isTranhso}${Tranhso}${ThueBao}${isLoadPages}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

//API NEW
export const listSimAll = async (
  domain,
  page,
  textSearch,
  nhamang,
  theloai,
  dinhdang,
  menhsim,
  orderby,
  rangePrice,
  isSttTranhso,
  actionTranhso,
  thuebaoSim,
  isLoadPage,
) => {
  const text = textSearch ? `&text=${textSearch}` : ``;
  const mang = nhamang ? `&nhamang=${nhamang}` : ``;
  const cate = theloai ? `&cate=${theloai}` : ``;
  const dd = dinhdang ? `&dinhdang=${dinhdang}` : ``;
  const menh = menhsim ? `&menh=${menhsim}` : ``;
  const orderbys = orderby ? `&orderby=${orderby}` : ``;
  const rangeprice = rangePrice ? `&rangeprice=${rangePrice}` : ``;
  const isTranhso = isSttTranhso ? `&ists=${isSttTranhso}` : ``;
  const Tranhso = actionTranhso ? `&ts=${actionTranhso}` : ``;
  const ThueBao = thuebaoSim ? `&tb=${thuebaoSim}` : ``;
  const isLoadPages = isLoadPage ? `&isloadpage=${isLoadPage}` : ``;
  try {
    const res = await requestAPI.getSim(
      `/listSimAll?domain=${domain}&page=${page}${text}${mang}${cate}${dd}${menh}${orderbys}${rangeprice}${isTranhso}${Tranhso}${ThueBao}${isLoadPages}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listSimAllCount = async (
  domain,
  textSearch,
  nhamang,
  theloai,
  dinhdang,
  menhsim,
  rangePrice,
  isSttTranhso,
  actionTranhso,
  thuebaoSim,
) => {
  const text = textSearch ? `&text=${textSearch}` : ``;
  const mang = nhamang ? `&nhamang=${nhamang}` : ``;
  const cate = theloai ? `&cate=${theloai}` : ``;
  const dd = dinhdang ? `&dinhdang=${dinhdang}` : ``;
  const menh = menhsim ? `&menh=${menhsim}` : ``;
  const rangeprice = rangePrice ? `&rangeprice=${rangePrice}` : ``;
  const isTranhso = isSttTranhso ? `&ists=${isSttTranhso}` : ``;
  const Tranhso = actionTranhso ? `&ts=${actionTranhso}` : ``;
  const ThueBao = thuebaoSim ? `&tb=${thuebaoSim}` : ``;
  try {
    const res = await requestAPI.getSim(
      `/listSimAllCount?domain=${domain}${text}${mang}${cate}${dd}${menh}${rangeprice}${isTranhso}${Tranhso}${ThueBao}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listSimID = async (domain, textSearch) => {
  const text = textSearch ? `&text=${textSearch}` : ``;
  try {
    const res = await requestAPI.getSim(`/listSimID?domain=${domain}${text}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const totalPriceSim = async (domain) => {
  try {
    const res = await requestAPI.getSim(`/totalPriceSim?domain=${domain}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listSimAllSize = async (
  domain,
  size,
  textSearch,
  nhamang,
  theloai,
  dinhdang,
  menhsim,
  orderby,
  rangePrice,
  isSttTranhso,
  actionTranhso,
) => {
  const text = textSearch ? `&text=${textSearch}` : ``;
  const mang = nhamang ? `&nhamang=${nhamang}` : ``;
  const cate = theloai ? `&cate=${theloai}` : ``;
  const dd = dinhdang ? `&dinhdang=${dinhdang}` : ``;
  const menh = menhsim ? `&menh=${menhsim}` : ``;
  const orderbys = orderby ? `&orderby=${orderby}` : ``;
  const rangeprice = rangePrice ? `&rangeprice=${rangePrice}` : ``;
  const isTranhso = isSttTranhso ? `&ists=${isSttTranhso}` : ``;
  const Tranhso = actionTranhso ? `&ts=${actionTranhso}` : ``;
  try {
    const res = await requestAPI.getSim(
      `/listSimAll?domain=${domain}&size=${size}${text}${mang}${cate}${dd}${menh}${orderbys}${rangeprice}${isTranhso}${Tranhso}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
//API THE LOAI
export const listCateSimAll = async (domain) => {
  try {
    const res = await requestAPI.getSim(`/listCateSimAll?domain=${domain}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
//API LÀM MỚI LIST
export const listDataSimID = async (domain) => {
  try {
    const res = await requestAPI.getSim(`/getDataSimID?domain=${domain}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

//API SIM DAILY
export const listSimDailyAll = async (keyword, nhamang = '', dauso = '', range = '', sapxep = '', page) => {
  try {
    const res = await requestAPI.getSim(
      `/searchdaily?keyword=${keyword}&page=${page}&dauso=${dauso}&nhamang=${nhamang}&range=${range}&sapxep=${sapxep}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const SimDailyDetail = async (keyword) => {
  try {
    const res = await requestAPI.getSim(`/searchdailydetail?keyword=${keyword}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
//API ẢNH
export const getImage = async (data) => {
  try {
    const res = await reqAuth.postSim(`/anhgoc`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
//API TÊN ẢNH
export const getInFoImage = async (mang, soluong) => {
  try {
    const res = await reqAuth.getSim(`/slanh?mang=${mang}&soluong=${soluong}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

//API TÊN ẢNH
export const getDemoImage = async (name) => {
  try {
    const res = await reqAuth.getSim(`/anhdemo?name=${name}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const uploadFileSim = async (data) => {
  try {
    const res = await reqAuth.postUpload(`/uploadFileSim`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const addImageShop = async (formData) => {
  try {
    const res = await reqAuth.postUpload(`/additems`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateImageShop = async (formData) => {
  try {
    const res = await reqAuth.postUpload(`/updateitems`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeImageShop = async (data) => {
  try {
    const res = await reqAuth.postSim(`/removeitem`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listShop = async (domain) => {
  try {
    const res = await requestAPI.getSim(`/listitems?domain=${domain}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listShopSize = async (domain, text = '', page = 1, size = 6) => {
  try {
    const res = await requestAPI.getSim(`/listsize?domain=${domain}&q=${text}&page=${page}&size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listShopId = async (id) => {
  try {
    const res = await requestAPI.getSim(`/listItemId?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
