import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Button, Card, CardContent, Grid, Paper, Stack } from '@mui/material';
import { numberFormat } from '../../../../utils/helper';

const ExportExcelCN = ({ dataSim = [], date }) => {
  const exportToExcel = (data) => {
    // Tạo một danh sách các đối tượng con tương ứng với từng dòng trong mảng data
    let result = {};

    data.map((cn) => {
      if (!result[cn.status === 1 ? 'HOÀN THÀNH' : 'CHƯA HOÀN THÀNH']) {
        result[cn.status === 1 ? 'HOÀN THÀNH' : 'CHƯA HOÀN THÀNH'] = [];
      }

      const arrSim = {
        Ngày: dayjs(cn.date_cn).format('DD/MM/YYYY'),
        'Tên Công Nợ': cn.sim,
        'Người Bán': cn.seller,
        'Giá Bán': numberFormat(cn?.price),
        'Hỗ Trợ': numberFormat(cn?.hotro),
        'Phí GDH': numberFormat(cn?.gdh),
        'Phí Dịch Vụ': numberFormat(cn?.dichvu),
        'Tổng Dư': numberFormat(cn?.tong),
        'Ghi Chú': cn.note,
      };

      result[cn.status === 1 ? 'HOÀN THÀNH' : 'CHƯA HOÀN THÀNH'].push(arrSim);
    });

    const arrSimSo = [];
    data.map((cnn) => {
      const arrSim = {
        Ngày: dayjs(cnn.date_cn).locale('vi').format('DD/MM/YYYY'),
        'Tên Công Nợ': cnn.sim,
        'Người Bán': cnn.seller,
        'Giá Bán': numberFormat(cnn?.price),
        'Hỗ Trợ': numberFormat(cnn?.hotro) || '',
        'Phí GDH': numberFormat(cnn?.gdh) || '',
        'Phí Dịch Vụ': numberFormat(cnn?.dichvu) || '',
        'Tổng Dư': numberFormat(cnn?.tong),
        'Ghi Chú': cnn.note,
        'Tình Trạng': cnn.status === 1 ? 'đã xong' : 'Chưa Nhận',
      };

      arrSimSo.push(arrSim);
    });

    let arrSeller = {};
    data.map((cn) => {
      if (!arrSeller['SELLER - ' + cn.seller.toUpperCase().trim()]) {
        arrSeller['SELLER - ' + cn.seller.toUpperCase().trim()] = [];
      }

      const arrSim = {
        Ngày: dayjs(cn.date_cn).format('DD/MM/YYYY'),
        'Tên Công Nợ': cn.sim,
        'Người Bán': cn.seller,
        'Giá Bán': numberFormat(cn?.price),
        'Hỗ Trợ': numberFormat(cn?.hotro),
        'Phí GDH': numberFormat(cn?.gdh),
        'Phí Dịch Vụ': numberFormat(cn?.dichvu),
        'Tổng Dư': numberFormat(cn?.tong),
        'Ghi Chú': cn.note,
        'Tình Trạng': cn.status === 1 ? 'đã xong' : 'Chưa Nhận',
      };

      arrSeller['SELLER - ' + cn.seller.toUpperCase().trim()].push(arrSim);
    });
    // console.log(arrSeller);
    result = { [`Công Nợ ${date}`]: arrSimSo, ...result, ...arrSeller };
    // Tạo workbook mới
    const workbook = XLSX.utils.book_new();

    // Duyệt qua từng category (sheet) trong dữ liệu
    for (const category in result) {
      if (result.hasOwnProperty(category)) {
        const items = result[category];

        // Tạo worksheet mới cho từng category
        const worksheet = XLSX.utils.json_to_sheet(items);
        const wscols = [
          { wch: 20 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 25 },
          { wch: 30 },
        ];

        worksheet['!cols'] = wscols;

        // Gán tên cho worksheet
        XLSX.utils.book_append_sheet(workbook, worksheet, category);
      }
    }

    // Chuyển đổi workbook thành dạng binary tương ứng với file Excel
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Tạo blob từ dữ liệu excel để tải xuống
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Sử dụng hàm saveAs từ thư viện file-saver để tải xuống tệp
    saveAs(blob, `congno-${date}.xlsx`);
  };
  // Gọi hàm exportToExcel khi bạn muốn tải xuống
  const handleExport = () => {
    if (dataSim?.detail?.length > 0) {
      exportToExcel(
        dataSim?.detail.sort(function (a, b) {
          // Convert the date strings to Date objects
          let dateA = new Date(a.date_cn);
          let dateB = new Date(b.date_cn);

          // Subtract the dates to get a value that is either negative, positive, or zero
          return dateA - dateB;
        }),
      );
    }
  };
  return (
    <Paper elevation={0}>
      <Card
        variant="outlined"
        sx={{ borderRadius: 2, background: '#00864e', color: '#fff', cursor: 'pointer' }}
        onClick={handleExport}
      >
        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
          {' '}
          <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
            <h4 style={{ whiteSpace: 'nowrap' }}>XUẤT EXCEL CÔNG NỢ</h4>
          </Stack>
          <Stack direction="column" spacing={0}>
            <span style={{ whiteSpace: 'nowrap' }}>{`Tháng ${date}`}</span>
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  );
};
export default ExportExcelCN;
