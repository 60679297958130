import { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Button, Card, CardContent, Grid, Paper, Stack } from '@mui/material';
import { numberFormatText, totalPriceDiscount } from '../../../utils/helper';
import { LogicCategoryShowName } from '../LogicSearch/LogicCategoryShowName';
import { LogicMenhSim } from '../LogicSearch/LogicMenhSim';
import { LogicCategory } from '../LogicSearch/LogicCategory';
import { logicFormatSim } from '../../Dashboard/Excel/logicFormatSim';
import { DataSim } from '../../../utils/DataSim';

const ExportExcel = ({ dataSim = [], discount = [], cateSim = [] }) => {
  const favourite = (number) => {
    const last4Str = String(number).slice(-4);
    const last4Num = parseInt(last4Str);
    const tailnumbers = last4Num / 80;
    const surplus = getDecimalPart(tailnumbers);
    const totalHungCat = '0.' + surplus;
    const total = Math.round(parseFloat(totalHungCat) * 80);
    // if (DataSim[total]['mucdo'] === 'Đại Cát' || DataSim[total]['mucdo'] === 'Cát') {
    //   return DataSim[total];
    // } else {
    //   return '';
    // }
    return DataSim[total] || '';
  };

  const getDecimalPart = (num) => {
    // console.log(Number.isInteger(num), num);
    if (Number.isInteger(num)) {
      return 0;
    }

    const decimalStr = num.toString().split('.')[1];
    return parseInt(decimalStr);
  };
  const exportToExcel = (data) => {
    // Tạo một danh sách các đối tượng con tương ứng với từng dòng trong mảng data
    const result = {};

    data.map((item) => {
      // const categories = item.id_catesim.split(',');
      let cate = [];
      if (item.id_catesim.split(',').length > 0) {
        item.id_catesim.split(',').map((cates, i) => {
          let name_cate =
            cateSim.filter((x) => x.link === cates)[0]?.title ||
            LogicCategoryShowName(cates) ||
            cates ||
            `Dạng Khác ${i + 1}`;
          cate.push(name_cate);
        });
      }

      cate.map((category) => {
        if (!result[category]) {
          result[category] = [];
        }

        result[category].push({
          sim: item.sim,
          sim_show: item.sim_show,
          nhamang: item.nhamang,
          thuebao: item.thuebao,
          format_sim: item.format_sim,
          id_catesim: item.id_catesim,
          price: item.price,
          price_old: item.price_old,
          menh_sim: item.menh_sim,
        });
      });
      // console.log(cate, result);
    });
    const worksheetData = data.map((item) => {
      //   const [sim, price] = item;
      const sim = item?.sim_show;
      const mang = item?.nhamang;
      const giaKhach = numberFormatText(item?.price);
      let cate = [];
      if (item.id_catesim.split(',').length > 0) {
        item.id_catesim.split(',').map((cates, i) => {
          let name_cate = cateSim.filter((x) => x.link === cates)[0]?.title || cates;
          cate.push(name_cate);
        });
      }
      let arrNameCate = cate.filter((value) => value !== undefined && value !== '').join(', ');
      const dinhdang = item?.format_sim;
      const thuebao = item?.thuebao;
      const giaThu = item?.price && item?.price_old && numberFormatText(totalPriceDiscount(item, discount)[0]);
      const arrSim = {
        'Sim Số': sim,
        'Giá Thu': giaThu,
        'Giá Khách': giaKhach,
        'Nhà Mạng': mang,
        'Thuê Bao': thuebao,
        'Thể Loại': arrNameCate,
        'Mệnh Sim': item?.menh_sim,
        'Định Dạng Sẵn': logicFormatSim(item?.sim),
        'Định Dạng Tổng': dinhdang,
        'Hung/Cát': favourite(item?.sim)['mucdo'],
        'Ý nghĩa': favourite(item?.sim)['ynghia'],
      };

      return arrSim;
    });
    // Tạo workbook mới
    const workbook = XLSX.utils.book_new();

    // Tạo worksheet mới cho từng category
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const wscols = [
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 30 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 30 },
    ];

    worksheet['!cols'] = wscols;

    // Thêm worksheet vào workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tổng Sim');

    // Chuyển đổi workbook thành dạng binary tương ứng với file Excel
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Tạo blob từ dữ liệu excel để tải xuống
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Sử dụng hàm saveAs từ thư viện file-saver để tải xuống tệp
    saveAs(blob, 'data.xlsx');
  };
  // Gọi hàm exportToExcel khi bạn muốn tải xuống
  const handleExport = () => {
    if (Object.values(dataSim).length > 0) {
      exportToExcel(Object.values(dataSim));
    }
  };
  return (
    <Paper elevation={0}>
      <Card
        variant="outlined"
        sx={{ borderRadius: 2, background: '#00864e', color: '#fff', cursor: 'pointer' }}
        onClick={handleExport}
      >
        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
          {' '}
          <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
            <h3 style={{ whiteSpace: 'nowrap' }}>XUẤT EXCEL</h3>
          </Stack>
          <Stack direction="column" spacing={0}>
            <span style={{ whiteSpace: 'nowrap' }}>
              {' '}
              {Object.values(dataSim).length > 0
                ? `Xuất ${Object.values(dataSim).length} sim`
                : 'Chọn sim -> xuất excel'}
            </span>
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  );
};
export default ExportExcel;
