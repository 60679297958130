import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import images from '../../../assets/images';
import { Checkbox, Grid, Stack } from '@mui/material';
import classNames from 'classnames/bind';
import styles from './flashsale.module.scss';
import { useEffect, useState } from 'react';
import { getFlashSale } from '../../../services/apiFlashSale';
import OrderSim from '../../DigiShop/orderSim/ordersim';
import { favourite, getLocalStorage } from '../../../utils/helper';
import { LogicNetWork } from '../LogicSearch/LogicNetWork';
import OrderSimDetail from './orderSim/ordersim';
const cx = classNames.bind(styles);
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});
function FlashSale({ id_user }) {
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const [resultFlash, setResultFlash] = useState({});
  const [resultFlashData, setResultFlashData] = useState([]);
  const [timer, setTimer] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [open, setOpen] = useState(false);
  const [detailSim, setDetailSim] = useState({});

  const fetchApiFlashSale = async () => {
    const resultAPISIM = await getFlashSale(id_user);
    if (resultAPISIM && resultAPISIM.length > 0) {
      const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
      const sortedData = [...resultAPISIM].sort((a, b) => a.time_start.localeCompare(b.time_start));

      const ongoingRecords = sortedData.filter(
        (record) => record.time_start <= currentTime && record.time_end >= currentTime,
      );
      setResultFlash(ongoingRecords[0] || {});
      setResultFlashData(ongoingRecords[0]?.text_sim?.split('\n').slice(0, 20));
    }
  };
  useEffect(() => {
    fetchApiFlashSale();
  }, []);

  useEffect(() => {
    if (Object?.keys(resultFlash)?.length > 0) {
      const interval = setInterval(() => {
        const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });
        const timeStartInSeconds = new Date(`1970-01-01T${resultFlash.time_start}`).getTime() / 1000;
        const timeEndInSeconds = new Date(`1970-01-01T${resultFlash.time_end}`).getTime() / 1000;
        const currentTimeInSeconds = new Date(`1970-01-01T${currentTime}`).getTime() / 1000;
        const timeRemainingInSeconds = timeEndInSeconds - currentTimeInSeconds;

        if (timeRemainingInSeconds <= 0) {
          clearInterval(interval);
          setTimer({
            hours: 0,
            minutes: 0,
            seconds: 0,
          });
          return;
        }

        const formattedTimeRemaining = new Date(timeRemainingInSeconds * 1000)
          ?.toISOString()
          ?.substr(11, 8)
          ?.split(':');

        setTimer({
          hours: formattedTimeRemaining[0],
          minutes: formattedTimeRemaining[1],
          seconds: formattedTimeRemaining[2],
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [resultFlash]);

  const handleModalOpen = (sim) => {
    const simandprice = sim.split('=');
    const favoriteSim = favourite(simandprice[0].toString()) && favourite(simandprice[0].toString())['ynghia'];
    const simDetailDailog = {
      sim: simandprice[0].toString(),
      price: simandprice[1].toString(),
      subCard: '',
      netWork: LogicNetWork(simandprice[0].replace(/\./g, '')),
      means: favoriteSim,
      hotline: getLocalStorage('khoso')['HOTTLINE_SIM'],
      GMAIL_NOTIFI: getLocalStorage('khoso')['GMAIL_NOTIFI'],
      background: getLocalStorage('khoso')['background'].split(',')[0],
    };
    setOpen(true);
    setDetailSim(simDetailDailog);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {detailSim && <OrderSimDetail openDiaLog={open} onClose={handleClose} detailSim={detailSim} />}
      {Object?.keys(resultFlash)?.length > 0 && (
        <div className={cx('main-content-cate')}>
          <Grid container spacing={1}>
            <Grid item xs={8} sx={{ p: 1 }}>
              <Stack direction={'row'} spacing={1}>
                <img src={images.flashsale} alt="flash sale" width={'130px'} />
              </Stack>
            </Grid>
            <Grid item xs={4} sx={{ p: 1 }}>
              <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
                <span
                  className={cx('ml-10', 'cursor')}
                  onClick={() => {
                    navigate('/sim-so-dep-sale', { replace: true });
                  }}
                >
                  Xem thêm
                </span>
              </Stack>
            </Grid>
            <Grid container spacing={1} sx={{ ml: 0, mt: -2 }}>
              {/* hiển thị sim */}
              <Grid item xs={12} sx={{ p: 1 }}>
                <div className={cx('box-list-subcate')}>
                  <div className={cx('list-subcate')}>
                    <Stack direction="row" spacing={1}>
                      {resultFlashData?.map(
                        (item, index) =>
                          index % 5 === 0 && (
                            <Grid item key={index}>
                              <div className={cx('phoi-all')}>
                                <div className={cx('phoi-all-2')}>
                                  <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                    <b className={cx('cs-tieude')}>Thay Sim Đổi Vận - Thu Hút Tài Lộc</b>
                                  </Stack>
                                </div>

                                <div
                                  className={cx('phoi-all-1')}
                                  onClick={() => handleModalOpen(item.replace(/\s/g, ''))}
                                >
                                  <span>{item}</span>
                                </div>
                                {resultFlashData[index + 1] && (
                                  <div
                                    className={cx('phoi-all-1')}
                                    onClick={() => handleModalOpen(resultFlashData[index + 1].replace(/\s/g, ''))}
                                  >
                                    <span>{resultFlashData[index + 1]}</span>
                                  </div>
                                )}
                                {resultFlashData[index + 2] && (
                                  <div
                                    className={cx('phoi-all-1')}
                                    onClick={() => handleModalOpen(resultFlashData[index + 2].replace(/\s/g, ''))}
                                  >
                                    <span>{resultFlashData[index + 2]}</span>
                                  </div>
                                )}
                                {resultFlashData[index + 3] && (
                                  <div
                                    className={cx('phoi-all-1')}
                                    onClick={() => handleModalOpen(resultFlashData[index + 3].replace(/\s/g, ''))}
                                  >
                                    <span>{resultFlashData[index + 3]}</span>
                                  </div>
                                )}
                                {resultFlashData[index + 4] && (
                                  <div
                                    className={cx('phoi-all-1')}
                                    onClick={() => handleModalOpen(resultFlashData[index + 4].replace(/\s/g, ''))}
                                  >
                                    <span>{resultFlashData[index + 4]}</span>
                                  </div>
                                )}

                                <div className={cx('phoi-all-2')}>
                                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <img
                                      src={images.phoisimdep}
                                      alt={'khoso'}
                                      width={'50px'}
                                      className={cx('bdradius-10')}
                                    />
                                    <span className={cx('price-flash')}>
                                      <b className={cx('cs-logo')}>SALE</b>
                                    </span>
                                  </Stack>
                                  <Stack
                                    direction={'row'}
                                    justifyContent={'flex-end'}
                                    alignItems={'center'}
                                    sx={{ color: '#fff', fontSize: '14px', mt: '-10px' }}
                                  >
                                    <span>
                                      <b>{resultFlash?.notes_sim}</b>
                                    </span>
                                  </Stack>

                                  <Stack
                                    direction={'row'}
                                    justifyContent={'flex-end'}
                                    alignItems={'center'}
                                    sx={{ color: '#fff' }}
                                  >
                                    <span className={cx('ml-10', 'mt-2')}>
                                      <b className={cx('cs-countdow')}>{timer.hours}</b> :{' '}
                                      <b className={cx('cs-countdow')}>{timer.minutes}</b> :{' '}
                                      <b className={cx('cs-countdow')}>{timer.seconds}</b>
                                    </span>
                                  </Stack>
                                </div>
                                {/* <div className={cx('phoi-all-2')}>
                      <Stack direction={'column'} sx={{ mt: '-12px' }}>
                        <span className={cx('cs-color-white')}> Thay Sim Đổi Vận - Thu Hút Tài Lộc</span>
                      </Stack>
                    </div> */}
                              </div>
                            </Grid>
                          ),
                      )}
                    </Stack>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default FlashSale;
