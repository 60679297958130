import { useState, createContext, useEffect } from 'react';

const ThemeContext = createContext();

function ThemeProviderSim({ children }) {
  const [textSearch, setTextSearch] = useState({ text: '', isSim: 1 });
  const [dataUser, setDataUser] = useState('');

  const toggleDataSim = (textSearchSim) => {
    setTextSearch(textSearchSim);
  };

  const toggleDataUser = (data) => {
    setDataUser(data);
  };

  const value = {
    toggleDataSim,
    toggleDataUser,
    textSearch,
    dataUser,
  };
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}

export { ThemeContext, ThemeProviderSim };
