import {
  Alert,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { forwardRef, useEffect, useRef, useState } from 'react';

import * as apiUsers from '../../../services/apiUsers';
import {
  checkTokenDashboad,
  getCookie,
  getLocalStorage,
  numberFormat,
  numberFormatDeleteDots,
  numberFormatText,
} from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import AddBoxIcon from '@mui/icons-material/AddBox';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import classNames from 'classnames/bind';
import styles from './shop.module.scss';
import { addImageShop, listShop, listShopId, removeImageShop, updateImageShop } from '../../../services/listSim';
import { API_URL_SIMSO } from '../../../config/config';
const cx = classNames.bind(styles);
function Shop() {
  const inputFile = useRef(null);
  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const navigate = useNavigate();
  const getToken = getCookie('token');
  const editorRef = useRef();
  const hostname = window.location.hostname;

  const arrProduct = {
    id_user: getToken['id'],
    id_cate: 1,
    domain: hostname,
    title: '',
    brand: '', //thương hiêu
    product_code: '',
    status: 1,
    price: '',
    price_old: '',
    quantity: 1, // số lượng
    quantity_sold: 0, // số lượng bán
    view: 1,
    details: '',
    note: '',
  };
  const [value, setValue] = useState(arrProduct);
  const [dataDetail, setDataDetail] = useState([]);
  const [resultListShop, setResultListShop] = useState([]);
  const [resultImage, setResultImage] = useState([]);
  const [isUpdate, setIsUpdate] = useState(0);
  const [content, setContent] = useState('');
  const [messNotifi, setMessNotifi] = useState({ isstt: true, notifi: '' });
  const fetchApiListShop = async () => {
    const resultAPISIM = await listShop(hostname);
    if (resultAPISIM?.length > 0) {
      setResultListShop(resultAPISIM);
    } else {
      setResultListShop([]);
    }
  };

  const fetchApiListShopID = async (id) => {
    const resultAPISIM = await listShopId(id);
    if (typeof resultAPISIM['data'] !== 'undefined') {
      setValue(resultAPISIM['data']);
      setDataDetail(resultAPISIM['dataDetails']);
      setResultImage(resultAPISIM['images']);
      setIsUpdate(1);
    } else {
      setValue({});
      setDataDetail([]);
      setResultImage([]);
      setIsUpdate(0);
    }
  };
  useEffect(() => {
    fetchApiListShop();
  }, []);

  const handleChangValueUser = (jsonVL) => {
    setValue({ ...value, ...jsonVL });
  };

  const onButtonClickFileImage = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onFilechange = (e) => {
    let tempArr = [];
    let tempFileArr = [];
    [...e.target.files].forEach((file) => {
      tempArr.push(URL.createObjectURL(file));
      tempFileArr.push(file);
    });
    setImageURLs([...imageURLS, ...tempArr]);
    setImages([...images, ...tempFileArr]);
  };

  // thêm nhiều loại sản phẩm
  const onClickAddProductDetail = () => {
    const objDetailDefault = { title: '', price: '' };
    setDataDetail([...dataDetail, objDetailDefault]);
  };

  // xoa loại sản phẩm
  const removePrice = (index) => {
    const arrRemove = dataDetail.filter((_, i) => i !== index) || [];
    setDataDetail(arrRemove);
  };

  const handleChangDetail = (jsonValue, i) => {
    let arrDetail = dataDetail?.map((obj, index) => {
      if (index === i) {
        return { ...obj, ...jsonValue };
      }
      return obj;
    });

    setDataDetail(arrDetail);
  };

  const removeItem = async (id) => {
    const remove = await removeImageShop({ id: id });
    if (remove.message === 'OK') {
      fetchApiListShop();
      setMessNotifi({ isstt: false, notifi: 'Xóa thành công ' });
      setTimeout(() => {
        setMessNotifi({ isstt: true, notifi: '' });
      }, 2000);
    }
  };

  // xoa ảnh
  const removeImage = (index) => {
    const arrRemove = imageURLS.filter((_, i) => i !== index) || [];
    const arrRemoveFile = images.filter((_, i) => i !== index) || [];
    setImageURLs(arrRemove);
    setImages(arrRemoveFile);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    images.forEach((image, i) => {
      let timestamp = i + '_' + Date.now();
      formData.append('image', image, timestamp);
    });
    formData.append('data', JSON.stringify({ ...value, details: content }));
    formData.append('dataDetail', JSON.stringify(dataDetail));
    if (value.title.length > 0) {
      const upload = await addImageShop(formData);
      if (upload.message === 'OK') {
        setValue(arrProduct);
        setContent('');
        setDataDetail([]);
        setImages([]);
        setImageURLs([]);
        setIsUpdate(0);
        setMessNotifi({ isstt: false, notifi: 'Lưu thành công ' });
        setTimeout(() => {
          setMessNotifi({ isstt: true, notifi: '' });
        }, 2000);
        fetchApiListShop();
      } else {
        setMessNotifi({ isstt: true, notifi: 'Lưu không thành công' });
        setTimeout(() => {
          setMessNotifi({ isstt: false, notifi: '' });
        }, 2000);
      }
    } else {
      setMessNotifi({ isstt: true, notifi: 'Nhập dữ liêu trước khi ấn lưu' });
    }
  };

  const handleFormUpdateSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    images.forEach((image, i) => {
      let timestamp = i + '_' + Date.now();
      formData.append('image', image, timestamp);
    });
    formData.append('data', JSON.stringify({ ...value, details: content }));
    formData.append('dataDetail', JSON.stringify(dataDetail));

    const upload = await updateImageShop(formData);
    if (upload.message === 'OK') {
      setValue(arrProduct);
      setDataDetail([]);
      setImages([]);
      setImageURLs([]);
      setIsUpdate(0);
      setContent('');

      setMessNotifi({ isstt: false, notifi: 'Lưu thành công ' });
      setTimeout(() => {
        setMessNotifi({ isstt: true, notifi: '' });
      }, 2000);
      fetchApiListShop();
    } else {
      setMessNotifi({ isstt: true, notifi: 'Lưu không thành công' });
      setTimeout(() => {
        setMessNotifi({ isstt: false, notifi: '' });
      }, 2000);
    }
  };

  const addShop = () => {
    setValue(arrProduct);
    setDataDetail([]);
    setImages([]);
    setImageURLs([]);
    setIsUpdate(0);
    setMessNotifi({ isstt: true, notifi: '' });
    setContent('');
  };
  return (
    <Container sx={{ p: 0, pb: '65px' }}>
      <Stack spacing={2}>
        <h2>THÊM/SỬA SẢN PHẨM</h2>
        {!messNotifi.isstt && messNotifi.notifi.length > 0 && <Alert severity="success">{messNotifi.notifi}</Alert>}
        {messNotifi.isstt && messNotifi.notifi.length > 0 && <Alert severity="error">{messNotifi.notifi}</Alert>}
      </Stack>
      <Grid container sx={{ p: 1 }} spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack direction={'column'} spacing={2}>
            <TextField
              label="Tiêu đề sản phẩm"
              size="small"
              value={value.title}
              onChange={(e) => {
                handleChangValueUser({ title: e.target.value });
              }}
            />
            <TextField
              label="Thương hiệu (NSX)"
              size="small"
              value={value.brand}
              onChange={(e) => {
                handleChangValueUser({ brand: e.target.value });
              }}
            />
            <TextField
              label="Mã sản phẩm"
              size="small"
              value={value.product_code}
              onChange={(e) => {
                handleChangValueUser({ product_code: e.target.value });
              }}
            />
            <TextField
              label="Số lượng sản phẩm"
              size="small"
              value={value.quantity}
              onChange={(e) => {
                handleChangValueUser({ quantity: e.target.value });
              }}
            />
            <TextField
              label="Số lượng đã bán"
              size="small"
              value={value.quantity_sold}
              onChange={(e) => {
                handleChangValueUser({ quantity_sold: e.target.value });
              }}
            />
            <TextField
              label="Giá bán"
              size="small"
              value={numberFormat(value.price)}
              onChange={(e) => {
                handleChangValueUser({ price: numberFormatDeleteDots(e.target.value).toString() });
              }}
            />
            <TextField
              label="Giá cũ (nếu có)"
              size="small"
              value={numberFormat(value.price_old)}
              onChange={(e) => {
                handleChangValueUser({ price_old: numberFormatDeleteDots(e.target.value).toString() });
              }}
            />
            <span style={{ borderTop: '1px dashed #ccc', pt: 2 }}>
              <b>Phân loại sản phẩm (nếu cần)</b>
            </span>
            <Stack direction={'row'} spacing={1}>
              <Button variant="outlined" startIcon={<AddBoxIcon />} color="success" onClick={onClickAddProductDetail}>
                Thêm loại sản phẩm
              </Button>
            </Stack>
            {dataDetail?.length > 0 && (
              <Stack direction={'column'} spacing={1} sx={{ borderBottom: '1px dashed #ccc', pb: 2 }}>
                {dataDetail.map((data, i) => (
                  <Stack direction={'row'} spacing={1} key={i}>
                    <TextField
                      label="Tên loại sản phẩm"
                      size="small"
                      value={data?.title}
                      onChange={(e) => {
                        handleChangDetail({ title: e.target.value }, i);
                      }}
                    />
                    <TextField
                      label="Giá"
                      size="small"
                      value={numberFormat(data?.price)}
                      onChange={(e) => {
                        handleChangDetail({ price: numberFormatDeleteDots(e.target.value).toString() }, i);
                      }}
                    />
                    <IconButton aria-label="delete" size="large" color="error" onClick={() => removePrice(i)}>
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            )}
            <TextField
              label="Ghi chú (nếu có)"
              size="small"
              value={value.note}
              onChange={(e) => {
                handleChangValueUser({ note: e.target.value });
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction={'column'} spacing={2}>
            <span>
              <b>Upload ảnh sản phẩm</b>
              <br />
              <b style={{ color: 'red' }}>(Tối đa 5 ảnh - kích thước vuông (ví dụ: 500x500, 900x900,1800x1800))</b>
            </span>
            {isUpdate === 0 && (
              <Stack direction={'row'} spacing={1}>
                {imageURLS.length < 5 && (
                  <span
                    style={{ padding: '20px', border: '1px dashed green', borderRadius: '10px', cursor: 'pointer' }}
                    onClick={onButtonClickFileImage}
                  >
                    <AddPhotoAlternateIcon color="success" />
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      name="image"
                      id="file"
                      ref={inputFile}
                      style={{ display: 'none' }}
                      onChange={onFilechange}
                    />
                  </span>
                )}
                {imageURLS.map((imageSrc, i) => (
                  <div key={i} className={cx('fix-remove')}>
                    <img src={imageSrc} alt="not fount" width={'100px'} />
                    <span className={cx('tag')} onClick={() => removeImage(i)}>
                      xóa
                    </span>
                  </div>
                ))}
              </Stack>
            )}

            {isUpdate === 1 && (
              <Stack direction={'row'} spacing={1}>
                {imageURLS.length < 5 && (
                  <span
                    style={{ padding: '20px', border: '1px dashed green', borderRadius: '10px', cursor: 'pointer' }}
                    onClick={onButtonClickFileImage}
                  >
                    <AddPhotoAlternateIcon color="success" />
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      name="image"
                      id="file"
                      ref={inputFile}
                      style={{ display: 'none' }}
                      onChange={onFilechange}
                    />
                  </span>
                )}
                {imageURLS.length > 0
                  ? imageURLS.map((imageSrc, i) => (
                      <div key={i} className={cx('fix-remove')}>
                        <img src={imageSrc} alt="not fount" width={'100px'} />
                        <span className={cx('tag')} onClick={() => removeImage(i)}>
                          xóa
                        </span>
                      </div>
                    ))
                  : resultImage.map((imageSrc, i) => (
                      <div key={i} className={cx('fix-remove')}>
                        <img
                          src={`${API_URL_SIMSO}/imgshop?name=${imageSrc.title}.jpg`}
                          alt="not fount"
                          width={'100px'}
                        />
                      </div>
                    ))}
              </Stack>
            )}

            <span>
              <b>Chi tiết sản phẩm</b>
            </span>

            <SunEditor
              ref={editorRef}
              defaultValue={value.details}
              setContents={value.details}
              onChange={(content) => {
                setContent(content);
              }}
              setOptions={{
                buttonList: [
                  ['undo', 'redo'],
                  ['font', 'fontSize', 'formatBlock'],
                  ['paragraphStyle', 'blockquote'],
                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                  ['fontColor', 'hiliteColor', 'textStyle'],
                  ['removeFormat'],
                  ['outdent', 'indent'],
                  ['align', 'horizontalRule', 'list', 'table'],
                  ['link', 'image', 'video'],
                  ['fullScreen', 'showBlocks', 'codeView'],
                  ['preview', 'print'],
                  ['save'],
                ],
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          {isUpdate === 0 && (
            <Button variant="contained" color="error" onClick={handleFormSubmit}>
              LƯU SẢN PHẨM
            </Button>
          )}
          {isUpdate === 1 && (
            <Stack direction={'row'} spacing={2}>
              <Button variant="contained" color="error" onClick={handleFormUpdateSubmit}>
                CẬP NHẬT SẢN PHẨM
              </Button>
              <Button variant="contained" color="success" onClick={addShop}>
                QUAY LẠI và THÊM MỚI
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Tên sản phẩm</TableCell>
              <TableCell align="right">Giá bán</TableCell>
              <TableCell align="right">Nhà sản xuất</TableCell>
              <TableCell align="right">Số lượng</TableCell>
              <TableCell align="right">Số lượng bán</TableCell>
              <TableCell align="right">Tác vụ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resultListShop.map((row, i) => (
              <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell align="right">{row.price}</TableCell>
                <TableCell align="right">{row.brand}</TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="right">{row.quantity_sold}</TableCell>
                <TableCell align="right">
                  {' '}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      fetchApiListShopID(row.id);
                    }}
                  >
                    Sửa
                  </Button>{' '}
                  <Button variant="contained" color="error" onClick={() => removeItem(row.id)}>
                    Xóa
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default Shop;
