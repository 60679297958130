import PropTypes from 'prop-types';
import SimCardIcon from '@mui/icons-material/SimCard';
import DoneIcon from '@mui/icons-material/Done';

import classNames from 'classnames/bind';
import styles from './FrameDataSim.module.scss';
import images from '../../../assets/images';
import ButtonUI from '../ButtonUI/ButtonUI';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Hidden,
  Paper,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { handleConvertMonths, numberFormat } from '../../../utils/helper';
const cx = classNames.bind(styles);

function FrameDataSim({
  bgrFrame = 'df',
  title = '',
  sms = '',
  fontSize = 'small',
  arrDetailSim = [],
  resultSimDetail = {},
  onClick,
}) {
  let CompIcon = DoneIcon;
  const props = { onClick };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <DialogTitle className={cx('UTM')}>
            <b>CHI TIẾT GÓI</b>
          </DialogTitle>
          {/* <Button onClick={handleClose}>ĐÓNG LẠI</Button> */}
        </Stack>
        <DialogContent sx={{ mt: -3, p: 1.5 }} id="alert-dialog-inputs">
          <DialogContentText>
            <Grid container justifyContent={'center'} alignItems={'center'} spacing={1}>
              <Grid item xs={4}>
                <Paper elevation={0}>
                  <Card variant="outlined" sx={{ borderRadius: 2 }}>
                    <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                      <p className={cx('UTM')}>Tên gói</p>
                      <Stack direction="column" spacing={0}>
                        <h5 className={cx('FS')}>{resultSimDetail.title}</h5>
                      </Stack>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0}>
                  <Card variant="outlined" sx={{ borderRadius: 2 }}>
                    <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                      <p className={cx('UTM')}>Giá gói</p>
                      <Stack direction="column" spacing={0}>
                        <h5 className={cx('FS')}>{numberFormat(resultSimDetail.price)}</h5>
                      </Stack>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper elevation={0}>
                  <Card variant="outlined" sx={{ borderRadius: 2 }}>
                    <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                      <p className={cx('UTM')}>Thời gian</p>
                      <Stack direction="column" spacing={0}>
                        <h5 className={cx('FS')}>{handleConvertMonths(resultSimDetail.dategoi)}</h5>
                      </Stack>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <div className={cx('pack-detail__box')}>
                  <div className={cx('pack-detail__box-inner')}>
                    <div className={cx('pack-detail__box-top')}>
                      <h5 className={cx('pack-detail__box-sub')}>Ưu đãi</h5>
                    </div>{' '}
                    <div className={cx('pack-detail__box-content')}>
                      <div className={cx('pack-detail__box-info')} style={{ minHeight: '230px', maxHeight: '230px' }}>
                        <p className={cx('pack-detail__box-des')}>
                          <p dangerouslySetInnerHTML={{ __html: resultSimDetail.content }} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContentText>
          <Stack direction={'column'} alignItems={'center'} sx={{ mt: 1 }}>
            <span className={cx('color-red', 'font-note')}>
              Lưu ý: A/c nạp tiền vào tài khoản rồi mới ấn đăng ký gói
            </span>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Hidden only={['md', 'lg', 'xl']}>
            <ButtonUI
              bgrColorBtn="#005ce0"
              colorText="#fff"
              borderBtn="#005ce0"
              borderRadius={10}
              href={`sms:1543?&body=${sms}`}
              fullWidth
            >
              ĐĂNG KÝ
            </ButtonUI>
          </Hidden>
          <Hidden only={['sm', 'xs']}>
            <span className={cx('color-blue', 'font-note')}>SOẠN CHUẨN CÚ PHÁP ĐĂNG KÝ: {`${sms} Gửi 1543`}</span>
          </Hidden>
        </DialogActions>
      </Dialog>
      <div className={cx(`header-title-${bgrFrame}`)} {...props}>
        {/* <div className={cx(`header-title-item-${bgrFrame}`)}>
        <b> {title}</b>
      </div> */}
        <div className={cx('container_red')}>
          <img src={images.img_similar_pack} alt="" width="100%" />
          <h5 className={cx('similar-pack__name')}>{resultSimDetail.title}</h5>
        </div>
        <div className={cx('detail-item-sim', `font-size-${fontSize}`)}>
          <div className={cx('title-item')}>
            <Stack direction={'column'}>
              <Stack direction={'row'} alignItems={'center'}>
                <img src={images.icon_dola} alt="" width="30px" />
                <span className={cx('cat-chuoi')}>
                  <b className={cx('font-18')}>
                    {numberFormat(resultSimDetail.price)}/{handleConvertMonths(resultSimDetail.dategoi)}
                  </b>
                </span>
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <img src={images.icon_data} alt="" width="30px" />
                <span className={cx('cat-chuoi')} dangerouslySetInnerHTML={{ __html: resultSimDetail.contentshot }} />
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <span className={cx('color-red', 'font-note')}>
                  Lưu ý: A/c nạp tiền vào tài khoản rồi mới ấn đăng ký gói
                </span>
              </Stack>
            </Stack>
          </div>
        </div>
        <div className={cx('btn-item-sim')}>
          <Stack direction={'row'} spacing={1}>
            <ButtonUI
              bgrColorBtn="#398dff"
              colorText="#fff"
              borderBtn="#398dff"
              borderRadius={10}
              href={`sms:1543?&body=${sms}`}
            >
              ĐĂNG KÝ
            </ButtonUI>
            <ButtonUI
              bgrColorBtn="#fff"
              colorText="#398dff"
              borderBtn="#fff"
              borderRadius={10}
              onClick={handleClickOpen}
            >
              <b>Chi tiết</b>
            </ButtonUI>
          </Stack>
        </div>
      </div>
    </>
  );
}

FrameDataSim.propTypes = {
  bgrFrame: PropTypes.string,
  title: PropTypes.string,
  fontSize: PropTypes.string,
  arrDetailSim: PropTypes.array,
  onClick: PropTypes.func,
};

export default FrameDataSim;
