import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ButtonUI } from '../../../../component/CustomerMUI';
import styles from './ordersim.module.scss';
import * as simService from '../../../../services/apiWinServer';
import images from '../../../../assets/images';
import { numberFormatText, numberFormatTextCanvas } from '../../../../utils/helper';

const cx = classNames.bind(styles);
export default function OrderSimDetail({ openDiaLog, onClose, detailSim }) {
  const [nameUser, setNameUser] = useState('');
  const [phoneUser, setPhoneUser] = useState('');
  const [addressUser, setAddressUser] = useState('');
  const [noteUser, setNoteUser] = useState('');
  const [statusOrder, setStatusOrder] = useState(false);
  const [messError, setMessError] = useState('');
  const [packageSim, setPackageSim] = useState({});
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const refCanvas = useRef(null);
  const dataSimVina = {
    showPackage: {
      name: '6VD89P',
      duration: '6 Tháng',
      price: '535000',
      km: 'Tặng 1 Tháng',
      sms: '6VD89P DL 0918524989',
    },
    data_detail: {
      data: '120GB/Tháng (4GB/ngày)',
      ngoaimang: '50 phút ngoại mạng',
      noimang: '1500p nội mạng',
    },
    price_mon: [
      // { name: 'VD89P', duration: '1 Tháng', price: '89000', km: '', sms: 'VD89P DL 0918524989' },
      // { name: '6VD89P', duration: '7 Tháng', price: '435000', km: 'Tặng 1 Tháng', sms: '6VD89P DL 0918524989' },
      // { name: '12VD89P', duration: '14 Tháng', price: '868000', km: 'Tặng 2 Tháng', sms: '12VD89P DL 0918524989' },
      // { name: 'D79P', duration: '1 Tháng', price: '79000', km: '', sms: 'D79P DL 0918524989' },
      // { name: '3D79P', duration: '3 Tháng', price: '237000', km: '', sms: '3D79P DL 0918524989' },
      // { name: '6D79P', duration: '5 Tháng', price: '395000', km: 'Tặng 1 Tháng', sms: '6D79P DL 0918524989' },
      // { name: '12D79P', duration: '10 Tháng', price: '790000', km: 'Tặng 2 Tháng', sms: '12D79P DL 0918524989' },
      // { name: 'VD149', duration: '1 Tháng', price: '149000', km: '', sms: 'VD149 DL 0918524989' },
      // { name: '3VD149', duration: '3 Tháng', price: '375000', km: 'Giảm nửa tháng', sms: '3VD149 DL 0918524989' },
      // { name: '6VD149', duration: '5 Tháng', price: '750000', km: 'Tặng 1 Tháng', sms: '6VD149 DL 0918524989' },
      // { name: '12VD149', duration: '10 Tháng', price: '1500000', km: 'Tặng 2 Tháng', sms: '12VD149 DL 0918524989' },
    ],
  };

  const fetchApiMail = async () => {
    const mail = {
      mail: detailSim.GMAIL_NOTIFI,
      nameuser: nameUser,
      simsearch: detailSim.sim,
      network: detailSim.netWork,
      phoneuser: phoneUser,
      addressuser: addressUser,
      price: numberFormat(detailSim.price),
      noteuser: noteUser,
    };
    const resultAPI = await simService.sendMailSim(mail);
    if (resultAPI.success === 'OK') {
      setNameUser('');
      setPhoneUser('');
      setAddressUser('');
      setNoteUser('');
      setStatusOrder(false);
      onClose();
      navigate('/camon', { replace: true });
    }
  };

  const numberFormat = (number) => {
    let calcNumber;
    if (isNaN(number)) {
      calcNumber = number;
    } else {
      calcNumber = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);
    }
    return calcNumber;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nameUser.length < 4) {
      setMessError('Bạn nhập chưa đầy đủ họ tên');
    } else if (phoneUser.length !== 10) {
      setMessError('Bạn nhập chưa đúng số điện thoại liên hệ');
    } else if (addressUser.length < 15) {
      setMessError('Bạn nhập chưa đầy đủ địa chỉ ');
    } else {
      fetchApiMail();
      setStatusOrder(true);
    }
  };

  const handleUserName = (e) => {
    setNameUser(e.target.value);
    if (e.target.value.length >= 4) {
      setMessError('');
    }
  };

  const handlePhoneUser = (e) => {
    setPhoneUser(e.target.value);
    if (e.target.value.length === 10) {
      setMessError('');
    }
  };

  const handleAddressUser = (e) => {
    setAddressUser(e.target.value);
    if (e.target.value.length >= 15) {
      setMessError('');
    }
  };

  const handleClose = () => {
    setNameUser('');
    setPhoneUser('');
    setAddressUser('');
    setNoteUser('');
    setMessError('');
    onClose();
  };

  const form = useRef();
  // send email

  const handleCanvas = (arrSim) => {
    const canvas = refCanvas.current;
    const ctx = canvas?.getContext('2d');
    const img = new Image();
    img.src =
      arrSim.netWork === 'Mobifone'
        ? images.phoi_mobi
        : arrSim.netWork === 'Vinaphone'
        ? images.phoi_vina
        : arrSim.netWork === 'Viettel'
        ? images.phoi_viettel
        : arrSim.netWork === 'Vietnamobile'
        ? images.phoi_vnmb
        : arrSim.netWork === 'Itelecom'
        ? images.phoi_itel
        : images.phoi_default;
    if (img.src === window.location.origin + '/') {
      ctx.canvas.width = 0;
      ctx.canvas.height = 0;
    } else {
      img.onload = () => {
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        ctx.font = '40px VCENTB';
        ctx.fillText(arrSim.sim, 185, 215);

        if (
          arrSim.netWork === 'Mobifone' ||
          arrSim.netWork === 'Vinaphone' ||
          arrSim.netWork === 'Viettel' ||
          arrSim.netWork === 'Vietnamobile' ||
          arrSim.netWork === 'Itelecom'
        ) {
          ctx.font = 'bold 18px UTM-Avo';
          ctx.fillText('Giá sale', 95, 193);
          ctx.font = 'bold 18px UTM-Avo';
          ctx.fillText(arrSim.price, 100, 217);
        }
      };
    }
  };

  useEffect(() => {
    if (Object.keys(detailSim).length > 0) {
      setTimeout(() => {
        handleCanvas(detailSim);
      }, 100);
    }
  }, [detailSim]);

  const DownloadCanvasAsImage = () => {
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', `sim_${detailSim.sim}`);
    let canvas = refCanvas.current;
    canvas.toBlob(function (blob) {
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute('href', url);
      downloadLink.click();
    });
  };

  const handleChangePackage = (e) => {
    const pakName = e.target.value;
    const res = dataSimVina?.price_mon?.find((s) => s.name === pakName);
    setPackageSim(res);
  };

  return (
    <div>
      <Dialog open={openDiaLog} onClose={onClose} fullScreen>
        <AppBar sx={{ position: 'relative', background: detailSim?.background }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Đóng lại
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid item md={8} width="100%">
            {/* <Card variant="outlined"> */}
            <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
              {detailSim && (
                <div className={cx('detail-sim')}>
                  {detailSim.sim && (
                    <p className={cx('detail-sim-title')}>
                      Số Sim:{' '}
                      <span
                        className={cx(
                          detailSim.netWork === 'Mobifone'
                            ? 'color-df'
                            : detailSim.netWork === 'Vinaphone'
                            ? 'color-blue'
                            : detailSim.netWork === 'Itelecom'
                            ? 'color-red'
                            : 'color-red',
                        )}
                      >
                        {detailSim.sim}
                      </span>
                    </p>
                  )}
                  {detailSim.price && (
                    <p className={cx('detail-sim-title-1')}>
                      Giá sale:{' '}
                      <span className={cx('color-red')}>
                        <b>{numberFormatText(detailSim.price)}</b>
                      </span>
                    </p>
                  )}
                  {detailSim.netWork && (
                    <p className={cx('detail-sim-title-1')}>
                      Nhà mạng:{' '}
                      <span
                        className={cx(
                          detailSim.netWork === 'Mobifone'
                            ? 'color-df'
                            : detailSim.netWork === 'Vinaphone'
                            ? 'color-blue'
                            : detailSim.netWork === 'Itelecom'
                            ? 'color-red'
                            : 'color-red',
                        )}
                      >
                        <b>{detailSim.netWork}</b>
                      </span>
                    </p>
                  )}
                  {detailSim.subCard && (
                    <p className={cx('detail-sim-title-1')}>
                      Thuê bao: <b>{detailSim.subCard}</b>
                    </p>
                  )}

                  {/* {detailSim.cateSim && (
                    <p className={cx('detail-sim-title-1')}>
                      Loại sim: <b>{detailSim.cateSim}</b>
                    </p>
                  )} */}
                  {detailSim.means && (
                    <p className={cx('detail-sim-title-1')}>
                      Ý Nghĩa: <b className={cx('color-red')}>{detailSim.means}</b>
                    </p>
                  )}
                  {/* {detailSim.noteSims && (
                    <p className={cx('detail-sim-title-1')}>
                      Ghi chú: <i>{detailSim.noteSims}</i>
                    </p>
                  )} */}
                  <p className={cx('detail-sim-title-1')}>
                    <i>Giao sim {detailSim.sim} toàn quốc 24/7</i>
                  </p>
                </div>
              )}
            </CardContent>
            {/* </Card> */}
          </Grid>
          <Grid item md={4}>
            <div className={cx('container')}>
              <canvas ref={refCanvas} />
              <div className={cx('download-image')} onClick={DownloadCanvasAsImage}>
                <DownloadForOfflineIcon sx={{ color: '#FFF' }} />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid item>
          <Paper elevation={2}>
            <Card variant="outlined">
              <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                <div className={cx('order-sim')}>
                  <h2>Đặt mua sim </h2>
                  <h3>- Miễn phí giao sim toàn quốc</h3>
                  <h3>- Nhận sim kiểm tra xong mới phải trả tiền</h3>
                </div>
              </CardContent>
            </Card>

            <Card variant="outlined">
              <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                <form ref={form} onSubmit={handleSubmit} className={cx('order-sim-input')}>
                  <TextField
                    value={detailSim.sim}
                    id="outlined-basic"
                    fullWidth
                    label="Sim mua"
                    variant="outlined"
                    name="sim"
                    type="text"
                    sx={{ display: 'none' }}
                  />
                  <TextField
                    value={nameUser}
                    onChange={handleUserName}
                    id="outlined-basic"
                    fullWidth
                    label="Họ và Tên"
                    variant="outlined"
                    name="userName"
                    type="text"
                    required
                    error={nameUser.length < 4}
                  />
                  <TextField
                    value={phoneUser}
                    onChange={handlePhoneUser}
                    id="outlined-basic"
                    fullWidth
                    label="Số điện thoại liên hệ"
                    variant="outlined"
                    name="phoneUser"
                    type="number"
                    required
                    error={phoneUser.length < 10}
                  />
                  <TextField
                    value={addressUser}
                    onChange={handleAddressUser}
                    id="outlined-basic"
                    fullWidth
                    label="Địa chỉ giao sim"
                    variant="outlined"
                    name="addressUser"
                    type="text"
                    required
                    error={addressUser.length < 15}
                  />

                  <TextField
                    value={noteUser}
                    onChange={(e) => setNoteUser(e.target.value)}
                    id="outlined-basic"
                    fullWidth
                    label="Yêu cầu khác (Không bắt buộc)"
                    variant="outlined"
                    name="noteUser"
                    type="text"
                  />
                  <TextField
                    value={numberFormat(detailSim.price)}
                    id="outlined-basic"
                    fullWidth
                    label="Tổng tiền trả khi nhận sim"
                    variant="outlined"
                    name="price"
                    type="text"
                    sx={{ display: 'none' }}
                  />
                  <Typography variant="h5" color="red">
                    <b>Tổng tiền: {numberFormat(detailSim.price)}</b>
                  </Typography>
                  <Typography variant="h5" color="error">
                    <b>{messError}</b>
                  </Typography>
                  <ButtonUI
                    sx={{ p: 1, fontSize: '19px' }}
                    color="error"
                    borderBtn="#ff0000"
                    borderRadius={4}
                    fullWidth
                    disabled={statusOrder}
                    type="submit"
                  >
                    Đặt Mua
                  </ButtonUI>
                </form>
                <div className={cx('order-sim-input')}>
                  {statusOrder && <LinearProgress color="error" />}
                  <ButtonUI sx={{ fontSize: '19px' }} borderRadius={4} fullWidth>
                    Hoặc Gọi mua hàng: {detailSim.hotline}
                  </ButtonUI>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <b>HƯỚNG DẪN MUA SIM</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={cx('guide-order-sim')}>
                        <p>
                          <b>Bước 1: ĐẶT SIM</b>
                          <br />
                          Quý khách điền đầy đủ thông tin và đặt mua, hoặc liên hệ {detailSim.hotline} để được đặt hàng.
                        </p>
                        <p>
                          <b>Bước 2: XÁC NHẬN ĐƠN HÀNG</b>
                          <br />
                          Khi nhận được đơn hàng của quý khách, nhân viên bán hàng sẽ kiểm tra số trong kho của chúng
                          tôi và gọi điện lại báo cho quý khách về tình trạng sim quý khách đã đặt mua.
                        </p>
                        <p>
                          <b>Bước 3 &amp; 4: ĐĂNG KÝ THÔNG TIN VÀ GIAO SIM</b>
                          <br />▪ Cách 1: Giao sim tận nhà miễn phí trong ngày
                          <br />– Chúng tôi sẽ có giao dịch viên giao sim và đăng ký chính chủ tại nơi quý khách đang ở
                          hiện tại.
                          <br />– Quý khách kiểm tra đúng chính chủ, đúng số sim quý khách mới phải thanh toán
                        </p>
                        <p>
                          ▪ Cách 2: Nhận sim tại cửa hàng Vinaphone gần nhất
                          <br />– Giao dịch viên của chúng tôi sẽ cùng quý khách ra điểm giao dịch gần nhất để giao
                          dịch.
                          <br />– Quý khách kiểm tra đúng chính chủ, đúng số sim quý khách mới phải thanh toán.
                        </p>
                        <p>
                          ▪ Cách 3: Đặt cọc và thanh toán tiền còn lại khi nhận sim đối sim giá dưới 1tr: Quý khách đảm
                          bảo việc mua hàng bằng cách đặt cọc tối thiểu 10% giá trị sim qua chuyển khoản hoặc mã thẻ
                          cào. Chúng tôi sẽ gửi bưu điện phát sim đến tay quý khách và thu tiền còn lại (Hệ thống bưu
                          điện trên cả nước đều cung cấp dịch vụ thu hộ tiền cho người bán – gọi tắt là COD. Theo đó,
                          bưu điện sẽ giao hàng (sim) đến tận tay quý khách và thu tiền cho chúng tôi)
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Dialog>
    </div>
  );
}
