import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useTheme } from '@mui/material/styles';

import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SearchIcon from '@mui/icons-material/Search';
import SimCardIcon from '@mui/icons-material/SimCard';

import styles from './digishop.module.scss';
import { Badge, Grid, Hidden, Modal, Paper, Stack } from '@mui/material';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import { Box } from '@mui/system';
import { ButtonUI } from '../../component/CustomerMUI';
import * as simService from '../../services/apiWinServer';
import { numberFormat } from '../../utils/helper';
import { Link } from 'react-router-dom';
import ThemeLoad from '../../component/CustomerMUI/ThemeLoad/ThemeLoad';
const cx = classNames.bind(styles);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 333,
  bgcolor: '#fff',
  border: '2px solid #f4367f',
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
};

function CheckPackage() {
  useEffect(() => {
    document.title = 'Kho số Vinaphone giá tận gốc';
  });

  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [checkTBPackage, setCheckTBPackage] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [checkTBPackageDetail, setCheckTBPackageDetail] = useState([]);
  const [menuSim, setMenuSim] = useState(1);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setLoadingDetail(true);
  };
  const handleClose = () => setOpen(false);

  const fetchApiCheckPackageSim = async () => {
    const result = await simService.checkPackageSim(textSearch);
    if (result.message === 'success') {
      setLoading(false);
      setCheckTBPackage(result);
    } else {
      setLoading(false);
      setCheckTBPackage(result);
    }
    // console.log(result);
  };

  const fetchApiCheckPackageDetailSim = async (id, valPack) => {
    const result = await simService.checkPackageDetailSim(id);
    const arrDetail = [{ production: { ...valPack } }, ...result.data];
    const res = arrDetail.find((s) => s.production.id === id);
    if (result.message === 'success') {
      setCheckTBPackageDetail({
        data: arrDetail,
        nameCode: res.production.packageCode,
        isIdCode: id,
        showPackage: res.production,
      });
      setLoadingDetail(false);
    } else {
      setLoadingDetail(false);
    }

    // console.log(checkTBPackageDetail);
  };

  const onClickCheckPackageTb = () => {
    setLoading(true);
    fetchApiCheckPackageSim();
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      fetchApiCheckPackageSim();
    }
  };
  const onChangTextSim = (e) => {
    setTextSearch(e.target.value);
  };

  const onUpdateCheckTBPackageDetail = (valPack, id) => {
    setCheckTBPackageDetail({
      data: checkTBPackageDetail.data,
      nameCode: checkTBPackageDetail.nameCode,
      isIdCode: id,
      showPackage: valPack.production,
    });
  };

  return (
    <div>
      <header className={cx('wrapper')}>
        <div className={cx('inner')}>
          <Grid container>
            <Grid item xs={12} className={cx('logo-center')}>
              <label className={cx('color-trang')}>
                <b className={cx('font-size-title')}>
                  {(menuSim === 0 && `KHO SỐ VINAPHONE`) ||
                    (menuSim === 1 && `KIỂM TRA GÓI MẠNG THEO THUÊ BAO`) ||
                    (menuSim === 2 && `CÁCH THỨC GIAO DỊCH SIM`)}
                </b>
              </label>
            </Grid>

            <Grid item md={12} xs={12} sx={{ mb: 7.5 }}>
              {/* LAYOUT TÌM KIẾM THEO GÓI CƯỚC */}
              <Paper elevation={3} sx={{ p: '0px 2px', display: 'flex', alignItems: 'center', borderRadius: 2 }}>
                {/* <IconButton>
                  <SearchIcon />
                </IconButton> */}
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Nhập số thuê bao cần kiểm tra gói cước"
                  inputProps={{ 'aria-label': 'Nhập số thuê bao cần kiểm tra gói cước' }}
                  onChange={onChangTextSim}
                  onKeyPress={handleEnterKey}
                />
                {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                <IconButton
                  sx={{ m: '2px' }}
                  className={cx('bgr-default')}
                  aria-label="directions"
                  onClick={onClickCheckPackageTb}
                >
                  <SearchIcon sx={{ color: 'aliceblue' }} />
                </IconButton>
              </Paper>
              {/* ĐÓNG LAYOUT TÌM KIẾM THEO GÓI CƯỚC */}
            </Grid>
          </Grid>
        </div>
      </header>

      <div className={cx('inner')}>
        <Grid container item>
          <Grid item md={6} xs={12} sx={{ mb: 2, mt: -5, ml: 'auto', mr: 'auto', zIndex: 10 }}>
            <Paper
              elevation={3}
              className={cx('box-shadow-menu')}
              sx={{ p: '4px 2px', display: 'flex', alignItems: 'center' }}
            >
              <Grid item md={4} xs={4} sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => setMenuSim(0)}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Badge
                    color={menuSim === 0 ? 'success' : 'default'}
                    overlap="circular"
                    badgeContent=" "
                    variant="dot"
                  >
                    <IconButton
                      className={cx(menuSim === 0 ? 'active-cate' : 'no-active-cate')}
                      aria-label="directions"
                      component={Link}
                      to="/"
                    >
                      <SimCardIcon className={cx('color-cate')} />
                    </IconButton>
                  </Badge>
                  <b>Sim Số</b>
                </Box>
              </Grid>

              <Grid item md={4} xs={4} sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => setMenuSim(1)}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Badge
                    color={menuSim === 1 ? 'success' : 'default'}
                    overlap="circular"
                    badgeContent=" "
                    variant="dot"
                  >
                    <IconButton
                      className={cx(menuSim === 1 ? 'active-cate' : 'no-active-cate')}
                      aria-label="directions"
                    >
                      <SystemSecurityUpdateGoodIcon className={cx('color-cate')} />
                    </IconButton>
                  </Badge>
                  <b>Kiểm Tra Gói Cước</b>
                </Box>
              </Grid>

              <Grid item md={4} xs={4} sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => setMenuSim(2)}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Badge
                    color={menuSim === 2 ? 'success' : 'default'}
                    overlap="circular"
                    badgeContent=" "
                    variant="dot"
                  >
                    <IconButton
                      className={cx(menuSim === 2 ? 'active-cate' : 'no-active-cate')}
                      aria-label="directions"
                      component={Link}
                      to="/cach-giao-dich"
                    >
                      <LocalShippingIcon className={cx('color-cate')} />
                    </IconButton>
                  </Badge>
                  <b>Giao Dịch</b>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>

      {/* LAYOUT TÌM KIẾM THEO GÓI CƯỚC */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {loadingDetail && <ThemeLoad />}
          {!loadingDetail && (
            <Box>
              <Grid item md={12} xs={12}>
                <div className={cx(`vnt_goi`)}>
                  <div className={cx(`tengoi`)}>{checkTBPackageDetail?.showPackage?.packageCode}</div>
                  <div className={cx(`giagoi`)}>
                    {numberFormat(checkTBPackageDetail?.showPackage?.price.toString())}/{' '}
                    {checkTBPackageDetail?.showPackage?.duration / 30} tháng
                  </div>
                  <div
                    className={cx(`pad-5`)}
                    dangerouslySetInnerHTML={{ __html: checkTBPackageDetail?.showPackage?.shortDescription }}
                  />

                  <Stack direction="row" spacing={1} justifyContent={'center'} sx={{ mb: 1 }}>
                    {checkTBPackageDetail?.data?.map((row, i) => (
                      <ButtonUI
                        key={i}
                        bgrColorBtn={row.production.id === checkTBPackageDetail?.isIdCode ? '#ff000026' : '#F3F4F6'}
                        colorText={row.production.id === checkTBPackageDetail?.isIdCode ? '#ff0000' : '#444444'}
                        borderBtn={row.production.id === checkTBPackageDetail?.isIdCode ? '#ff00004a' : '#ccc'}
                        onClick={() => onUpdateCheckTBPackageDetail(row, row.production.id)}
                      >
                        <b>{row.production.duration / 30} tháng</b>
                      </ButtonUI>
                    ))}
                  </Stack>
                </div>
              </Grid>
              <Stack direction="column" sx={{ mt: 1 }} justifyContent={'center'}>
                <Hidden only={['sm', 'xs']}>
                  <p className={cx(`data`)}>
                    <span>
                      Soạn Cú Pháp:{' '}
                      <b>
                        {checkTBPackageDetail?.showPackage?.duration / 30 === 1
                          ? ''
                          : checkTBPackageDetail?.showPackage?.duration / 30}
                        {checkTBPackageDetail?.nameCode} DL 0918524989 gửi 1543
                      </b>
                    </span>
                  </p>
                </Hidden>
                <p className={cx(`data`)}>
                  <span>
                    <b>LƯU Ý:</b> Quý khách đăng ký gói giữ nguyên cú pháp <br /> để hệ thống đăng ký thành công
                  </span>
                </p>
                <ButtonUI
                  color="error"
                  href={`sms:1543?&body=${
                    checkTBPackageDetail?.showPackage?.duration / 30 === 1
                      ? ''
                      : checkTBPackageDetail?.showPackage?.duration / 30
                  }${checkTBPackageDetail?.nameCode} DL 0918524989`}
                  fullWidth
                >
                  <b>ĐĂNG KÝ</b>
                </ButtonUI>
              </Stack>
            </Box>
          )}
        </Box>
      </Modal>
      <div className={cx('inner')}>
        <Grid container item spacing={0.5} md={6} xs={12} sx={{ mb: 2, ml: 'auto', mr: 'auto' }}>
          {loading && <ThemeLoad />}
          {loading && <ThemeLoad />}
          {!loading && (
            <div className={cx(`center-mess`)}>
              <p>{checkTBPackage.message !== 'success' ? checkTBPackage.message : ''}</p>
            </div>
          )}
          {!loading &&
            checkTBPackage?.data?.map((pak, i) => (
              <Grid item key={i} md={6} xs={6}>
                <div className={cx(`vnt_goi`)}>
                  <div className={cx(`tengoi`)}>{pak.packageCode}</div>
                  <div className={cx(`giagoi`)}>{numberFormat(pak?.price.toString())}/ tháng</div>
                  <div className={cx(`pad-5`)} dangerouslySetInnerHTML={{ __html: pak.shortDescription }} />
                  <div className={cx(`data`)}>
                    <ButtonUI
                      bgrColorBtn="#ff000026"
                      colorText="#ff0000"
                      borderBtn="#ff00004a"
                      onClick={() => {
                        fetchApiCheckPackageDetailSim(pak.id, pak);
                        handleOpen();
                      }}
                    >
                      <b>CHI TIẾT</b>
                    </ButtonUI>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>
      {/*ĐÓNG LAYOUT TÌM KIẾM THEO GÓI CƯỚC */}
    </div>
  );
}

export default CheckPackage;
