import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ReplyIcon from '@mui/icons-material/Reply';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Tabs,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ButtonUI } from '../../../component/CustomerMUI';

import * as listSimService from '../../../services/listSim';
import * as simService from '../../../services/apiWinServer';
import { LogicMenhSim } from '../LogicSearch/LogicMenhSim';
import images from '../../../assets/images';
import {
  getLocalStorage,
  numberFormatText,
  numberFormatTextCanvas,
  setLocalStorage,
  totalDiscount,
} from '../../../utils/helper';
import Footer from '../../../layouts/MainLayout/Footer/Footer';
import { LogicCategoryShowName } from '../LogicSearch/LogicCategoryShowName';
import { DataSim } from '../../../utils/DataSim';
import * as userIDService from '../../../services/apiUsers';
import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
import ThemeLoad from '../../../component/CustomerMUI/ThemeLoad/ThemeLoad';
import { API_URL_SIMSO } from '../../../config/config';

import classNames from 'classnames/bind';
import styles from './DetailHome.module.scss';
import SimpleSnackbar from '../../../component/CustomerMUI/FrameInfoSim/NotifiAddCart';
import { stubFalse } from 'lodash-es';
const cx = classNames.bind(styles);
export default function DetailShop({ onClose }) {
  const [nameUser, setNameUser] = useState('');
  const [phoneUser, setPhoneUser] = useState('');
  const [addressUser, setAddressUser] = useState('');
  const [noteUser, setNoteUser] = useState('');
  const [messSuccess, setMessSuccess] = useState(false);
  const [isSimDaily, setIsSimDaily] = useState(false);
  const [statusOrder, setStatusOrder] = useState(false);
  const [simInfinteResult, setSimInfinteResult] = useState({});
  const [messError, setMessError] = useState('');
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const refCanvas = useRef(null);
  const params = useParams();
  const [authDomain, setAuthDomain] = useState({});
  const contextApiUser = useContext(ThemeContext);
  let textSimHeader = contextApiUser.textSearch;
  const [loadingTheme, setLoadingTheme] = useState(true);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedDetailProduct, setSelectedDetailProduct] = useState(0);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [startX, setStartX] = useState(null);
  const [moveX, setMoveX] = useState(null);
  const [openCart, setOpenCart] = useState(false);

  const handleImageChange = (index) => {
    setSelectedImage(index);
  };
  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setMoveX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (moveX && startX) {
      const difference = startX - moveX;

      if (difference > 50) {
        if (selectedImage < simInfinteResult?.images.length - 1) {
          setSelectedImage(selectedImage + 1);
        }
      } else if (difference < -50) {
        if (selectedImage > 0) {
          setSelectedImage(selectedImage - 1);
        }
      }

      setStartX(null);
      setMoveX(null);
    }
  };
  const handleDetailProductChange = (index) => {
    setSelectedDetailProduct(index);
  };

  const handleQuantityChange = (index) => {
    setSelectedQuantity(index);
  };

  const fetchApiSim = async () => {
    const arr = params?.id?.split('.');
    const idShop = arr[arr.length - 1];
    if (idShop) {
      const resultAPISIM = await listSimService.listShopId(idShop);
      if (typeof resultAPISIM['data'] !== 'undefined') {
        setSimInfinteResult(resultAPISIM);
        setLoadingTheme(false);
        document.title = resultAPISIM['data']['title'];
      }
    }
  };
  const fetchApiUserID = async () => {
    const resultAPISIM = await userIDService.listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setLocalStorage('khoso', resultAPISIM[0]);
      setAuthDomain(resultAPISIM[0]);
      contextApiUser.toggleDataUser(resultAPISIM[0]);
    }
  };

  useEffect(() => {
    fetchApiSim();
    if (getLocalStorage('khoso')?.link_domain === hostname) {
      setAuthDomain(getLocalStorage('khoso'));
      contextApiUser.toggleDataUser(getLocalStorage('khoso'));
    } else {
      fetchApiUserID();
    }
  }, []);

  const fetchApiMail = async () => {
    const mail = {
      mail: authDomain['GMAIL_NOTIFI'],
      nameuser: nameUser,
      namesanpham: simInfinteResult?.data.title,
      masanpham: simInfinteResult?.data.product_code,
      loaisanpham:
        simInfinteResult?.dataDetails.length > 0 ? simInfinteResult?.dataDetails[selectedDetailProduct]['title'] : '',
      phoneuser: phoneUser,
      addressuser: addressUser,
      price:
        simInfinteResult?.dataDetails.length > 0
          ? numberFormat(simInfinteResult?.dataDetails[selectedDetailProduct].price * selectedQuantity)
          : numberFormat(simInfinteResult?.data.price * selectedQuantity),
      noteuser: noteUser,
    };
    // console.log(mail);
    const resultAPI = await simService.sendMailShop(mail);
    if (resultAPI.success === 'OK') {
      setNameUser('');
      setPhoneUser('');
      setAddressUser('');
      setNoteUser('');
      setMessSuccess(true);
      setStatusOrder(false);
      navigate('/camon', { replace: true });
    }
    console.log(resultAPI);
  };

  const numberFormat = (number) => {
    let calcNumber;
    if (isNaN(number)) {
      calcNumber = number;
    } else {
      calcNumber = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);
    }
    return calcNumber;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nameUser.length < 4) {
      setMessError('Bạn nhập chưa đầy đủ họ tên');
    } else if (phoneUser.length !== 10) {
      setMessError('Bạn nhập chưa đúng số điện thoại liên hệ');
    } else if (addressUser.length < 15) {
      setMessError('Bạn nhập chưa đầy đủ địa chỉ ');
    } else {
      fetchApiMail();
      setStatusOrder(true);
    }
  };

  const handleUserName = (e) => {
    setNameUser(e.target.value);
    if (e.target.value.length >= 4) {
      setMessError('');
    }
  };

  const handlePhoneUser = (e) => {
    setPhoneUser(e.target.value);
    if (e.target.value.length === 10) {
      setMessError('');
    }
  };

  const handleAddressUser = (e) => {
    setAddressUser(e.target.value);
    if (e.target.value.length >= 15) {
      setMessError('');
    }
  };

  const handleClose = () => {
    navigate(-1);
    setNameUser('');
    setPhoneUser('');
    setAddressUser('');
    setNoteUser('');
    setMessError('');
  };

  const form = useRef();

  const onAddShop = (title, price, cate, soluong = 1, code) => {
    let obj = { title: title, price: price, cate: cate, soluong: soluong, code: code };
    let arrShop = getLocalStorage('addShop') || [];
    setLocalStorage('addShop', [...arrShop, obj]);
    setOpenCart(true);
    setTimeout(() => {
      setOpenCart(false);
    }, 3000);
  };

  return (
    <Container sx={{ pb: '70px', pl: '0px', pr: '0px' }}>
      <Grid container justifyContent="center" sx={{ bgcolor: 'seashell' }}>
        <Grid item md={12} xs={12}>
          {/* {authDomain?.background && (
            <AppBar sx={{ position: 'relative', background: authDomain?.background?.split(',')[0] }}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <ReplyIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  <b>{simInfinteResult['data'] && simInfinteResult['data']['title']}</b>
                </Typography>
              </Toolbar>
            </AppBar>
          )} */}
          <Paper elevation={3} sx={{ mb: 2 }}>
            {loadingTheme && (
              <Grid item xs={12}>
                <Paper elevation={2}>
                  <Card variant="outlined">
                    <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                      <div style={{ padding: '10px' }}>
                        <Skeleton variant="rectangular" height={125} />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                      </div>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            )}
            {!simInfinteResult?.data?.title && !loadingTheme && (
              <h4 style={{ textAlign: 'center' }}>SẢN PHẨM ĐÃ BÁN...</h4>
            )}
            {simInfinteResult?.data?.title && (
              <Grid container alignItems={'flex-start'} sx={{ p: '5px' }}>
                <Grid item md={6}>
                  <div
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    style={{
                      // margin: 5,
                      transform: `translateX(${selectedImage}px)`,
                      transition: 'transform 0.2s',
                      width: '100%',
                      height: 'auto',
                    }}
                  >
                    <img
                      src={`${API_URL_SIMSO}/imgshop?name=${
                        simInfinteResult?.images.length > 0 && simInfinteResult?.images[selectedImage].title
                      }.jpg`}
                      style={{ borderRadius: '10px' }}
                      alt="Large Image"
                      width={'100%'}
                    />
                  </div>
                  <div>
                    {simInfinteResult?.images.map((image, index) => (
                      <img
                        key={index}
                        src={`${API_URL_SIMSO}/imgshop?name=${image.title}.jpg`}
                        alt={`Small Image ${index + 1}`}
                        style={{
                          width: '50px',
                          height: '50px',
                          margin: '5px',
                          cursor: 'pointer',
                          border: selectedImage === index ? '1px solid red' : '1px solid #ccc',
                          borderRadius: '10px',
                        }}
                        onClick={() => handleImageChange(index)}
                      />
                    ))}
                  </div>
                </Grid>
                <Grid item md={6}>
                  <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                    {simInfinteResult?.data?.title && (
                      <div className={cx('detail-sim-1')}>
                        {simInfinteResult?.data?.title && (
                          <div className={cx('detail-sim-title', 'dis-flex-center')}>
                            <h1 style={{ fontSize: '0.8em' }}>{simInfinteResult?.data?.title}</h1>
                          </div>
                        )}

                        {simInfinteResult?.data?.brand && (
                          <div>
                            <h5 style={{ fontSize: '1em' }}>Thương Hiệu: {simInfinteResult?.data?.brand}</h5>
                          </div>
                        )}
                        <Paper
                          elevation={0}
                          sx={{
                            mb: 1,
                            p: 1,
                            background: authDomain?.background?.split(',')[0] + '1c',
                            borderRadius: 2,
                          }}
                        >
                          <div className={cx('dis-flex-center')}>
                            {simInfinteResult?.data?.price_old && (
                              <p className={cx('price-old-item')}>{numberFormat(simInfinteResult?.data?.price_old)}</p>
                            )}
                            {simInfinteResult?.data?.price && (
                              <p className={cx('detail-sim-title-2')}>
                                <span className={cx('color-red')}>
                                  <b>{numberFormat(simInfinteResult?.data?.price)}</b>
                                </span>
                              </p>
                            )}
                            <span className={cx('note-phamtramsale')}>
                              <b>
                                {simInfinteResult?.data?.price_old &&
                                  totalDiscount(simInfinteResult?.data?.price, simInfinteResult?.data?.price_old)}
                              </b>
                              <b> GIẢM</b>
                            </span>
                          </div>
                        </Paper>

                        {simInfinteResult?.dataDetails?.length > 0 && (
                          <Grid container spacing={1} alignItems={'center'}>
                            <p className={cx('detail-sim-title-1')} style={{ marginLeft: 10, fontWeight: 'bold' }}>
                              Loại:
                            </p>
                            <Grid item>
                              {simInfinteResult?.dataDetails.map((item, i) => (
                                <ButtonUI
                                  colorText={selectedDetailProduct === i ? 'red' : '#000'}
                                  borderBtn={selectedDetailProduct === i ? 'red' : '#ccc'}
                                  bgrColorBtn={selectedDetailProduct === i ? '#f1000014' : '#f3f4f678'}
                                  borderRadius={5}
                                  disabled={statusOrder}
                                  key={i}
                                  spacing={1}
                                  sx={{ m: 0.3 }}
                                  onClick={() => handleDetailProductChange(i)}
                                >
                                  <span style={{ lineHeight: '1.5' }}>
                                    {' '}
                                    {item?.title} <br /> {item?.price && numberFormat(item?.price)}
                                  </span>
                                </ButtonUI>
                              ))}
                            </Grid>
                          </Grid>
                        )}

                        <Stack direction={'row'} spacing={1} sx={{ mt: 2 }} alignItems={'center'}>
                          <label>
                            <b>Số lượng</b>
                          </label>
                          <TextField
                            defaultValue={selectedQuantity}
                            label="Số lượng"
                            variant="outlined"
                            name="sim"
                            type="number"
                            size="small"
                            sx={{ width: '100px' }}
                            onChange={(e) => handleQuantityChange(e.target.value)}
                          />
                          <Button size="small" color="success" variant="contained">
                            <AddShoppingCartIcon
                              onClick={() =>
                                onAddShop(
                                  simInfinteResult?.dataDetails.length > 0
                                    ? `${simInfinteResult?.data?.title}(${simInfinteResult?.dataDetails[selectedDetailProduct]['title']})`
                                    : simInfinteResult?.data?.title,
                                  simInfinteResult?.dataDetails.length > 0
                                    ? numberFormat(
                                        simInfinteResult?.dataDetails[selectedDetailProduct].price * selectedQuantity,
                                      )
                                    : numberFormat(simInfinteResult?.data.price * selectedQuantity),
                                  'sp',
                                  selectedQuantity,
                                  simInfinteResult?.data?.product_code,
                                )
                              }
                            />
                          </Button>
                          {openCart && <SimpleSnackbar Notifi="Đã thêm vào giỏ hàng" />}
                        </Stack>

                        {simInfinteResult?.data.note && (
                          <p className={cx('detail-sim-title-1')}>
                            <i>{simInfinteResult?.data?.note}</i>
                          </p>
                        )}
                        <br />
                        <Grid container spacing={1} alignItems={'center'}>
                          <p className={cx('detail-sim-title-1')} style={{ marginLeft: 10, fontWeight: 'bold' }}>
                            Chi tiết sản phẩm:
                          </p>
                          <div className={cx('css-contents-shop')} style={{ marginLeft: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: simInfinteResult?.data?.details }} />
                          </div>
                        </Grid>
                      </div>
                    )}
                  </CardContent>
                </Grid>
              </Grid>
            )}
          </Paper>
          {simInfinteResult?.data?.title && authDomain['GMAIL_NOTIFI'] && (
            <Grid item>
              <Paper elevation={2}>
                <Card variant="outlined">
                  <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                    <div className={cx('order-sim')}>
                      <h2>Đặt mua sản phẩm </h2>
                      {/* <h3>- Miễn phí giao sim toàn quốc</h3>
                      <h3>- Nhận sim kiểm tra xong mới phải trả tiền</h3> */}
                    </div>
                  </CardContent>
                </Card>

                <Card variant="outlined">
                  <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                    <form ref={form} onSubmit={handleSubmit} className={cx('order-sim-input')}>
                      <TextField
                        defaultValue={simInfinteResult?.data?.title}
                        id="outlined-basic"
                        fullWidth
                        label="Sim mua"
                        variant="outlined"
                        name="sim"
                        type="text"
                        sx={{ display: 'none' }}
                      />
                      <TextField
                        defaultValue={nameUser}
                        onChange={handleUserName}
                        id="outlined-basic"
                        fullWidth
                        label="Họ và Tên"
                        variant="outlined"
                        name="userName"
                        type="text"
                        required
                        error={nameUser.length < 4}
                      />
                      <TextField
                        defaultValue={phoneUser}
                        onChange={handlePhoneUser}
                        id="outlined-basic"
                        fullWidth
                        label="Số điện thoại liên hệ"
                        variant="outlined"
                        name="phoneUser"
                        type="number"
                        required
                        error={phoneUser.length < 10}
                      />
                      <TextField
                        defaultValue={addressUser}
                        onChange={handleAddressUser}
                        id="outlined-basic"
                        fullWidth
                        label="Địa chỉ giao hàng"
                        variant="outlined"
                        name="addressUser"
                        type="text"
                        required
                        error={addressUser.length < 15}
                      />
                      <TextField
                        defaultValue={noteUser}
                        onChange={(e) => setNoteUser(e.target.value)}
                        id="outlined-basic"
                        fullWidth
                        label="Yêu cầu khác (Không bắt buộc)"
                        variant="outlined"
                        name="noteUser"
                        type="text"
                      />
                      <TextField
                        defaultValue={numberFormat(
                          simInfinteResult?.dataDetails.length > 0
                            ? simInfinteResult?.dataDetails[selectedDetailProduct].price
                            : simInfinteResult?.data.price,
                        )}
                        id="outlined-basic"
                        fullWidth
                        label="Tổng tiền trả khi nhận sim"
                        variant="outlined"
                        name="price"
                        type="text"
                        sx={{ display: 'none' }}
                      />
                      <div> </div>
                      <Typography variant="h5" color="error">
                        <b>{messError}</b>
                      </Typography>
                      <ButtonUI
                        sx={{ p: 1, fontSize: '19px' }}
                        color="error"
                        borderBtn="#ff0000"
                        borderRadius={4}
                        fullWidth
                        disabled={statusOrder}
                        type="submit"
                      >
                        Đặt Mua
                      </ButtonUI>
                    </form>
                    <div className={cx('order-sim-input')}>
                      {statusOrder && <LinearProgress color="error" />}
                      <ButtonUI sx={{ fontSize: '19px' }} borderRadius={4} fullWidth>
                        {authDomain['HOTTLINE_SIM'] ? ` Hoặc Gọi mua hàng: ${authDomain['HOTTLINE_SIM']}` : ''}
                      </ButtonUI>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <b>HƯỚNG DẪN MUA HÀNG</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={cx('guide-order-sim')}>
                            <p>
                              <b>Bước 1: ĐẶT HÀNG</b>
                              <br />
                              Quý khách điền đầy đủ thông tin và đặt mua{' '}
                              {authDomain['HOTTLINE_SIM'] ? `, hoặc liên hệ ${authDomain['HOTTLINE_SIM']}` : ``} để được
                              đặt hàng.
                            </p>
                            <p>
                              <b>Bước 2: XÁC NHẬN ĐƠN HÀNG</b>
                              <br />
                              Khi nhận được đơn hàng của quý khách, nhân viên bán hàng sẽ kiểm tra và gọi điện lại báo
                              cho quý khách xác nhận đơn hàng.
                            </p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
}
