import auth from './auth';
const hostname = window.location.hostname;
const routes = {
  home: '/',
  homeTowDetail: '/sim',
  homeCart: '/gio-hang',
  homeTowShop: '/shop',
  goicuocvina: '/kiem-tra-goi-cuoc',
  homeDetail: '/s/:sim',
  checkRouters: '/:id',
  homeDetailShop: '/shop/:id',
  homeCate: '/sim-tat-ca/:catePs',
  homeMenh: '/sim-menh/:menhPs',
  homeFormatSim: '/sim-dang/:dangsimPs',
  homeNewWork: '/sim-mang/:mangPs',
  homeRange: '/sim-theo-gia/:rangePs',
  homeTranhSo: '/ts/:tranhso/:istranhso',
  homeTimKiem: '/tim-sim/:search',
  homeStartEnd: '/tim-tong-:tongdau-:tongcuoi',
  homeThuebao: '/thue-bao/:thuebaoPs',
  flashsaleShop: '/sim-so-dep-sale',
  news: '/bai-viet',
  newsid: '/bai-viet/:idNews',
  order: '/order',
  info: '/info',
  hopmenh: '/ngu-hanh-tuong-sinh',
  login: '/login',
  success: '/camon',
  ctv: '/ctv',
  simdigi: '/flash-sale',
  dashboard: '/dashboard',
  dashboardUsers: '/dashboard/users',
  dashboardShops: '/dashboard/shops',
  dashboardLogin: '/user/login',
  dashboardCheckSimDaily: '/dashboard/checksim',
  dashboardCheckThoSim: '/dashboard/checktho',
  dashboardDigishop: '/dashboard/digishop',
  dashboardDigishopFormatSim: '/dashboard/digishop/formatsim',
  dashboardNotifi: '/dashboard/notifisim',
  dashboardAdmin: '/dashboard/admin',
  dashboardFlashSale: '/dashboard/flashsale',
  dashboardNews: '/dashboard/news',
  dashboardExcel: '/dashboard/excel',
  dashboardCN: '/congno',
  dashboardPT: '/photo',
  DashboardGiaoDien: '/dashboard/giaodien',
};
const routes_daily = {
  home: '/khoso',
  homeDetail: '/s/:sim',
  homeCate: '/sim-tat-ca/:catePs',
  homeMenh: '/sim-menh/:menhPs',
  homeFormatSim: '/sim-dang/:dangsimPs',
  homeNewWork: '/sim-mang/:mangPs',
  homeRange: '/sim-theo-gia/:rangePs',
  homeTranhSo: '/ts/:tranhso/:istranhso',
  homeTimKiem: '/tim-sim/:search',
  homeStartEnd: '/tim-tong-:tongdau-:tongcuoi',
  order: '/order',
  info: '/info',
  hopmenh: '/ngu-hanh-tuong-sinh',
  login: '/login',
  success: '/camon',
  ctv: '/ctv',
  daily: '/',
};
const routes_vinaphone = {
  order: '/order',
  info: '/info',
  login: '/login',
  success: '/camon',
  digi: '/sim',
  simdigi: '/',
  checkpackage: '/kiem-tra-goi-cuoc-vinaphone',
  about: '/cach-giao-dich',
};

const authRouter =
  auth[hostname]?.AUTHROUTER === 1 ? routes_vinaphone : auth[hostname]?.AUTHROUTER === 2 ? routes_daily : routes;
export default authRouter;
