export const arrayFormatCate = [
  {
    title: 'AAAAAA',
    link: 'aaaaaa',
  },
  {
    title: 'AAAAA',
    link: 'aaaaa',
  },
  {
    title: 'AAAA',
    link: 'aaaa',
  },
  {
    title: 'AAA.BBB',
    link: 'aaa.bbb',
  },
  {
    title: 'x.AAA',
    link: 'abbb',
  },
  {
    title: 'xBB.AAA',
    link: 'abbccc',
  },
  {
    title: 'BBx.AAA',
    link: 'aabccc',
  },
  {
    title: 'BxB.AAA',
    link: 'abaccc',
  },
  {
    title: 'ABC.AAA',
    link: 'abcaaa',
  },
  {
    title: 'ABC.ABD',
    link: 'abcabd',
  },
  {
    title: 'x.AAA.BBB.x',
    link: 'abbbcccd',
  },
  {
    title: 'AAA.BBB.xx',
    link: 'aaabbbcd',
  },
  {
    title: 'AB.AB.AB',
    link: 'ababab',
  },
  {
    title: 'ABCD.ABCD',
    link: 'abcdabcd',
  },
  {
    title: 'BAA.BAA',
    link: 'baabaa',
  },
  {
    title: 'AAB.AAB',
    link: 'aabaab',
  },
  {
    title: 'ABA.ABA',
    link: 'abaaba',
  },
  {
    title: 'ABC.ABC',
    link: 'abcabc',
  },
  {
    title: 'ABC.CBA',
    link: 'abccba',
  },
  {
    title: 'ABBA.CBBC',
    link: 'abbacbbc',
  },
  {
    title: 'ABBA',
    link: 'abba',
  },
  {
    title: 'AABB',
    link: 'aabb',
  },
  {
    title: 'AA.BB.CC',
    link: 'aabbcc',
  },
  {
    title: 'AA.BB.AA',
    link: 'aabbaa',
  },
  {
    title: 'AA.BC.AA',
    link: 'aabcaa',
  },
  {
    title: 'AB.CC.AB',
    link: 'abccab',
  },
  {
    title: 'AB.CD.AB',
    link: 'abcdab',
  },
  {
    title: 'AAB.AAC',
    link: 'aabaac',
  },
  {
    title: 'ABB.CBB',
    link: 'abbcbb',
  },
  {
    title: 'ABA.ACA',
    link: 'abaaca',
  },
  {
    title: 'AAB.CAB',
    link: 'aabcab',
  },
  {
    title: 'ABB.ACB',
    link: 'abbacb',
  },
  {
    title: 'ABCDDE',
    link: 'abcdde',
  },
  {
    title: 'AB.AB.CAB',
    link: 'ababcab',
  },
  {
    title: 'AB.AC.AD',
    link: 'abacad',
  },
  {
    title: 'AB.CB.DB',
    link: 'abcbdb',
  },
  {
    title: 'AAAA.BCD',
    link: 'aaaabcd',
  },
  {
    title: 'AAAA.BCA',
    link: 'aaaabca',
  },
  {
    title: 'AAAA.BAC',
    link: 'aaaabac',
  },
  {
    title: 'AAAA.BBC',
    link: 'aaaabbc',
  },
  {
    title: 'AAAAB',
    link: 'aaaab',
  },
  {
    title: 'AAA.BCD',
    link: 'aaabcd',
  },
  {
    title: 'AAA.BCC',
    link: 'aaabcc',
  },
  {
    title: 'AAA.BAC',
    link: 'aaabac',
  },
  {
    title: 'AAA.BCA',
    link: 'aaabca',
  },
  {
    title: 'AAA.BBC',
    link: 'aaabbc',
  },
  {
    title: 'dACB.eACB',
    link: 'abcdebcd',
  },
  {
    title: 'ABAC',
    link: 'abac',
  },
  {
    title: 'ABAB',
    link: 'abab',
  },
  {
    title: 'AB.BA.BA',
    link: 'abbaba',
  },
  {
    title: 'AAB.ACB',
    link: 'aabacb',
  },
  {
    title: 'ABCB',
    link: 'abcb',
  },
  {
    title: 'ABB.CDD',
    link: 'abbcdd',
  },
  {
    title: 'AAB.CCD',
    link: 'aabccd',
  },
  {
    title: 'AAB.CDD',
    link: 'aabcdd',
  },
  {
    title: 'ABB.CCD',
    link: 'abbccd',
  },
  {
    title: 'ABA.CCD',
    link: 'abaccd',
  },
  {
    title: 'ABA.CDD',
    link: 'abacdd',
  },
  {
    title: 'AAB.CDD',
    link: 'aabcdd',
  },
  {
    title: 'AB.CB.DB',
    link: 'abcbdb',
  },
  {
    title: 'AA.BB.CC.DD',
    link: 'aabbccdd',
  },
  {
    title: 'AA.BB.AA.CC',
    link: 'aabbaacc',
  },
  {
    title: 'AA.BB.BB.AA',
    link: 'aabbbbaa',
  },
  {
    title: 'AA.BB.BB.CC',
    link: 'aabbbbcc',
  },
  {
    title: 'ABC.DBC',
    link: 'acbdcb',
  },
  {
    title: 'ABC.ADC',
    link: 'abcadc',
  },
  {
    title: 'AABB.CDE',
    link: 'aabbcde',
  },
  {
    title: 'AABB.CAD',
    link: 'aabbcad',
  },
  {
    title: 'AABB.CBD',
    link: 'aabbcbd',
  },
  {
    title: 'AABB.CD',
    link: 'aabbcd',
  },
  {
    title: 'AABB.CA',
    link: 'aabbca',
  },
  {
    title: 'AABB.CB',
    link: 'aabbcb',
  },
  {
    title: 'AB.AB.AC',
    link: 'ababac',
  },
  {
    title: 'AB.AB.CD',
    link: 'ababcd',
  },
  {
    title: 'AB.AB.BC',
    link: 'ababbc',
  },
  {
    title: 'AB.AB.AA',
    link: 'ababaa',
  },
  {
    title: 'AB.AB.BB',
    link: 'ababbb',
  },
  {
    title: 'AB.AB.BA',
    link: 'ababba',
  },
  {
    title: 'ABAB.CDE',
    link: 'ababcde',
  },
  {
    title: 'ABAB.ACD',
    link: 'ababacd',
  },
  {
    title: 'ABAB.AAC',
    link: 'ababaac',
  },
  {
    title: 'AB.AB.ABC',
    link: 'abababc',
  },
  {
    title: 'ABAB.BDC',
    link: 'ababbdc',
  },
  {
    title: 'ABAB.BBC',
    link: 'ababbbc',
  },
  {
    title: 'ABAB.C',
    link: 'ababc',
  },
  {
    title: 'ABAB.A',
    link: 'ababa',
  },
  {
    title: 'AB.ABB',
    link: 'ababb',
  },
  {
    title: 'ABC.DEE',
    link: 'abcdee',
  },
  {
    title: 'ABC.DDA',
    link: 'abcdda',
  },
  {
    title: 'ABC.DDB',
    link: 'abcddb',
  },
  {
    title: 'ABC.DDC',
    link: 'abcddc',
  },
  {
    title: 'ABB.ACD',
    link: 'abbacd',
  },
  {
    title: 'ABB.BCD',
    link: 'abbbcd',
  },
  {
    title: 'AAB.CDE',
    link: 'aabcde',
  },
  {
    title: 'ABC.DBC',
    link: 'abcdbc',
  },
  {
    title: 'ABC.ACB',
    link: 'abcacb',
  },
  {
    title: 'ABC.BAC',
    link: 'abcbac',
  },
  {
    title: 'ABC.BCA',
    link: 'abcbca',
  },
  {
    title: 'AAAAAB',
    link: 'aaaaab',
  },
  {
    title: 'AAAAA.BC',
    link: 'aaaaabc',
  },
  {
    title: 'AAAA.BC',
    link: 'aaaabc',
  },
  {
    title: 'AAAA.BA',
    link: 'aaaaba',
  },
  {
    title: 'AAAAB',
    link: 'aaaab',
  },
  {
    title: 'AAAA.BB',
    link: 'aaaabb',
  },
  {
    title: 'AAAAAAB',
    link: 'aaaaaab',
  },
  {
    title: 'AAAAAA.BC',
    link: 'aaaaaabc',
  },
  {
    title: 'AAAAA.BCD',
    link: 'aaaaabcd',
  },
  {
    title: 'AB.AC.AC',
    link: 'abacac',
  },
  {
    title: 'A.BC.BC.DBC',
    link: 'abcbcdbc',
  },
];
