import axios from 'axios';
import { API_URL_VINAPHONE } from '../config/config';

const request = axios.create({
  baseURL: API_URL_VINAPHONE,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const get = async (path, options = {}) => {
  const response = await request.get(path, options);
  return response.data;
};

export const post = async (path, options = {}) => {
  const response = await request.post(path, options);
  return response.data;
};

export default request;
