import * as request from '../utils/requestWinServer';
//cập nhật sim digi
export const addSimDigi = async (sim) => {
  try {
    const res = await request.post(`/addSimDigi`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updatesxSimDigi = async (sim) => {
  try {
    const res = await request.post(`/updateSxSimdigi`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// gết danh sach
export const getFormatSim = async (sim) => {
  try {
    const res = await request.get(`/getFormatSim`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getSimDigi = async (page) => {
  try {
    const res = await request.get(`/dssimDigi?page=${page}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getSimDigiAll = async (page) => {
  try {
    const res = await request.get(`/dssimDigiAll?page=${page}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getSimDigiALLSize = async (size) => {
  try {
    const res = await request.get(`/dssimDigiAll?size=${size}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getFormatSimID = async (id) => {
  try {
    const res = await request.get(`/getFormatSimID?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addFormatSimID = async (data) => {
  try {
    const res = await request.post(`/addFormatSim`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateFormatSimID = async (data) => {
  try {
    const res = await request.post(`/updateFormatSimID`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const deleFormatSimID = async (data) => {
  try {
    const res = await request.post(`/deleFormatSimID`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
