import * as request from '../utils/requestWinServer';

export const listSimVinaDefault = async (page_number, page_record) => {
  try {
    const res = await request.get(`/simvina_default?page_number=${page_number}&page_record=${page_record}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const searchSim = async (timsim, dauso = '') => {
  try {
    const dausim = dauso !== '' ? `&dauso=${dauso}` : '';
    const res = await request.get(`/numsearch?timsim=${timsim}${dausim}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const checkPackageSim = async (tbsim) => {
  try {
    const res = await request.get(`/checkPackageTb?tbsim=${tbsim}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const checkPackageDetailSim = async (id) => {
  try {
    const res = await request.get(`/checkPackageDetailTb?idCode=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const sendMailSim = async (mail) => {
  try {
    const res = await request.post(`/send-mail`, mail);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const sendMailShop = async (mail) => {
  try {
    const res = await request.post(`/send-mail-shop`, mail);
    return res;
  } catch (error) {
    console.log(error);
  }
};
