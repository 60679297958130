const auth = {
  localhost: {
    AUTHROUTER: 0,
  },
  'vinaphone.khoso.com.vn': {
    AUTHROUTER: 1,
  },
  'xa.khoso.com.vn': {
    AUTHROUTER: 1,
  },
  'daily.khoso.com.vn': {
    AUTHROUTER: 2,
  },
};
export default auth;
