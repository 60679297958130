import { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import classNames from 'classnames/bind';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import useGoogleSheets from 'use-google-sheets';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

import styles from './Order.module.scss';
import { FrameInfoSim } from '../../component/CustomerMUI';
import { Grid } from '@mui/material';
import auth from '../../config/auth';

const cx = classNames.bind(styles);
function Order() {
  const hostname = window.location.hostname;
  const [orderSimResult, setOrderSimResult] = useState([]);
  const [orderSearchSimResult, setOrderSearchSimResult] = useState([]);
  const { data, loading, error } = useGoogleSheets({
    apiKey: auth[hostname]['apiKey'],
    sheetId: auth[hostname]['sheetId'],
    sheetsOptions: [{ id: 'muasim' }],
  });

  useEffect(() => {
    document.title = 'Kiểm tra đơn hàng sim';
    if (data.length > 0) {
      setOrderSimResult(data[0]['data']);
    }
  }, [loading]);

  const handleEnterKey = (e) => {
    const searchValue = e.target.value;
    if (!searchValue.startsWith(' ')) {
      if (e.key === 'Enter') {
        const filterOrder = orderSimResult.filter((data) => {
          return data.sdt === searchValue;
        });
        setOrderSearchSimResult(filterOrder);
      }
    }
  };

  const numberFormat = (number) => {
    let calcNumber;
    if (isNaN(number)) {
      calcNumber = number;
    } else {
      calcNumber = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);
    }
    return calcNumber;
  };

  const handleDetailSim = (hoten, sdt, diachi, gia, tinhtrang) => {
    return [
      `Người nhận: ${hoten}`,
      `Điện thoại: ${sdt}`,
      `Địa chỉ: ${diachi}`,
      `Thu hộ: ${numberFormat(gia)}`,
      `Tình trạng giao hàng: ${tinhtrang}`,
    ];
  };

  return (
    <div className={cx('wrapper')}>
      <FormControl fullWidth sx={{ m: 1 }}>
        <InputLabel htmlFor="outlined-adornment-amount" color="error">
          Tìm kiếm
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          color="error"
          placeholder="Nhập số điện thoại người nhận"
          startAdornment={
            <InputAdornment position="start">
              <ContentPasteSearchIcon />
            </InputAdornment>
          }
          onKeyPress={handleEnterKey}
          label="Tìm Kiếm"
        />
      </FormControl>

      <label className={cx('text-title')}>
        <b>Kết quả tìm kiếm:</b>
      </label>
      <p className={cx('text-title-loading')}></p>
      <Grid container spacing={0.5} sx={{ ml: 0.5 }}>
        {orderSearchSimResult &&
          orderSearchSimResult.map((sim, index) => (
            <Grid item key={index} md={4} xs={12}>
              <FrameInfoSim
                title={`SIM ĐÃ ĐẶT: ${sim.sdtmua}`}
                fontSize="medium"
                arrDetailSim={handleDetailSim(sim.hoten, sim.sdt, sim.diachi, sim.gia, sim.tinhtrang)}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default Order;
