import { Button, Container, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { numberFormatDeleteDots } from '../../../utils/helper';
import { logicFormatSim } from './logicFormatSim';

async function readExcelFile(file) {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(file);

  const worksheet = workbook.worksheets[0];
  const rows = [];

  worksheet.eachRow({ includeEmpty: true }, (row, rowIndex) => {
    row.eachCell({ includeEmpty: true }, (cell, cellIndex) => {
      const phone = `0${numberFormatDeleteDots(cell.value)}`;
      const checkFormatSim = logicFormatSim(phone);
      const textSim = `${phone} - ${checkFormatSim}`;
      rows.push(textSim);
    });
  });

  return rows;
}

function Excel() {
  const [dataExcel, setDataExcel] = useState([]);
  function handleFileChange(e) {
    const file = e.target.files[0];

    readExcelFile(file)
      .then((data) => {
        setDataExcel(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const exportToExcel = (data) => {
    // Tạo một danh sách các đối tượng con tương ứng với từng dòng trong mảng data
    const worksheetData = data.map((item) => {
      const [phone, names] = item.split(' - ');
      const [dinhdang6, dinhdang4, dinhdang3, dinhdang8] = names.split(',');

      return { phone, dinhdang6, dinhdang4, dinhdang3, dinhdang8 };
    });

    // Tạo workbook và worksheet mới từ dữ liệu được chuyển đổi, sử dụng thư viện xlsx
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);

    // Thêm worksheet vào workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Chuyển đổi workbook thành dạng binary tương ứng với file Excel
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Tạo blob từ dữ liệu excel để tải xuống
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Sử dụng hàm saveAs từ thư viện file-saver để tải xuống tệp
    saveAs(blob, 'data.xlsx');
  };

  // Gọi hàm exportToExcel khi bạn muốn tải xuống
  const handleExport = () => {
    exportToExcel(dataExcel);
  };
  return (
    <Container sx={{ pb: '65px' }}>
      <Grid container>
        <Grid item md={3} xs={12} sx={{ m: 1 }}>
          <Button onClick={handleExport} variant="contained" sx={{ mb: 2 }}>
            Tải xuống Excel
          </Button>
          <TextField label="File excel" size="small" type="file" onChange={handleFileChange} focused />
        </Grid>
        <Grid item md={12} xs={12} sx={{ m: 1 }}>
          {dataExcel.map((row, i) => (
            <p key={i}>{row}</p>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Excel;
