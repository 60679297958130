import * as reqAuth from '../utils/requestAuthServer';
// gết danh sach
export const getCongno = async (domain) => {
  try {
    const res = await reqAuth.getSim(`/getCongno?u=${domain}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCongnoDate = async (data) => {
  try {
    const res = await reqAuth.postSim(`/getCongnoDate`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCongnoID = async (id) => {
  try {
    const res = await reqAuth.getSim(`/getCongnoID?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addCongnoAll = async (data) => {
  try {
    const res = await reqAuth.postSim(`/addCongnoAll`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateCongnoID = async (data) => {
  try {
    const res = await reqAuth.postSim(`/updateCongnoID`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const deleCongno = async (data) => {
  try {
    const res = await reqAuth.postSim(`/deleCongno`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
