import PropTypes from 'prop-types';
import SimCardIcon from '@mui/icons-material/SimCard';
import DoneIcon from '@mui/icons-material/Done';

import classNames from 'classnames/bind';
import styles from './FrameInfoSim.module.scss';
const cx = classNames.bind(styles);

function FrameInfoSim({ bgrFrame = 'df', title = '', fontSize = 'small', arrDetailSim = [], onClick }) {
  let CompIcon = DoneIcon;
  const props = { onClick };
  return (
    <div className={cx(`header-title-${bgrFrame}`)} {...props}>
      <div className={cx(`header-title-item-${bgrFrame}`)}>
        <SimCardIcon sx={{ pr: '5px' }} fontSize="small" /> <b> {title}</b>
      </div>
      <div className={cx('detail-item-sim', `font-size-${fontSize}`)}>
        {arrDetailSim &&
          arrDetailSim.map((sim, index) => (
            <div key={index} className={cx('title-item')}>
              {sim && <CompIcon fontSize="small" className={cx(`svg_icons_ticker_${bgrFrame}`)} />}
              <span dangerouslySetInnerHTML={{ __html: sim }} />
            </div>
          ))}
      </div>
    </div>
  );
}

FrameInfoSim.propTypes = {
  bgrFrame: PropTypes.string,
  title: PropTypes.string,
  fontSize: PropTypes.string,
  arrDetailSim: PropTypes.array,
  onClick: PropTypes.func,
};

export default FrameInfoSim;
