export const DataSim = {
  0: {
    id: 0,
    mucdo: 'Đại Cát',
    ynghia: 'Sinh ra đã tốt ắt được thành công',
  },
  1: {
    id: 1,
    mucdo: 'Cát',
    ynghia: 'Sự nghiệp phát triển, thăng tiến',
  },
  2: {
    id: 2,
    mucdo: 'Bình',
    ynghia: 'Cuộc sống thăng trầm, hậu vận về sau',
  },
  3: {
    id: 3,
    mucdo: 'Đại Cát',
    ynghia: 'Vạn sự thuận lợi',
  },
  4: {
    id: 4,
    mucdo: 'Hung',
    ynghia: 'Đau khổ khôn cùng, tiền đồ gian nan',
  },
  5: {
    id: 5,
    mucdo: 'Đại Cát',
    ynghia: 'Làm ăn tấn tới, tiền đồ rộng mở',
  },
  6: {
    id: 6,
    mucdo: 'Cát',
    ynghia: 'Có được lộc trời, thành công tới',
  },
  7: {
    id: 7,
    mucdo: 'Cát',
    ynghia: 'Mọi sự bình an',
  },
  8: {
    id: 8,
    mucdo: 'Cát',
    ynghia: 'Qua được gian nan sẽ có thành công',
  },
  9: {
    id: 9,
    mucdo: 'Hung',
    ynghia: 'Mọi sự khó lường, thường là hung',
  },
  10: {
    id: 10,
    mucdo: 'Hung',
    ynghia: 'Không có bến bờ',
  },
  11: {
    id: 11,
    mucdo: 'Cát',
    ynghia: 'Được người trọng vọng',
  },
  12: {
    id: 12,
    mucdo: 'Hung',
    ynghia: 'Công việc khó, sức khỏe nan',
  },
  13: {
    id: 13,
    mucdo: 'Cát',
    ynghia: 'Được người kính trọng',
  },
  14: {
    id: 14,
    mucdo: 'Bình',
    ynghia: 'Mọi sự đều dựa vào nghị lực',
  },
  15: {
    id: 15,
    mucdo: 'Cát',
    ynghia: 'Nhất định được hưng vượng',
  },
  16: {
    id: 16,
    mucdo: 'Đại Cát',
    ynghia: 'Vừa được danh, vừa được lợi',
  },
  17: {
    id: 17,
    mucdo: 'Cát',
    ynghia: 'Quý nhân trợ giúp',
  },
  18: {
    id: 18,
    mucdo: 'Đại Cát',
    ynghia: 'Mọi việc trôi chảy',
  },
  19: {
    id: 19,
    mucdo: 'Hung',
    ynghia: 'Khó khăn muôn phần',
  },
  20: {
    id: 20,
    mucdo: 'Đại Hung',
    ynghia: 'Gian nan luôn có',
  },
  21: {
    id: 21,
    mucdo: 'Cát',
    ynghia: 'Chuyên tâm ắt sẽ thành',
  },
  22: {
    id: 22,
    mucdo: 'Hung',
    ynghia: 'Công việc không được gặp may',
  },
  23: {
    id: 23,
    mucdo: 'Đại Cát',
    ynghia: 'Ắt thành đại nghiệp',
  },
  24: {
    id: 24,
    mucdo: 'Cát',
    ynghia: 'Tự lập ắt được thành công',
  },
  25: {
    id: 25,
    mucdo: 'Cát',
    ynghia: 'Thiên thời địa lợi nhân hòa',
  },
  26: {
    id: 26,
    mucdo: 'Hung',
    ynghia: 'Phong ba bão táp đến',
  },
  27: {
    id: 27,
    mucdo: 'Cát',
    ynghia: 'Giữ được thành công',
  },
  28: {
    id: 28,
    mucdo: 'Đại Cát',
    ynghia: 'Tiến nhanh như gió',
  },
  29: {
    id: 29,
    mucdo: 'Hung',
    ynghia: 'Cát mất hung chia',
  },
  30: {
    id: 30,
    mucdo: 'Đại Cát',
    ynghia: 'Đại sự thành công',
  },
  31: {
    id: 31,
    mucdo: 'Đại Cát',
    ynghia: 'Thành công như rồng bay',
  },
  32: {
    id: 32,
    mucdo: 'Cát',
    ynghia: 'Thịnh vượng đến nhờ trí dũng',
  },
  33: {
    id: 33,
    mucdo: 'Hung',
    ynghia: 'Rủi ro liên tục thành công khó',
  },
  34: {
    id: 34,
    mucdo: 'Bình',
    ynghia: 'Số phận trung bình',
  },
  35: {
    id: 35,
    mucdo: 'Hung',
    ynghia: 'Thường gặp nạn tai',
  },
  36: {
    id: 36,
    mucdo: 'Cát',
    ynghia: 'Thuận buồm xuôi gió',
  },
  37: {
    id: 37,
    mucdo: 'Bình',
    ynghia: 'Được danh mà không có lợi',
  },
  38: {
    id: 38,
    mucdo: 'Đại Cát',
    ynghia: 'Công danh rộng mở nhìn thấy tương lai',
  },
  39: {
    id: 39,
    mucdo: 'Bình',
    ynghia: 'Lúc chìm lúc nổi vô định',
  },
  40: {
    id: 40,
    mucdo: 'Đại Cát',
    ynghia: 'Tiền đồ sáng sủa thuận thiên ý',
  },
  41: {
    id: 41,
    mucdo: 'Hung',
    ynghia: 'Sự nghiệp không thành',
  },
  42: {
    id: 42,
    mucdo: 'Cát',
    ynghia: 'Nhẫn nhịn chịu đựng ắt được cát lợi',
  },
  43: {
    id: 43,
    mucdo: 'Cát',
    ynghia: 'Thành công tự đến thuận thời thiên',
  },
  44: {
    id: 44,
    mucdo: 'Hung',
    ynghia: 'Tham công lỡ việc',
  },
  45: {
    id: 45,
    mucdo: 'Hung',
    ynghia: 'Khó khăn kéo dài',
  },
  46: {
    id: 46,
    mucdo: 'Đại Cát',
    ynghia: 'Quý nhân phù trợ thành công đến',
  },
  47: {
    id: 47,
    mucdo: 'Đại Cát',
    ynghia: 'Danh lợi thành công đều có',
  },
  48: {
    id: 48,
    mucdo: 'Bình',
    ynghia: 'Gặp cát được cát gặp hung thì hung',
  },
  49: {
    id: 49,
    mucdo: 'Bình',
    ynghia: 'Hung cát bất phân',
  },
  50: {
    id: 50,
    mucdo: 'Bình',
    ynghia: 'Thành công dẫn lối',
  },
  51: {
    id: 51,
    mucdo: 'Cát',
    ynghia: 'Sự nghiệp phát triển, thăng tiến',
  },
  52: {
    id: 52,
    mucdo: 'Hung',
    ynghia: 'Sướng trước khổ sau',
  },
  53: {
    id: 53,
    mucdo: 'Bình',
    ynghia: 'Cố gắng nhiều nhưng không được bao nhiêu',
  },
  54: {
    id: 54,
    mucdo: 'Hung',
    ynghia: 'Hiểm họa ẩn trong mọi sự',
  },
  55: {
    id: 55,
    mucdo: 'Đại Hung',
    ynghia: 'Mọi chuyện ngược ý, thành công xa',
  },
  56: {
    id: 56,
    mucdo: 'Cát',
    ynghia: 'Nỗ lực phấn đấu sẽ được thành công',
  },
  57: {
    id: 57,
    mucdo: 'Bình',
    ynghia: 'Khổ trước sướng sau',
  },
  58: {
    id: 58,
    mucdo: 'Hung',
    ynghia: 'Mọi việc do dự mất cơ hội',
  },
  59: {
    id: 59,
    mucdo: 'Bình',
    ynghia: 'Không xác định được phương hướng',
  },
  60: {
    id: 60,
    mucdo: 'Hung',
    ynghia: 'Phong ba bão táp',
  },
  61: {
    id: 61,
    mucdo: 'Hung',
    ynghia: 'Lo nghĩ cũng nhiều nhưng không thành',
  },
  62: {
    id: 62,
    mucdo: 'Cát',
    ynghia: 'Cố gắng nỗ lực sẽ được thành công',
  },
  63: {
    id: 63,
    mucdo: 'Hung',
    ynghia: 'Công việc khó khăn, tài lộc mất',
  },
  64: {
    id: 64,
    mucdo: 'Cát',
    ynghia: 'Thành công tự đến, giữ được sẽ an',
  },
  65: {
    id: 65,
    mucdo: 'Bình',
    ynghia: 'Gia đạo bất hòa, không được tin tưởng',
  },
  66: {
    id: 66,
    mucdo: 'Đại Cát',
    ynghia: 'Vạn sự như ý - Tỷ sự như mơ',
  },
  67: {
    id: 67,
    mucdo: 'Cát',
    ynghia: 'Nắm bắt tốt thời cơ ắt có thành công',
  },
  68: {
    id: 68,
    mucdo: 'Hung',
    ynghia: 'Có lo nghĩ cũng chẳng được an',
  },
  69: {
    id: 69,
    mucdo: 'Hung',
    ynghia: 'Công việc bấp bênh, khó khăn theo đuổi',
  },
  70: {
    id: 70,
    mucdo: 'Bình',
    ynghia: 'Hung có cát có, mọi sự bình thường',
  },
  71: {
    id: 71,
    mucdo: 'Bình',
    ynghia: 'Có được cũng mất',
  },
  72: {
    id: 72,
    mucdo: 'Cát',
    ynghia: 'Mọi việc ắt cát tường',
  },
  73: {
    id: 73,
    mucdo: 'Bình',
    ynghia: 'Bình thường mới tốt',
  },
  74: {
    id: 74,
    mucdo: 'Bình',
    ynghia: 'Tiến không bằng lùi, trong tốt ắt có xấu',
  },
  75: {
    id: 75,
    mucdo: 'Hung',
    ynghia: 'Sự gặp hung, cẩn thận hạn chế',
  },
  76: {
    id: 76,
    mucdo: 'Cát',
    ynghia: 'Khổ qua, thuận lợi ắt tới',
  },
  77: {
    id: 77,
    mucdo: 'Bình',
    ynghia: 'Được mất đã được định sẵn',
  },
  78: {
    id: 78,
    mucdo: 'Đại Cát',
    ynghia: 'Tiền đồ rộng mở, cố gắng sẽ thành',
  },
  79: {
    id: 79,
    mucdo: 'Hung',
    ynghia: 'Có rồi lại mất, lo nhiều cũng thế',
  },
  80: {
    id: 80,
    mucdo: 'Đại Cát',
    ynghia: 'Số phận cao nhất, sẽ được thành công',
  },
};
