import { Button, Container, Grid, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import * as apiUsers from '../../services/apiUsers';
import { checkTokenDashboad } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
function Users() {
  const navigate = useNavigate();
  const today = new Date();
  const date = today.getDate();
  const month = today.getMonth() + 1; // Lưu ý: Tháng bắt đầu từ 0 nên cần cộng thêm 1
  const year = today.getFullYear();
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const seconds = today.getSeconds();
  const currentDate = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds;
  const [value, setValue] = useState({
    name: '',
    username: '',
    pass_word: '',
    permission: '1',
    email: '',
    link_gg: '',
    range: '',
    link_domain: '',
    status: 1,
    layoutSim: '2',
    authGuard: '0',
    logo: 'logo',
    BASE_URL: '',
    TITLE_HOME: '',
    TITLE_HEADER: '',
    HOTTLINE_SIM: '',
    HOTTLINE_SIM_CALL: '',
    HOTTLINE_ZALO: '',
    GMAIL_NOTIFI: '',
    AUTHROUTER: '0',
    ADDRESS: '',
    LINKNOTIFI: '',
    NOTIFI: '',
    create_date: currentDate,
    update_date: currentDate,
  });

  const fetchApiUsers = async () => {
    const resultAPISIM = await apiUsers.addUsers(value);
    if (resultAPISIM === 'OK') {
      setValue({
        name: '',
        username: '',
        pass_word: '',
        permission: '1',
        email: '',
        link_gg: '',
        range: '',
        link_domain: '',
        status: 1,
        layoutSim: '2',
        authGuard: '0',
        logo: 'logo',
        BASE_URL: '',
        TITLE_HOME: '',
        TITLE_HEADER: '',
        HOTTLINE_SIM: '',
        HOTTLINE_SIM_CALL: '',
        HOTTLINE_ZALO: '',
        GMAIL_NOTIFI: '',
        AUTHROUTER: '0',
        ADDRESS: '',
        LINKNOTIFI: '',
        NOTIFI: '',
        create_date: currentDate,
        update_date: currentDate,
      });
    }
  };

  const handleSaveUsers = () => {
    fetchApiUsers();
  };

  const handleChangValueUser = (jsonVL) => {
    setValue({ ...value, ...jsonVL });
  };
  return (
    <Container sx={{ p: 0, pb: '65px' }}>
      <Grid container sx={{ m: 1 }}>
        <Grid item xs={4} sx={{ m: 1 }}>
          <Stack direction={'column'} spacing={2}>
            <TextField
              label="Tên Miền"
              size="small"
              value={value.link_domain}
              onChange={(e) => {
                handleChangValueUser({ link_domain: e.target.value });
              }}
            />
            <TextField
              label="Họ Tên"
              size="small"
              value={value.name}
              onChange={(e) => {
                handleChangValueUser({ name: e.target.value });
              }}
            />
            <TextField
              label="Tài Khoản"
              size="small"
              value={value.username}
              onChange={(e) => {
                handleChangValueUser({ username: e.target.value });
              }}
            />
            <TextField
              label="Mật Khẩu"
              size="small"
              value={value.pass_word}
              onChange={(e) => {
                handleChangValueUser({ pass_word: e.target.value });
              }}
            />
            <TextField
              label="Quyền Hạn"
              size="small"
              value={value.permission}
              onChange={(e) => {
                handleChangValueUser({ permission: e.target.value });
              }}
            />
            <TextField
              label="Email"
              size="small"
              value={value.email}
              onChange={(e) => {
                handleChangValueUser({ email: e.target.value, GMAIL_NOTIFI: e.target.value });
              }}
            />
            <TextField
              label="Link Trang Tính"
              size="small"
              value={value.link_gg}
              onChange={(e) => {
                handleChangValueUser({ link_gg: e.target.value });
              }}
            />
            <TextField
              label="Sheets"
              size="small"
              value={value.range}
              onChange={(e) => {
                handleChangValueUser({ range: e.target.value });
              }}
            />
            <TextField
              label="Trạng Thái"
              size="small"
              value={value.status}
              onChange={(e) => {
                handleChangValueUser({ status: e.target.value });
              }}
            />
            <TextField
              label="Layout  Sim"
              size="small"
              value={value.layoutSim}
              onChange={(e) => {
                handleChangValueUser({ layoutSim: e.target.value });
              }}
            />
            <TextField
              label="authGuard"
              size="small"
              value={value.authGuard}
              onChange={(e) => {
                handleChangValueUser({ authGuard: e.target.value });
              }}
            />
            <TextField
              label="logo"
              size="small"
              value={value.logo}
              onChange={(e) => {
                handleChangValueUser({ logo: e.target.value });
              }}
            />
            <TextField
              label="create_date"
              size="small"
              value={value.create_date}
              onChange={(e) => {
                handleChangValueUser({ create_date: e.target.value });
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ m: 1 }}>
          <Stack direction={'column'} spacing={2}>
            <TextField
              label="BASE_URL"
              size="small"
              value={value.BASE_URL}
              onChange={(e) => {
                handleChangValueUser({ BASE_URL: e.target.value });
              }}
            />
            <TextField
              label="TITLE_HOME"
              size="small"
              value={value.TITLE_HOME}
              onChange={(e) => {
                handleChangValueUser({ TITLE_HOME: e.target.value });
              }}
            />
            <TextField
              label="TITLE_HEADER"
              size="small"
              value={value.TITLE_HEADER}
              onChange={(e) => {
                handleChangValueUser({ TITLE_HEADER: e.target.value });
              }}
            />
            <TextField
              label="HOTTLINE_SIM"
              size="small"
              value={value.HOTTLINE_SIM}
              onChange={(e) => {
                handleChangValueUser({ HOTTLINE_SIM: e.target.value });
              }}
            />
            <TextField
              label="HOTTLINE_SIM_CALL"
              size="small"
              value={value.HOTTLINE_SIM_CALL}
              onChange={(e) => {
                handleChangValueUser({ HOTTLINE_SIM_CALL: e.target.value });
              }}
            />
            <TextField
              label="HOTTLINE_ZALO"
              size="small"
              value={value.HOTTLINE_ZALO}
              onChange={(e) => {
                handleChangValueUser({ HOTTLINE_ZALO: e.target.value });
              }}
            />
            <TextField
              label="GMAIL_NOTIFI"
              size="small"
              value={value.GMAIL_NOTIFI}
              onChange={(e) => {
                handleChangValueUser({ GMAIL_NOTIFI: e.target.value });
              }}
            />
            <TextField
              label="AUTHROUTER"
              size="small"
              value={value.AUTHROUTER}
              onChange={(e) => {
                handleChangValueUser({ AUTHROUTER: e.target.value });
              }}
            />
            <TextField
              label="ADDRESS"
              size="small"
              value={value.ADDRESS}
              onChange={(e) => {
                handleChangValueUser({ ADDRESS: e.target.value });
              }}
            />
            <TextField
              label="LINKNOTIFI"
              size="small"
              value={value.LINKNOTIFI}
              onChange={(e) => {
                handleChangValueUser({ LINKNOTIFI: e.target.value });
              }}
            />
            <TextField
              label="NOTIFI"
              size="small"
              value={value.NOTIFI}
              onChange={(e) => {
                handleChangValueUser({ NOTIFI: e.target.value });
              }}
            />
            <TextField
              label="update_date"
              size="small"
              value={value.update_date}
              onChange={(e) => {
                handleChangValueUser({ update_date: e.target.value });
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" color="error" onClick={handleSaveUsers}>
            SAVE
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Users;
