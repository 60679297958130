export const LogicCategory = (phoneNumber, format, isFormat = false) => {
  if (phoneNumber) {
    console.log(phoneNumber);
    const soduoi2 = phoneNumber.slice(-2);
    const soduoi3 = phoneNumber.slice(-3);
    const soduoi4 = phoneNumber.slice(-4);
    const sogiua3 = phoneNumber.slice(4, 7);
    var arrCate,
      arrCate2,
      arrCate3,
      arrCate4,
      arrCate5,
      arrTaxi,
      arrTaxiTien,
      arrSanh3,
      arrTuQuy,
      arrNamSinh,
      arrDDMMYY;
    let arrayNumber = format.slice(3, 11).split('');
    const soduoi = phoneNumber.slice(-arrayNumber.length);
    const soduoi6 = phoneNumber.slice(-6);
    const sd6 = [
      soduoi.slice(0, 1),
      soduoi.slice(1, 2),
      soduoi.slice(2, 3),
      soduoi.slice(3, 4),
      soduoi.slice(4, 5),
      soduoi.slice(5, 6),
      soduoi.slice(6, 7),
      soduoi.slice(7, 8),
    ];
    const sd = [
      soduoi6.slice(0, 1),
      soduoi6.slice(1, 2),
      soduoi6.slice(2, 3),
      soduoi6.slice(3, 4),
      soduoi6.slice(4, 5),
      soduoi6.slice(5, 6),
    ];
    let indices = {};
    let a = [];
    let b = [];
    let c = [];
    let d = [];
    let e = [];
    let f = [];
    if (isFormat) {
      for (let i = 0; i < arrayNumber.length; i++) {
        if (!indices[arrayNumber[i]]) {
          indices[arrayNumber[i]] = [];
        }
        indices[arrayNumber[i]].push(i);
      }

      arrayNumber.map((row, i) => {
        if (i < indices?.a?.length) {
          a.push(sd6[indices?.a[i]]);
        }
        if (i < indices?.b?.length) {
          b.push(sd6[indices?.b[i]]);
        }
        if (i < indices?.c?.length) {
          c.push(sd6[indices?.c[i]]);
        }
        if (i < indices?.d?.length) {
          d.push(sd6[indices?.d[i]]);
        }
        if (i < indices?.e?.length) {
          e.push(sd6[indices?.e[i]]);
        }
        if (i < indices?.f?.length) {
          f.push(sd6[indices?.f[i]]);
        }
      });
      let atotal = a.filter((x) => x === a[0]).length;
      let btotal = b.filter((x) => x === b[0]).length;
      let ctotal = c.filter((x) => x === c[0]).length;
      let dtotal = d.filter((x) => x === d[0]).length;
      let etotal = e.filter((x) => x === e[0]).length;
      let ftotal = f.filter((x) => x === f[0]).length;

      if (
        atotal === indices?.a.length &&
        btotal === 0 &&
        ctotal === 0 &&
        dtotal === 0 &&
        etotal === 0 &&
        ftotal === 0
      ) {
        arrCate4 = format;
      } else if (
        atotal === indices?.a.length &&
        btotal === indices?.b.length &&
        ctotal === 0 &&
        dtotal === 0 &&
        etotal === 0 &&
        ftotal === 0
      ) {
        if (sd6[indices?.a[0]] !== sd6[indices?.b[0]]) {
          arrCate4 = format;
        }
      } else if (
        atotal === indices?.a.length &&
        btotal === indices?.b.length &&
        ctotal === indices?.c.length &&
        dtotal === 0 &&
        etotal === 0 &&
        ftotal === 0
      ) {
        if (
          sd6[indices?.a[0]] !== sd6[indices?.b[0]] &&
          sd6[indices?.a[0]] !== sd6[indices?.c[0]] &&
          sd6[indices?.b[0]] !== sd6[indices?.c[0]]
        ) {
          arrCate4 = format;
        }
      } else if (
        atotal === indices?.a.length &&
        btotal === indices?.b.length &&
        ctotal === indices?.c.length &&
        dtotal === indices?.d.length &&
        etotal === 0 &&
        ftotal === 0
      ) {
        if (
          sd6[indices?.a[0]] !== sd6[indices?.b[0]] &&
          sd6[indices?.a[0]] !== sd6[indices?.c[0]] &&
          sd6[indices?.a[0]] !== sd6[indices?.d[0]] &&
          sd6[indices?.b[0]] !== sd6[indices?.c[0]] &&
          sd6[indices?.b[0]] !== sd6[indices?.d[0]] &&
          sd6[indices?.c[0]] !== sd6[indices?.d[0]]
        ) {
          arrCate4 = format;
        }
      } else if (
        atotal === indices?.a.length &&
        btotal === indices?.b.length &&
        ctotal === indices?.c.length &&
        dtotal === indices?.d.length &&
        etotal === indices?.e.length &&
        ftotal === 0
      ) {
        if (
          sd6[indices?.a[0]] !== sd6[indices?.b[0]] &&
          sd6[indices?.a[0]] !== sd6[indices?.c[0]] &&
          sd6[indices?.a[0]] !== sd6[indices?.d[0]] &&
          sd6[indices?.a[0]] !== sd6[indices?.e[0]] &&
          sd6[indices?.b[0]] !== sd6[indices?.c[0]] &&
          sd6[indices?.b[0]] !== sd6[indices?.d[0]] &&
          sd6[indices?.b[0]] !== sd6[indices?.e[0]] &&
          sd6[indices?.c[0]] !== sd6[indices?.d[0]] &&
          sd6[indices?.c[0]] !== sd6[indices?.e[0]] &&
          sd6[indices?.d[0]] !== sd6[indices?.e[0]]
        ) {
          arrCate4 = format;
        }
      } else if (
        atotal === indices?.a.length &&
        btotal === indices?.b.length &&
        ctotal === indices?.c.length &&
        dtotal === indices?.d.length &&
        etotal === indices?.e.length &&
        ftotal === indices?.f.length
      ) {
        if (
          sd6[indices?.a[0]] !== sd6[indices?.b[0]] &&
          sd6[indices?.a[0]] !== sd6[indices?.c[0]] &&
          sd6[indices?.a[0]] !== sd6[indices?.d[0]] &&
          sd6[indices?.a[0]] !== sd6[indices?.e[0]] &&
          sd6[indices?.a[0]] !== sd6[indices?.f[0]] &&
          sd6[indices?.b[0]] !== sd6[indices?.c[0]] &&
          sd6[indices?.b[0]] !== sd6[indices?.d[0]] &&
          sd6[indices?.b[0]] !== sd6[indices?.e[0]] &&
          sd6[indices?.b[0]] !== sd6[indices?.f[0]] &&
          sd6[indices?.c[0]] !== sd6[indices?.d[0]] &&
          sd6[indices?.c[0]] !== sd6[indices?.e[0]] &&
          sd6[indices?.c[0]] !== sd6[indices?.f[0]] &&
          sd6[indices?.d[0]] !== sd6[indices?.e[0]] &&
          sd6[indices?.d[0]] !== sd6[indices?.f[0]] &&
          sd6[indices?.e[0]] !== sd6[indices?.f[0]]
        ) {
          arrCate4 = format;
        }
      }
      // else if (
      //   atotal === indices?.a.length &&
      //   btotal === indices?.b.length &&
      //   ctotal === indices?.c.length &&
      //   dtotal === indices?.d.length &&
      //   etotal === indices?.e.length &&
      //   ftotal === indices?.f.length &&
      //   gtotal === indices?.g.length &&
      //   htotal === 0
      // ) {
      //   if (
      //     sd6[indices?.a[0]] !== sd6[indices?.b[0]] &&
      //     sd6[indices?.a[0]] !== sd6[indices?.c[0]] &&
      //     sd6[indices?.a[0]] !== sd6[indices?.d[0]] &&
      //     sd6[indices?.a[0]] !== sd6[indices?.e[0]] &&
      //     sd6[indices?.a[0]] !== sd6[indices?.f[0]] &&
      //     sd6[indices?.a[0]] !== sd6[indices?.g[0]] &&
      //     sd6[indices?.b[0]] !== sd6[indices?.c[0]] &&
      //     sd6[indices?.b[0]] !== sd6[indices?.d[0]] &&
      //     sd6[indices?.b[0]] !== sd6[indices?.e[0]] &&
      //     sd6[indices?.b[0]] !== sd6[indices?.f[0]] &&
      //     sd6[indices?.b[0]] !== sd6[indices?.g[0]] &&
      //     sd6[indices?.c[0]] !== sd6[indices?.d[0]] &&
      //     sd6[indices?.c[0]] !== sd6[indices?.e[0]] &&
      //     sd6[indices?.c[0]] !== sd6[indices?.f[0]] &&
      //     sd6[indices?.c[0]] !== sd6[indices?.g[0]] &&
      //     sd6[indices?.d[0]] !== sd6[indices?.e[0]] &&
      //     sd6[indices?.d[0]] !== sd6[indices?.f[0]] &&
      //     sd6[indices?.d[0]] !== sd6[indices?.g[0]] &&
      //     sd6[indices?.e[0]] !== sd6[indices?.f[0]] &&
      //     sd6[indices?.e[0]] !== sd6[indices?.g[0]] &&
      //     sd6[indices?.f[0]] !== sd6[indices?.g[0]]
      //   ) {
      //     arrCate4 = format;
      //   }
      // }
      // else if (
      //   atotal === indices?.a.length &&
      //   btotal === indices?.b.length &&
      //   ctotal === indices?.c.length &&
      //   dtotal === indices?.d.length &&
      //   etotal === indices?.e.length &&
      //   ftotal === indices?.f.length &&
      //   gtotal === indices?.g.length &&
      //   htotal === indices?.h.length
      // ) {
      //   if (
      //     sd6[indices?.a[0]] !== sd6[indices?.b[0]] &&
      //     sd6[indices?.a[0]] !== sd6[indices?.c[0]] &&
      //     sd6[indices?.a[0]] !== sd6[indices?.d[0]] &&
      //     sd6[indices?.a[0]] !== sd6[indices?.e[0]] &&
      //     sd6[indices?.a[0]] !== sd6[indices?.f[0]] &&
      //     sd6[indices?.a[0]] !== sd6[indices?.g[0]] &&
      //     sd6[indices?.b[0]] !== sd6[indices?.c[0]] &&
      //     sd6[indices?.b[0]] !== sd6[indices?.d[0]] &&
      //     sd6[indices?.b[0]] !== sd6[indices?.e[0]] &&
      //     sd6[indices?.b[0]] !== sd6[indices?.f[0]] &&
      //     sd6[indices?.b[0]] !== sd6[indices?.g[0]] &&
      //     sd6[indices?.c[0]] !== sd6[indices?.d[0]] &&
      //     sd6[indices?.c[0]] !== sd6[indices?.e[0]] &&
      //     sd6[indices?.c[0]] !== sd6[indices?.f[0]] &&
      //     sd6[indices?.c[0]] !== sd6[indices?.g[0]] &&
      //     sd6[indices?.d[0]] !== sd6[indices?.e[0]] &&
      //     sd6[indices?.d[0]] !== sd6[indices?.f[0]] &&
      //     sd6[indices?.d[0]] !== sd6[indices?.g[0]] &&
      //     sd6[indices?.e[0]] !== sd6[indices?.f[0]] &&
      //     sd6[indices?.e[0]] !== sd6[indices?.g[0]] &&
      //     sd6[indices?.f[0]] !== sd6[indices?.g[0]] &&
      //     sd6[indices?.f[0]] !== sd6[indices?.h[0]] &&
      //     sd6[indices?.g[0]] !== sd6[indices?.h[0]]
      //   ) {
      //     arrCate4 = format;
      //   }
      // }
    }
    switch (soduoi2) {
      case '39':
      case '79':
        arrCate = 'than-tai,phong-thuy';
        break;
      case '86':
        arrCate = 'phat-loc,phong-thuy';
        break;
      case '68':
        arrCate = 'loc-phat,phong-thuy';
        break;
      case '78':
      case '38':
        arrCate = 'tho-dia,phong-thuy';
        break;

      default:
        arrCate = '';
        break;
    }
    if (sd[2] !== sd[3] && sd[3] === sd[4] && sd[4] === sd[5]) {
      switch (soduoi3) {
        case '000':
        case '111':
        case '222':
        case '333':
        case '444':
        case '555':
        case '666':
        case '777':
        case '888':
        case '999':
          arrCate2 = 'tam-hoa';
          break;
        default:
          arrCate2 = '';
          break;
      }
    }
    switch (soduoi3) {
      case '012':
      case '123':
      case '234':
      case '345':
      case '456':
      case '678':
      case '789':
        arrSanh3 = 'sanh-tien,sanh-tien-3';
        break;
      default:
        arrSanh3 = '';
        break;
    }

    if (sd[1] !== sd[2] && sd[2] === sd[3] && sd[3] === sd[4] && sd[4] === sd[5]) {
      switch (soduoi4) {
        case '0000':
        case '1111':
        case '2222':
        case '3333':
        case '4444':
        case '5555':
        case '6666':
        case '7777':
        case '8888':
        case '9999':
          arrTuQuy = 'tu-quy';
          break;
        default:
          arrTuQuy = '';
          break;
      }
    }

    switch (soduoi4) {
      case '0123':
      case '1234':
      case '2345':
      case '3456':
      case '4567':
      case '6789':
        arrCate3 = 'sanh-tien,sanh-tien-4';
        break;
      case '4953':
      case '4078':
      case '7749':
        arrCate3 = 'doc-la,dac-biet';
        break;
      default:
        arrCate3 = '';
        break;
    }

    if (sd[0] + sd[1] + sd[2] === sd[3] + sd[4] + sd[5]) {
      arrTaxi = 'taxi';
    } else if (sd[0] + sd[1] === sd[2] + sd[3] && sd[2] + sd[3] === sd[4] + sd[5]) {
      arrTaxi = 'taxi';
    }

    if (format !== 'tu-quy') {
      if (
        (sd[0] === sd[1] && sd[1] === sd[2] && sd[2] === sd[3] && sd[3] !== sd[4]) ||
        (sd[1] === sd[2] && sd[2] === sd[3] && sd[3] === sd[4] && sd[4] !== sd[5])
      ) {
        arrCate5 = 'tu-quy-giua';
      }

      if (sd[0] === sd[1] && sd[1] === sd[2] && sd[2] === sd[3] && sd[3] === sd[4] && sd[4] !== sd[5]) {
        arrCate5 = 'ngu-quy-giua';
      }
    }

    if (
      soduoi4.slice(0, 3) === '197' ||
      soduoi4.slice(0, 3) === '198' ||
      soduoi4.slice(0, 3) === '199' ||
      soduoi4.slice(0, 3) === '200' ||
      soduoi4.slice(0, 3) === '201' ||
      soduoi4.slice(0, 3) === '202'
    ) {
      arrNamSinh = 'nam-sinh';
    }
    if (
      parseInt(soduoi6.slice(0, 2)) <= 31 &&
      parseInt(soduoi6.slice(0, 2)) >= 1 &&
      parseInt(soduoi6.slice(2, 4)) <= 12 &&
      parseInt(soduoi6.slice(2, 4)) >= 1 &&
      parseInt(soduoi6.slice(4, 6)) <= 99 &&
      parseInt(soduoi6.slice(4, 6)) >= 70
    ) {
      arrDDMMYY = 'nam-sinh';
    } else if (
      parseInt(soduoi6.slice(0, 2)) <= 31 &&
      parseInt(soduoi6.slice(0, 2)) >= 1 &&
      parseInt(soduoi6.slice(2, 4)) <= 12 &&
      parseInt(soduoi6.slice(2, 4)) >= 1 &&
      parseInt(soduoi6.slice(4, 6)) >= 0 &&
      parseInt(soduoi6.slice(4, 6)) <= 29
    ) {
      arrDDMMYY = 'nam-sinh';
    }
    if (format === 'taxi-tien') {
      arrTaxiTien =
        parseInt(sogiua3) === soduoi3 - 1
          ? 'taxi-tien'
          : parseInt(sogiua3) === soduoi3 - 10
          ? 'taxi-tien'
          : parseInt(sogiua3) === soduoi3 - 100
          ? 'taxi-tien'
          : '';
    }

    const arrSim = [
      arrCate,
      arrCate2,
      arrCate3,
      arrCate4,
      arrCate5,
      arrTaxi,
      arrTaxiTien,
      arrSanh3,
      arrTuQuy,
      arrNamSinh,
      arrDDMMYY,
    ];
    var filtered = arrSim.filter(function (el) {
      return el !== '';
    });
    return filtered.toString();
  }
};
