import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import ReplyIcon from '@mui/icons-material/Reply';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ButtonUI } from '../../../component/CustomerMUI';
import styles from './DetailHome.module.scss';
import * as listSimService from '../../../services/listSim';
import * as simService from '../../../services/apiWinServer';
import { LogicMenhSim } from '../LogicSearch/LogicMenhSim';
import images from '../../../assets/images';
import {
  getLocalStorage,
  numberFormatText,
  numberFormatTextCanvas,
  setLocalStorage,
  totalDiscount,
} from '../../../utils/helper';
import Footer from '../../../layouts/MainLayout/Footer/Footer';
import { LogicCategoryShowName } from '../LogicSearch/LogicCategoryShowName';
import { DataSim } from '../../../utils/DataSim';
import * as userIDService from '../../../services/apiUsers';
import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
import ThemeLoad from '../../../component/CustomerMUI/ThemeLoad/ThemeLoad';
import SimpleSnackbar from '../../../component/CustomerMUI/FrameInfoSim/NotifiAddCart';

const cx = classNames.bind(styles);
export default function DetailHome({ onClose }) {
  const [nameUser, setNameUser] = useState('');
  const [phoneUser, setPhoneUser] = useState('');
  const [addressUser, setAddressUser] = useState('');
  const [noteUser, setNoteUser] = useState('');
  const [messSuccess, setMessSuccess] = useState(false);
  const [isSimDaily, setIsSimDaily] = useState(false);
  const [statusOrder, setStatusOrder] = useState(false);
  const [simInfinteResult, setSimInfinteResult] = useState('');
  const [messError, setMessError] = useState('');
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const refCanvas = useRef(null);
  const params = useParams();
  const [authDomain, setAuthDomain] = useState({});
  const contextApiUser = useContext(ThemeContext);
  let textSimHeader = contextApiUser.textSearch;
  const [loadingTheme, setLoadingTheme] = useState(true);
  const [openCart, setOpenCart] = useState(false);

  const fetchApiSim = async () => {
    if (params.sim) {
      const resultAPISIM = await listSimService.listSimID(hostname, params.sim);
      if (resultAPISIM['data'].length > 0) {
        setLoadingTheme(false);
        let arrSim = resultAPISIM['data'][0];
        setSimInfinteResult(arrSim);
        setTimeout(() => {
          handleCanvas(arrSim);
        }, 100);
      } else {
        if (getLocalStorage('khoso')?.is_simdaily > 0) {
          const apiDetailDaily = await listSimService.SimDailyDetail(params.sim);
          if (apiDetailDaily['data'].length > 0) {
            setLoadingTheme(false);
            setIsSimDaily(true);
            let arrSim = apiDetailDaily['data'][0];
            setSimInfinteResult(arrSim);
            setTimeout(() => {
              handleCanvas(arrSim);
            }, 100);
          } else {
            setLoadingTheme(false);
          }
        } else {
          setLoadingTheme(false);
        }
      }
    }
  };
  const fetchApiUserID = async () => {
    const resultAPISIM = await userIDService.listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setLocalStorage('khoso', resultAPISIM[0]);
      setAuthDomain(resultAPISIM[0]);
      contextApiUser.toggleDataUser(resultAPISIM[0]);
    }
  };

  useEffect(() => {
    fetchApiSim();

    if (getLocalStorage('khoso')?.link_domain === hostname) {
      setAuthDomain(getLocalStorage('khoso'));
      contextApiUser.toggleDataUser(getLocalStorage('khoso'));
    } else {
      fetchApiUserID();
    }
    document.title = 'chi tiết sim ' + params.sim;
  }, []);

  const fetchApiMail = async () => {
    const mail = {
      mail: authDomain['GMAIL_NOTIFI'],
      nameuser: nameUser,
      simsearch: simInfinteResult.sim_show,
      network: simInfinteResult.nhamang,
      phoneuser: phoneUser,
      addressuser: addressUser,
      price: numberFormatText(simInfinteResult.price),
      noteuser: noteUser,
    };
    const resultAPI = await simService.sendMailSim(mail);
    if (resultAPI.success === 'OK') {
      setNameUser('');
      setPhoneUser('');
      setAddressUser('');
      setNoteUser('');
      setMessSuccess(true);
      setStatusOrder(false);
      navigate('/camon', { replace: true });
    }
    console.log(resultAPI);
  };

  const numberFormat = (number) => {
    let calcNumber;
    if (isNaN(number)) {
      calcNumber = number;
    } else {
      calcNumber = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);
    }
    return calcNumber;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nameUser.length < 4) {
      setMessError('Bạn nhập chưa đầy đủ họ tên');
    } else if (phoneUser.length !== 10) {
      setMessError('Bạn nhập chưa đúng số điện thoại liên hệ');
    } else if (addressUser.length < 15) {
      setMessError('Bạn nhập chưa đầy đủ địa chỉ ');
    } else {
      fetchApiMail();
      setStatusOrder(true);
    }
  };

  const handleUserName = (e) => {
    setNameUser(e.target.value);
    if (e.target.value.length >= 4) {
      setMessError('');
    }
  };

  const handlePhoneUser = (e) => {
    setPhoneUser(e.target.value);
    if (e.target.value.length === 10) {
      setMessError('');
    }
  };

  const handleAddressUser = (e) => {
    setAddressUser(e.target.value);
    if (e.target.value.length >= 15) {
      setMessError('');
    }
  };

  const handleClose = () => {
    console.log(123);
    navigate(-1);
    setNameUser('');
    setPhoneUser('');
    setAddressUser('');
    setNoteUser('');
    setMessError('');
  };

  const form = useRef();

  const handleCanvas = (arrSim) => {
    const canvas = refCanvas.current;
    const ctx = canvas?.getContext('2d');
    const img = new Image();
    img.src =
      arrSim.nhamang === 'Mobifone'
        ? images.phoi_mobi
        : arrSim.nhamang === 'Vinaphone'
        ? images.phoi_vina
        : arrSim.nhamang === 'Viettel'
        ? images.phoi_viettel
        : arrSim.nhamang === 'Vietnamobile'
        ? images.phoi_vnmb
        : arrSim.nhamang === 'Itelecom'
        ? images.phoi_itel
        : images.phoi_default;
    if (img.src === window.location.origin + '/') {
      ctx.canvas.width = 0;
      ctx.canvas.height = 0;
    } else {
      img.onload = () => {
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        ctx.font = '40px VCENTB';
        ctx.fillText(arrSim.sim_show, 185, 215);

        if (
          arrSim.nhamang === 'Mobifone' ||
          arrSim.nhamang === 'Vinaphone' ||
          arrSim.nhamang === 'Viettel' ||
          arrSim.nhamang === 'Vietnamobile' ||
          arrSim.nhamang === 'Itelecom'
        ) {
          ctx.font = 'bold 18px UTM-Avo';
          ctx.fillText('Giá sale', 95, 193);
          ctx.font = 'bold 18px UTM-Avo';
          ctx.fillText(numberFormatTextCanvas(arrSim.price), 100, 217);
        }
      };
    }
  };

  const DownloadCanvasAsImage = () => {
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', `sim_${simInfinteResult.sim}`);
    let canvas = refCanvas.current;
    canvas.toBlob(function (blob) {
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute('href', url);
      downloadLink.click();
    });
  };

  const favourite = (number) => {
    const last4Str = String(number).slice(-4);
    const last4Num = parseInt(last4Str);
    const tailnumbers = last4Num / 80;
    const surplus = getDecimalPart(tailnumbers);
    const totalHungCat = '0.' + surplus;
    const total = Math.round(parseFloat(totalHungCat) * 80);
    if (DataSim[total]['mucdo'] === 'Đại Cát' || DataSim[total]['mucdo'] === 'Cát') {
      return DataSim[total];
    } else {
      return '';
    }
  };

  const getDecimalPart = (num) => {
    // console.log(Number.isInteger(num), num);
    if (Number.isInteger(num)) {
      return 0;
    }

    const decimalStr = num.toString().split('.')[1];
    return parseInt(decimalStr);
  };

  const handleNameCate = (titleSim, cateSim, menhSim, formatSim) => {
    let arrFavourite = '';
    if (favourite(titleSim)) {
      arrFavourite = `<b style='color: #d5001e'>Sim Đại Cát</b>`;
    } else if (LogicCategoryShowName(cateSim?.split(',')[0])?.length > 0) {
      arrFavourite = LogicCategoryShowName(cateSim.split(',')[0]);
    } else if (isSimDaily) {
      arrFavourite = cateSim;
    } else if (LogicCategoryShowName(menhSim?.split(',')[0])?.length > 0) {
      arrFavourite = LogicCategoryShowName(menhSim?.split(',')[0]);
    } else {
      arrFavourite = `Dạng ${formatSim?.length > 0 && formatSim?.split(',')[0]}`;
    }
    return arrFavourite;
  };

  const onAddShop = (title, price, cate, soluong = 1, code) => {
    let obj = { title: title, price: price, cate: cate, soluong: soluong, code: code };
    let arrShop = getLocalStorage('addShop') || [];
    setLocalStorage('addShop', [...arrShop, obj]);
    setOpenCart(true);
    setTimeout(() => {
      setOpenCart(false);
    }, 3000);
  };

  return (
    <Container sx={{ pb: '70px', pl: '0px', pr: '0px' }}>
      <Grid container justifyContent="center" sx={{ bgcolor: 'seashell' }}>
        <Grid item md={10} xs={12}>
          {authDomain?.background && (
            <AppBar sx={{ position: 'relative', background: authDomain?.background?.split(',')[0] }}>
              <Toolbar>
                <Link to={-1}>
                  <IconButton edge="start" color="inherit" aria-label="close">
                    <ReplyIcon />
                  </IconButton>
                </Link>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  <b>CHI TIẾT SIM {simInfinteResult?.sim && simInfinteResult?.sim_show}</b>
                </Typography>
              </Toolbar>
            </AppBar>
          )}
          <Paper elevation={3} sx={{ mb: 2 }}>
            {loadingTheme && (
              <Grid item xs={12}>
                <Paper elevation={2}>
                  <Card variant="outlined">
                    <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                      <div style={{ padding: '10px' }}>
                        <Skeleton variant="rectangular" height={125} />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                      </div>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            )}
            {!simInfinteResult?.sim && !loadingTheme && <h4 style={{ textAlign: 'center' }}>SIM ĐÃ BÁN...</h4>}
            {simInfinteResult?.sim && (
              <Grid container alignItems={'center'}>
                <Grid item md={6}>
                  <div className={cx('container')}>
                    <canvas ref={refCanvas} style={{ borderRadius: 10 }} />
                    <div className={cx('download-image')} onClick={DownloadCanvasAsImage}>
                      <DownloadForOfflineIcon sx={{ color: '#FFF' }} />
                    </div>
                  </div>
                </Grid>
                <Grid item md={6}>
                  {/* <Card variant="outlined"> */}
                  <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                    {simInfinteResult && (
                      <div className={cx('detail-sim')}>
                        {simInfinteResult.sim_show && (
                          <p className={cx('detail-sim-title', 'dis-flex-center')}>
                            {favourite(simInfinteResult.sim) && <span className={cx('note-love')}>Yêu thích</span>}{' '}
                            <span style={{ marginRight: 4 }}>Số Sim: </span>
                            <span
                              className={cx(
                                simInfinteResult.nhamang === 'Mobifone'
                                  ? 'color-df'
                                  : simInfinteResult.nhamang === 'Vinaphone'
                                  ? 'color-blue'
                                  : simInfinteResult.nhamang === 'Itelecom'
                                  ? 'color-red'
                                  : simInfinteResult.nhamang === 'Vietnamobile'
                                  ? 'color-gold'
                                  : 'color-red',
                              )}
                            >
                              {simInfinteResult.sim_show}
                            </span>
                          </p>
                        )}
                        <Paper
                          elevation={0}
                          sx={{
                            mb: 1,
                            p: 1,
                            background: authDomain?.background?.split(',')[0] + '1c',
                            borderRadius: 2,
                          }}
                        >
                          <div className={cx('dis-flex-center')}>
                            {simInfinteResult.price_old && (
                              <p className={cx('price-old-item')}>{numberFormat(simInfinteResult.price_old)}</p>
                            )}
                            {simInfinteResult.price && (
                              <p className={cx('detail-sim-title-2')}>
                                <span className={cx('color-red')}>
                                  <b>{numberFormat(simInfinteResult.price)}</b>
                                </span>
                              </p>
                            )}
                            <span className={cx('note-phamtramsale')}>
                              <b>
                                {simInfinteResult.price_old &&
                                  totalDiscount(simInfinteResult.price, simInfinteResult.price_old)}
                              </b>
                              <b> GIẢM</b>
                            </span>
                          </div>
                        </Paper>
                        {favourite(simInfinteResult.sim) && (
                          <p className={cx('detail-sim-title-1')}>
                            Ý Nghĩa: <b className={cx('color-red')}>{favourite(simInfinteResult.sim)['ynghia']}</b>
                          </p>
                        )}
                        {simInfinteResult.nhamang && (
                          <p className={cx('detail-sim-title-1')}>
                            Nhà mạng:{' '}
                            <span
                              className={cx(
                                simInfinteResult.nhamang === 'Mobifone'
                                  ? 'color-df'
                                  : simInfinteResult.nhamang === 'Vinaphone'
                                  ? 'color-blue'
                                  : simInfinteResult.nhamang === 'Itelecom'
                                  ? 'color-red'
                                  : simInfinteResult.nhamang === 'Vietnamobile'
                                  ? 'color-gold'
                                  : 'color-red',
                              )}
                            >
                              <b>{simInfinteResult.nhamang}</b>
                            </span>
                          </p>
                        )}
                        {simInfinteResult.thuebao && (
                          <p className={cx('detail-sim-title-1')}>
                            Thuê bao: <b>{simInfinteResult.thuebao}</b>
                          </p>
                        )}

                        <p className={cx('detail-sim-title-1')}>
                          {' '}
                          {LogicCategoryShowName(simInfinteResult.menh_sim?.split(',')[0])?.length > 0
                            ? 'Mệnh sim: ' + LogicCategoryShowName(simInfinteResult.menh_sim?.split(',')[0])
                            : ''}
                        </p>

                        {simInfinteResult.id_catesim && (
                          <p className={cx('detail-sim-title-1')}>
                            Loại sim:{' '}
                            <b>
                              {(LogicCategoryShowName(simInfinteResult.id_catesim?.split(',')[0])?.length > 0
                                ? LogicCategoryShowName(simInfinteResult.id_catesim?.split(',')[0]) + ','
                                : ``) +
                                (LogicCategoryShowName(simInfinteResult.id_catesim?.split(',')[1])?.length > 0
                                  ? ' ' + LogicCategoryShowName(simInfinteResult.id_catesim?.split(',')[1]) + ','
                                  : isSimDaily
                                  ? simInfinteResult.id_catesim + ','
                                  : ``) +
                                `${
                                  simInfinteResult.format_sim?.length > 0
                                    ? 'Dạng ' + simInfinteResult.format_sim?.split(',')[0]
                                    : ''
                                }`}
                            </b>
                          </p>
                        )}

                        {simInfinteResult.note_sim && (
                          <p className={cx('detail-sim-title-1')}>
                            Ghi chú: <i>{simInfinteResult.note_sim}</i>
                          </p>
                        )}
                        <p className={cx('detail-sim-title-1')}>
                          <i>Giao sim {simInfinteResult.sim_show} toàn quốc 24/7</i>
                        </p>

                        <Button size="small" color="success" variant="contained">
                          <AddShoppingCartIcon
                            onClick={() =>
                              onAddShop(
                                simInfinteResult?.sim,
                                numberFormat(simInfinteResult?.price),
                                'sim',
                                1,
                                simInfinteResult?.sim,
                              )
                            }
                          />
                        </Button>
                        {openCart && <SimpleSnackbar Notifi="Đã thêm vào giỏ hàng" />}
                      </div>
                    )}
                  </CardContent>
                  {/* </Card> */}
                </Grid>
              </Grid>
            )}
          </Paper>
          {simInfinteResult?.sim && authDomain['GMAIL_NOTIFI'] && (
            <Grid item>
              <Paper elevation={2}>
                <Card variant="outlined">
                  <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                    <div className={cx('order-sim')}>
                      <h2>Đặt mua sim </h2>
                      <h3>- Miễn phí giao sim toàn quốc</h3>
                      <h3>- Nhận sim kiểm tra xong mới phải trả tiền</h3>
                    </div>
                  </CardContent>
                </Card>

                <Card variant="outlined">
                  <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                    <form ref={form} onSubmit={handleSubmit} className={cx('order-sim-input')}>
                      <TextField
                        defaultValue={simInfinteResult?.sim_show}
                        id="outlined-basic"
                        fullWidth
                        label="Sim mua"
                        variant="outlined"
                        name="sim"
                        type="text"
                        sx={{ display: 'none' }}
                      />
                      <TextField
                        defaultValue={nameUser}
                        onChange={handleUserName}
                        id="outlined-basic"
                        fullWidth
                        label="Họ và Tên"
                        variant="outlined"
                        name="userName"
                        type="text"
                        required
                        error={nameUser.length < 4}
                      />
                      <TextField
                        defaultValue={phoneUser}
                        onChange={handlePhoneUser}
                        id="outlined-basic"
                        fullWidth
                        label="Số điện thoại liên hệ"
                        variant="outlined"
                        name="phoneUser"
                        type="number"
                        required
                        error={phoneUser.length < 10}
                      />
                      <TextField
                        defaultValue={addressUser}
                        onChange={handleAddressUser}
                        id="outlined-basic"
                        fullWidth
                        label="Địa chỉ giao sim"
                        variant="outlined"
                        name="addressUser"
                        type="text"
                        required
                        error={addressUser.length < 15}
                      />
                      <TextField
                        defaultValue={noteUser}
                        onChange={(e) => setNoteUser(e.target.value)}
                        id="outlined-basic"
                        fullWidth
                        label="Yêu cầu khác (Không bắt buộc)"
                        variant="outlined"
                        name="noteUser"
                        type="text"
                      />
                      <TextField
                        defaultValue={numberFormat(simInfinteResult?.price)}
                        id="outlined-basic"
                        fullWidth
                        label="Tổng tiền trả khi nhận sim"
                        variant="outlined"
                        name="price"
                        type="text"
                        sx={{ display: 'none' }}
                      />
                      <div> </div>
                      <Typography variant="h5" color="error">
                        <b>{messError}</b>
                      </Typography>
                      <ButtonUI
                        sx={{ p: 1, fontSize: '19px' }}
                        color="error"
                        borderBtn="#ff0000"
                        borderRadius={4}
                        fullWidth
                        disabled={statusOrder}
                        type="submit"
                      >
                        Đặt Mua
                      </ButtonUI>
                    </form>
                    <div className={cx('order-sim-input')}>
                      {statusOrder && <LinearProgress color="error" />}
                      <ButtonUI sx={{ fontSize: '19px' }} borderRadius={4} fullWidth>
                        {authDomain['HOTTLINE_SIM'] ? ` Hoặc Gọi mua hàng: ${authDomain['HOTTLINE_SIM']}` : ''}
                      </ButtonUI>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <b>HƯỚNG DẪN MUA SIM</b>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={cx('guide-order-sim')}>
                            <p>
                              <b>Bước 1: ĐẶT SIM</b>
                              <br />
                              Quý khách điền đầy đủ thông tin và đặt mua{' '}
                              {authDomain['HOTTLINE_SIM'] ? `, hoặc liên hệ ${authDomain['HOTTLINE_SIM']}` : ``} để được
                              đặt hàng.
                            </p>
                            <p>
                              <b>Bước 2: XÁC NHẬN ĐƠN HÀNG</b>
                              <br />
                              Khi nhận được đơn hàng của quý khách, nhân viên bán hàng sẽ kiểm tra số trong kho của
                              chúng tôi và gọi điện lại báo cho quý khách về tình trạng sim quý khách đã đặt mua.
                            </p>
                            <p>
                              <b>Bước 3 &amp; 4: ĐĂNG KÝ THÔNG TIN VÀ GIAO SIM</b>
                              <br />▪ Cách 1: Giao sim tận nhà miễn phí trong ngày
                              <br />– Chúng tôi sẽ có giao dịch viên giao sim và đăng ký chính chủ tại nơi quý khách
                              đang ở hiện tại.
                              <br />– Quý khách kiểm tra đúng chính chủ, đúng số sim quý khách mới phải thanh toán
                            </p>
                            <p>
                              ▪ Cách 2: Nhận sim tại cửa hàng Viettel, Vinaphone, Mobifone, Vietnammobile, Itelcom gần
                              nhất
                              <br />– Giao dịch viên của chúng tôi sẽ cùng quý khách ra điểm giao dịch gần nhất để giao
                              dịch.
                              <br />– Quý khách kiểm tra đúng chính chủ, đúng số sim quý khách mới phải thanh toán.
                            </p>
                            <p>
                              ▪ Cách 3: Đặt cọc và thanh toán tiền còn lại khi nhận sim đối sim giá dưới 1tr: Quý khách
                              đảm bảo việc mua hàng bằng cách đặt cọc tối thiểu 10% giá trị sim qua chuyển khoản hoặc mã
                              thẻ cào. Chúng tôi sẽ gửi bưu điện phát sim đến tay quý khách và thu tiền còn lại (Hệ
                              thống bưu điện trên cả nước đều cung cấp dịch vụ thu hộ tiền cho người bán – gọi tắt là
                              COD. Theo đó, bưu điện sẽ giao hàng (sim) đến tận tay quý khách và thu tiền cho chúng tôi)
                            </p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
}
