import { useEffect, useState } from 'react';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SimCardIcon from '@mui/icons-material/SimCard';

import * as apiCheckSimDaily from '../../../services/apiCheckSimDaily';
import classNames from 'classnames/bind';
import styles from './simdaily.module.scss';
import { checkTokenDashboad, numberFormat } from '../../../utils/helper';
import FrameInfoSimDF from '../../../component/CustomerMUI/FrameInfoSim/FrameInfoSimDF';
import ThemeLoad from '../../../component/CustomerMUI/ThemeLoad/ThemeLoad';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

function ThoSim() {
  const [textSearchChange, setTextSearchChange] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [resultInfoSim, setResultInfoSim] = useState({});
  const [loadingTheme, setLoadingTheme] = useState(false);
  const navigate = useNavigate();

  const fetchCheckSimDaily = async (sim) => {
    const resultAPISIM = await apiCheckSimDaily.checkSimTho(sim);
    if (resultAPISIM.length > 0) {
      setLoadingTheme(false);
      setResultInfoSim(resultAPISIM[0]);
    } else {
      setLoadingTheme(false);
    }
  };

  const handleChangeSearchSim = (e) => {
    setTextSearchChange(e.target.value);
  };
  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      setLoadingTheme(true);
      setTextSearch(e.target.value);
      fetchCheckSimDaily(e.target.value);
    }
  };
  const onClickSearchSubmit = () => {
    setLoadingTheme(true);
    setTextSearch(textSearchChange);
    fetchCheckSimDaily(textSearchChange);
  };

  const handleDetailSim = (gia, giadaly, hoahong, updatesim, hotline) => {
    const arrInfoSim = [
      gia && `Giá bán lẻ: ${numberFormat(gia)}`,
      giadaly && `Giá đại lý: ${numberFormat(giadaly)}`,
      hoahong && `Lãi: ${numberFormat(hoahong)}`,
      updatesim && `Cập nhật: ${numberFormat(updatesim)}`,
      hotline && `Hotline: ${numberFormat(hotline)}`,
    ];
    return arrInfoSim;
  };
  return (
    <Container sx={{ pb: '65px' }}>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <header className={cx('wrapper-header')}>
            <div className={cx('inner-header')}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <Paper elevation={3} sx={{ p: '0px 2px', display: 'flex', alignItems: 'center', borderRadius: 2 }}>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder={`Nhập số điện thoại`}
                      inputProps={{ 'aria-label': 'Tìm kiếm sim số' }}
                      value={textSearchChange}
                      onChange={handleChangeSearchSim}
                      onKeyPress={handleEnterKey}
                      fullWidth
                    />
                    {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                    <IconButton
                      sx={{ m: '2px' }}
                      className={cx('bgr-default')}
                      aria-label="directions"
                      onClick={onClickSearchSubmit}
                    >
                      <SearchIcon sx={{ color: 'aliceblue' }} />
                    </IconButton>
                  </Paper>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ mt: 1 }}>
                <label className={cx('color-trang')}>
                  <b>Nhập số điện thoại bạn muốn tra cứu thông tin</b>
                </label>
              </Grid>
            </div>
          </header>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12} sx={{ mt: 1 }}>
        {loadingTheme && <ThemeLoad />}
      </Grid>
      {!loadingTheme && resultInfoSim?.simso && (
        <Grid container justifyContent="center">
          <Grid item md={6} xs={12} sx={{ mt: 1 }}>
            <Card variant="outlined" sx={{ borderRadius: 3 }}>
              <CardContent className={cx('bg-xanhlam')} sx={{ p: 0.5, '&:last-child': { pb: 1 } }}>
                <Stack direction="row" spacing={0} justifyContent="center" alignItems="flex-end">
                  <SimCardIcon color="gold" />
                  <h2 className={cx('font-fs')}>{resultInfoSim?.simso}</h2>
                </Stack>
                <Stack direction="row" spacing={0} justifyContent="center" alignItems="flex-end">
                  <span className={cx('fs-16')}>Giá khách: {numberFormat(resultInfoSim?.giakhach)} đ</span>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {!loadingTheme &&
        resultInfoSim?.infotho?.map((row, i) => (
          <Grid key={i} container justifyContent="center">
            <Grid item md={6} xs={12} sx={{ mt: 1 }}>
              <Card variant="outlined" sx={{ borderRadius: 3 }}>
                <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                  <FrameInfoSimDF
                    fontSize="medium"
                    fontSizeTitle="20"
                    bgrFrame={'red'}
                    title={`Thợ: ${row.nameTho}`}
                    arrDetailSim={handleDetailSim(
                      row.price,
                      row.priceGoc,
                      row.priceHH,
                      row.dateUpdateSim,
                      row.hotlineTho,
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ))}
    </Container>
  );
}

export default ThoSim;
