import * as requestAPI from '../utils/requestAPI';
import * as reqAuth from '../utils/requestAuthServer';
// gết danh sach
export const getFlashSale = async (user_id) => {
  try {
    const res = await requestAPI.getSim(`/getFlashSale?u=${user_id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getFlashSaleID = async (id) => {
  try {
    const res = await requestAPI.getSim(`/getFlashSaleID?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addFlashSaleID = async (data) => {
  try {
    const res = await reqAuth.postSim(`/addFlashSale`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateFlashSaleID = async (data) => {
  try {
    const res = await reqAuth.postSim(`/updateFlashSaleID`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const deleFlashSale = async (data) => {
  try {
    const res = await reqAuth.postSim(`/deleFlashSale`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
