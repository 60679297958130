import { useState } from 'react';
import { Alert, Button, Grid, Hidden, InputAdornment, Stack, TextField } from '@mui/material';
import { addDiscountAll, getDiscount } from '../../../services/apiDiscount';
import { useEffect } from 'react';
import { numberFormat } from '../../../utils/helper';

const Discount = () => {
  const hostname = window.location.hostname;
  const simDiscount = [
    {
      price_start: 0,
      price_end: 999999,
      discount: 35,
      domain: hostname,
      status: 1,
    },
    {
      price_start: 1000000,
      price_end: 2499999,
      discount: 30,
      domain: hostname,
      status: 1,
    },
    {
      price_start: 2500000,
      price_end: 4999999,
      discount: 25,
      domain: hostname,
      status: 1,
    },
    {
      price_start: 5000000,
      price_end: 9999999,
      discount: 20,
      domain: hostname,
      status: 1,
    },
    {
      price_start: 10000000,
      price_end: 19999999,
      discount: 15,
      domain: hostname,
      status: 1,
    },
    {
      price_start: 20000000,
      price_end: 1000000000,
      discount: 10,
      domain: hostname,
      status: 1,
    },
  ];
  const [resultDiscount, setResultDiscount] = useState([
    { price_start: 0, price_end: 0, discount: 0, domain: hostname, status: 1 },
  ]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchApiDiscount = async () => {
    const resultAPISIM = await getDiscount(hostname);
    if (resultAPISIM.length > 0) {
      setResultDiscount(resultAPISIM);
    } else {
      setResultDiscount([]);
    }
  };

  useEffect(() => {
    fetchApiDiscount();
  }, []);

  const handleChangPrice = (jsonValue, i) => {
    setResultDiscount((discount) =>
      discount.map((obj, index) => {
        if (index === i) {
          return { ...obj, ...jsonValue };
        }
        return obj;
      }),
    );
    setIsUpdate(true);
  };

  const handleAddPrice = () => {
    setResultDiscount([...resultDiscount, { price_start: 0, price_end: 0, discount: 0, domain: hostname, status: 1 }]);
    setIsUpdate(true);
  };

  const removePrice = (index) => {
    setResultDiscount((prevData) => prevData.filter((_, i) => i !== index));
    setIsUpdate(true);
  };

  const handleAddDiscount = async () => {
    const resultAPISIM = await addDiscountAll(resultDiscount);
    if (resultAPISIM.message === 'OK') {
      setIsUpdate(false);
      setLoading(true);
    } else {
      setIsUpdate(false);
      setLoading(true);
    }
  };

  const handleDefaultDiscount = () => {
    setResultDiscount(simDiscount);
    setIsUpdate(true);
  };
  return (
    <Grid container>
      {loading && <Alert severity="success">Cập nhật chiết khấu sim thành công!</Alert>}
      <br />
      {/* <Hidden only={['sm', 'xs']}> */}
      {/* <Stack direction={'column'} spacing={2}> */}
      {resultDiscount?.length > 0 &&
        resultDiscount.map((dis, i) => (
          <Grid container xs={12} key={i} spacing={1} sx={{ mt: 1, pb: 1, borderBottom: '1px dashed #ccc' }}>
            <Grid item xs={6} md={3} spacing={1}>
              <TextField
                label="Nhập Khoảng Giá Từ"
                InputProps={{
                  startAdornment: <InputAdornment position="start">Từ:</InputAdornment>,
                }}
                color="success"
                focused
                value={numberFormat(dis.price_start.toString())}
                onChange={(e) => {
                  handleChangPrice({ price_start: parseInt(e.target.value.replace(/\./g, '')) || 0 }, i);
                }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label="Nhập Khoảng Giá Đến"
                InputProps={{
                  startAdornment: <InputAdornment position="start">Đến:</InputAdornment>,
                }}
                color="success"
                focused
                value={numberFormat(dis.price_end.toString())}
                onChange={(e) => {
                  handleChangPrice({ price_end: parseInt(e.target.value.replace(/\./g, '')) || 0 }, i);
                }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label="Nhập % Chiết Khấu"
                InputProps={{
                  endAdornment: <InputAdornment position="start">%:</InputAdornment>,
                }}
                color="success"
                focused
                value={dis.discount}
                onChange={(e) => {
                  handleChangPrice({ discount: parseInt(e.target.value.replace(/\./g, '')) }, i);
                }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Button variant="contained" onClick={() => removePrice(i)} size="small" color="error">
                Xóa
              </Button>
            </Grid>
          </Grid>
        ))}
      {/* </Stack> */}
      {/* </Hidden> */}
      {/* <Hidden only={['md', 'lg', 'xl']}></Hidden> */}
      <Grid container xs={12} spacing={1} sx={{ mt: 1 }}>
        {isUpdate && (
          <Grid item xs={12} md={3}>
            <Button variant="contained" onClick={handleAddDiscount} size="small" color="error" fullWidth>
              Lưu Lại Chiết Khấu
            </Button>
          </Grid>
        )}
        <Grid item xs={6} md={3}>
          <Button variant="contained" onClick={handleAddPrice} size="small" color="success" fullWidth>
            Thêm khoảng giá chiết khấu
          </Button>
        </Grid>

        <Grid item xs={6} md={3}>
          <Button variant="contained" onClick={handleDefaultDiscount} size="small" color="warning" fullWidth>
            Thêm chiết khấu mặc định
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Discount;
