import classNames from 'classnames/bind';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from './Hopmenh.module.scss';
import { useEffect } from 'react';
import auth from '../../config/auth';
import Footer from '../../layouts/MainLayout/Footer/Footer';
import { Stack } from '@mui/system';
import { green } from '@mui/material/colors';
import { Box, Button, Grid } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import { Link, Navigate, useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#10793f',
    color: theme.palette.common.white,
    borderRight: '1px solid rgba(224, 224, 224, 1) !important',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#10793f',
    fontWeight: 600,
    borderRight: '1px solid rgba(224, 224, 224, 1) !important',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function createData(namsinh, menh, sohop, sokhac, link) {
  return { namsinh, menh, sohop, sokhac, link };
}
const rows = [
  createData('1960-1961', 'THỔ', '2,5,8,9', '3,4', 'tho'),
  createData('1962-1963', 'KIM', '2,5,6,7,8', '9', 'kim'),
  createData('1964-1965', 'HỎA', '3,4,9', '0,1', 'hoa'),
  createData('1966-1967', 'THỦY', '0,1,6,7', '2,5,8', 'thuy'),
  createData('1968-1969', 'THỔ', '2,5,8,9', '3,4', 'tho'),
  createData('1970-1971', 'KIM', '2,5,6,7,8', '9', 'kim'),
  createData('1972-1973', 'MỘC', '0,1,3,4', '6,7', 'moc'),
  createData('1974-1975', 'THỦY', '0,1,6,7', '2,5,8', 'thuy'),
  createData('1976-1977', 'THỔ', '2,5,8,9', '3,4', 'tho'),
  createData('1978-1979', 'HỎA', '3,4,9', ' 0, 1', 'hoa'),
  createData('1980-1981', 'MỘC', '0,1,3,4', '6,7', 'moc'),
  createData('1982-1983', 'THỦY', '0,1,6,7', ' 2,5,8', 'thuy'),
  createData('1984-1985', 'KIM', '2,5,6,7,8', '9', 'kim'),
  createData('1986-1987', 'HỎA', '3,4,9', ' 0, 1', 'hoa'),
  createData('1988-1989', 'MỘC', '0, 1,3,4', '6,7', 'moc'),
  createData('1990-1991', 'THỔ', '2,5,8,9', '3,4', 'tho'),
  createData('1992-1993', 'KIM', '2,5,6,7,8', ' 9', 'kim'),
  createData('1994-1995', 'HỎA', '3,4,9', ' 0, 1', 'hoa'),
  createData('1996-1997', 'THỦY', '0,1,6,7', ' 2,5,8', 'thuy'),
  createData('1998-1999', 'THỔ', '2,5,8,9', '3,4', 'tho'),
  createData('2000-2001', 'KIM', '2,5,6,7,8', '9', 'kim'),
  createData('2002-2003', 'MỘC', ' 0,1,3,4', '6,7', 'moc'),
  createData('2004-2005', 'THỦY', '0,1,6,7', '2,5,8', 'thuy'),
  createData('2006-2007', 'THỔ', '2,5,8,9', '3,4', 'tho'),
  createData('2008-2009', 'HỎA', '3,4,9', ' 0, 1', 'hoa'),
  createData('2010-2011', 'MỘC', ' 0,1,3,4', '6,7', 'moc'),
  createData('2012-2013', 'THỦY', '0,1,6,7', '2,5,8', 'thuy'),
  createData('2014-2015', 'KIM', '2,5,6,7,8', '9', 'kim'),
  createData('2016-2017', 'HỎA', '3,4,9', ' 0, 1', 'hoa'),
  createData('2018-2019', 'MỘC', ' 0,1,3,4', '6,7', 'moc'),
  createData('2020-2021', 'THỔ', '2,5,8,9', '3,4', 'tho'),
  createData('2022-2023', 'KIM', '2,5,6,7,8', '9', 'kim'),
];
function Hopmenh() {
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Phong thủy sim theo ngũ hành tương sinh';
  });

  const onHandleRoutesMenh = (link) => {
    navigate('/sim-menh/' + link, { replace: true });
  };
  return (
    <Grid container justifyContent={'center'} spacing={1} className={cx('bgr-img')}>
      <Grid item xs={12} md={5}>
        <Box sx={{ p: 1, pb: 10 }}>
          <Stack direction="row" spacing={0} alignItems="center" justifyContent={'space-around'}>
            <Box component={Link} to="/">
              <ReplyIcon />
            </Box>
            <Stack direction="column" spacing={0} alignItems="center" sx={{ p: 1, color: '#10793f' }}>
              <h2>BẢNG SIM HỢP MỆNH</h2>{' '}
              <b>
                (<i>Bạn ấn vào để lựa sim theo mệnh của bạn</i>)
              </b>
            </Stack>
          </Stack>
          <TableContainer component={Paper} sx={{ backgroundColor: '#ffffff80' }}>
            <Table size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    <b>NĂM SINH</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>MỆNH</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>SỐ HỢP</b>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <b>SỐ KHẮC</b>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.namsinh} onClick={() => onHandleRoutesMenh(row.link)}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {row.namsinh}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <span className={cx(row.link)}>{row.menh}</span>
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.sohop}</StyledTableCell>
                    <StyledTableCell align="center">{row.sokhac}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Hopmenh;
