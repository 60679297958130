import { useEffect, useRef, useState } from 'react';
import HomeTwo from './LayoutHome/HomeTwo';
import HomeOne from './LayoutHome/HomeOne';
import { getLocalStorage } from '../../utils/helper';
import { listUserID } from '../../services/apiUsers';
import HomeThree from './LayoutHome/HomeThree';
import styles from './Home.module.scss';
import classNames from 'classnames/bind';
import images from '../../assets/images';
import { API_URL_SIMSO } from '../../config/config';
const cx = classNames.bind(styles);
function Home() {
  const hostname = window.location.hostname;
  const [layoutSim, setLayoutSim] = useState('');

  const apiUser = async () => {
    const resultAPISIM = await listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setLayoutSim(resultAPISIM[0]['layoutSim']);
    }
  };
  useEffect(() => {
    apiUser();
  }, []);

  return (
    <>
      {layoutSim >= 1 && layoutSim <= 3 && <HomeOne />}
      {((layoutSim >= 4 && layoutSim <= 6) || layoutSim == 8) && <HomeTwo />}
      {layoutSim == 7 && <HomeThree />}
      {layoutSim.length === 0 && (
        <div className={cx('bgr-logo-loading')}>
          <img
            src={`${API_URL_SIMSO}/showlogo?name=logoload_${hostname}.jpg`}
            alt="sim số đẹp"
            className={cx('img-logo-loading')}
            onError={(e) => {
              e.target.src = images.logoloading;
            }}
          />
        </div>
      )}
    </>
  );
}

export default Home;
