export const LogicTotalDauDit = (phoneNumber, sodau, tongsodau, socuoi, tongsocuoi) => {
  const amountSimStart = phoneNumber.slice(0, parseInt(sodau));
  const amountSimEnd = phoneNumber.slice(-parseInt(socuoi));

  const sumAmountSimStart = amountSimStart.split('').reduce((accumulator, value) => {
    return parseInt(accumulator) + parseInt(value);
  }, 0);

  const sumAmountSimEnd = amountSimEnd.split('').reduce((accumulator, value) => {
    return parseInt(accumulator) + parseInt(value);
  }, 0);
  // console.log(sumAmountSimStart, parseInt(tongsodau));
  const resultSimStart = sumAmountSimStart === parseInt(tongsodau) ? true : false;
  const resultSimEnd = sumAmountSimEnd === parseInt(tongsocuoi) ? true : false;
  // console.log(resultSimStart, resultSimEnd);
  return [resultSimStart, resultSimEnd];
};
