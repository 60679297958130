import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import SearchIcon from '@mui/icons-material/Search';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';
import IosShareIcon from '@mui/icons-material/IosShare';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import AddToHomeScreenOutlinedIcon from '@mui/icons-material/AddToHomeScreenOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
import images from '../../../assets/images';
import styles from './Header.module.scss';
import auth from '../../../config/auth';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Hidden,
  IconButton,
  InputBase,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import * as userIDService from '../../../services/apiUsers';
import { ButtonUI } from '../../../component/CustomerMUI';
import { getCookie, getLocalStorage } from '../../../utils/helper';
import { API_URL_SIMSO } from '../../../config/config';
const cx = classNames.bind(styles);

function Header() {
  const params = useLocation();
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const contextApiUser = useContext(ThemeContext);
  let authDomain = contextApiUser.dataUser;
  let textSimHeader = contextApiUser.textSearch;
  const [open, setOpen] = useState(false);
  const [openApp, setOpenApp] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [isFixed, setIsFixed] = useState(false);
  const [isSearchSim, setIsSearchSim] = useState(1);
  let getToken = getCookie('token');

  useEffect(() => {
    setTextSearch(textSimHeader?.text);
    setIsSearchSim(textSimHeader?.isSim);
  }, [textSimHeader?.text]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true);
      }
      if (window.scrollY < 200) {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenApp = () => {
    setOpenApp(true);
  };

  const handleCloseApp = () => {
    setOpenApp(false);
  };

  const handleNav = () => {
    navigate('/', { replace: true });
    window.location.reload();
  };
  const redirectToExternalLink = (link) => {
    window.open(link, '_blank');
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      contextApiUser.toggleDataSim({ text: e.target.value, isSim: isSearchSim });
      setTextSearch(e.target.value);
      if (params.pathname !== '/') {
        if (isSearchSim === 1) {
          navigate(`/sim?q=${textSearch}`, { replace: true });
        }
        if (isSearchSim === 2) {
          navigate(`/shop?q=${textSearch}`, { replace: true });
        }
      }
    }
  };

  const onClickSearchSubmit = () => {
    contextApiUser.toggleDataSim({ text: textSearch, isSim: isSearchSim });
    if (params.pathname !== '/') {
      if (isSearchSim === 1) {
        navigate(`/sim?q=${textSearch}`, { replace: true });
      }
      if (isSearchSim === 2) {
        navigate(`/shop?q=${textSearch}`, { replace: true });
      }
    }
  };

  return (
    <>
      <Hidden only={['md', 'lg', 'xl']}>
        {authDomain && (
          <Stack direction={'row'} justifyContent={'space-around'} sx={{ background: 'black' }}>
            {hostname !== 'sieuthivuongkhang.vn' && (
              <>
                <b className={cx('notifi')}>Tải APP SIM ĐẸP trên Điện Thoại</b>{' '}
                <ButtonUI
                  bgrColorBtn={authDomain?.background?.split(',')[0]}
                  colorText={'#fff'}
                  borderBtn={'#fff'}
                  small
                  onClick={handleClickOpenApp}
                >
                  Tải App
                </ButtonUI>
              </>
            )}

            {getToken && (
              <ButtonUI
                bgrColorBtn={'#009688'}
                colorText={'#fff'}
                borderBtn={'#fff'}
                small
                onClick={() => {
                  navigate('/dashboard', { replace: true });
                }}
              >
                Vào ADMIN
              </ButtonUI>
            )}
          </Stack>
        )}
      </Hidden>

      <Dialog open={openApp} onClose={handleCloseApp}>
        {/(iPhone|iPad|iPod)/.test(navigator.platform) ? (
          <>
            <img src={images.ios} alt="ios" width={'100%'} />
            <Stack
              direction={'column'}
              spacing={1}
              sx={{ p: 1, background: '#3b3b3b', textAlign: 'center', color: '#fff' }}
              alignItems={'center'}
            >
              <h2>{'Hướng dẫn cài APP SIM SỐ ĐẸP'}</h2>
              <Stack direction={'row'} alignItems={'center'}>
                {'1. Ấn "thêm dấu trang"'} <IosShareIcon sx={{ color: 'blue' }} />
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                {'2. Ấn "Thêm vào MH chính"'} <AddBoxOutlinedIcon sx={{ color: 'blue' }} />
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                {'3. Ấn nút "Thêm" là hoàn thành'}
              </Stack>

              <Divider />
            </Stack>
          </>
        ) : (
          <>
            <img src={images.android} alt="android" width={'100%'} />
            <Stack
              direction={'column'}
              spacing={1}
              sx={{ p: 1, background: '#3b3b3b', textAlign: 'center', color: '#fff' }}
              alignItems={'center'}
            >
              <h2>{'Hướng dẫn cài APP SIM SỐ ĐẸP'}</h2>
              <Stack direction={'row'} alignItems={'center'}>
                {'1. Ấn "ba chấm"'} <MoreVertOutlinedIcon sx={{ color: 'blue' }} />
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                {'2. Ấn "Thêm vào Màn hình chính"'} <AddToHomeScreenOutlinedIcon sx={{ color: 'blue' }} />
              </Stack>
              <Stack direction={'row'} alignItems={'center'}>
                {'3. Ấn nút "Thêm" là hoàn thành'}
              </Stack>
            </Stack>
          </>
        )}
      </Dialog>
      {authDomain['layoutSim'] < 4 && (
        <div>
          {authDomain['LINKNOTIFI'] && (
            <div className={cx('wrapper-notifi')}>
              <span className={cx('ping')}></span>
              <b
                className={cx('notifi')}
                onClick={() => {
                  redirectToExternalLink(authDomain['LINKNOTIFI']);
                }}
              >
                {authDomain['NOTIFI']}
              </b>
            </div>
          )}
          {authDomain && (
            <header className={cx('wrapper')}>
              <div className={cx('inner')}>
                <Hidden only={['sm', 'xs']}>
                  <Grid container>
                    <Grid item xs={4} className={cx('logo-left-md')} onClick={() => handleNav()}>
                      <img
                        src={`${API_URL_SIMSO}/showlogo?name=logo_${hostname}.jpg`}
                        alt="sim số đẹp"
                        className={cx('logo')}
                        onError={(e) => {
                          e.target.src = images[authDomain['logo']];
                        }}
                      />
                      <label className={cx('color-logo-title')}>
                        <b>{authDomain['TITLE_HEADER']}</b>
                      </label>
                    </Grid>
                    <Grid item xs={4} className={cx('logo-right-md')}>
                      {authDomain['ADDRESS'] && (
                        <Grid item xs={12} className={cx('logo-center')}>
                          <label className={cx('color-hotline')}>
                            <b className={cx('color-red')}>{authDomain['ADDRESS']}</b>
                          </label>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={4} className={cx('logo-right-md')}>
                      {authDomain['HOTTLINE_SIM'] && (
                        <Grid item xs={12} className={cx('logo-center')}>
                          <label className={cx('color-hotline')}>
                            <b className={cx('color-black', 'dis-flex')}>
                              <PhoneInTalkRoundedIcon sx={{ mr: 1 }} color="error" /> {authDomain['HOTTLINE_SIM']}
                            </b>
                          </label>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                  <Grid container>
                    <Grid item xs={12} className={cx('logo-center')} onClick={() => handleNav()}>
                      <img
                        src={`${API_URL_SIMSO}/showlogo?name=logo_${hostname}.jpg`}
                        alt="sim số đẹp"
                        className={cx('logo')}
                        onError={(e) => {
                          e.target.src = images[authDomain['logo']];
                        }}
                      />
                      <label className={cx('color-logo-title')}>
                        <b>{authDomain['TITLE_HEADER']}</b>
                      </label>
                    </Grid>
                    {authDomain['HOTTLINE_SIM'] && (
                      <Grid item xs={12} className={cx('logo-center')}>
                        <label className={cx('color-hotline-xs')}>
                          <b className={cx('color-black', 'dis-flex')}>
                            <PhoneInTalkRoundedIcon sx={{ mr: 1 }} color="error" /> {authDomain['HOTTLINE_SIM']}
                          </b>
                        </label>
                      </Grid>
                    )}
                    {authDomain['ADDRESS'] && (
                      <Grid item xs={12} className={cx('logo-center')}>
                        <label className={cx('color-hotline-xs')}>
                          <b className={cx('color-red')}>{authDomain['ADDRESS']}</b>
                        </label>
                      </Grid>
                    )}

                    {/* <Grid item md={11.5} xs={10.5}>
            <Search />
          </Grid>
          <Grid item md={0.5} xs={1.5}>
            <IconButton aria-label="light" size="large" sx={{ pl: '25px', pb: '7px', pt: '4px' }} onClick={handleBoLoc}>
              <FilterAltRoundedIcon fontSize="inherit" style={{ color: 'rgb(255 223 227)' }} />
            </IconButton>
          </Grid> */}
                  </Grid>
                </Hidden>
              </div>
            </header>
          )}
        </div>
      )}

      {authDomain['layoutSim'] >= 4 && authDomain['layoutSim'] <= 8 && (
        <div>
          <Hidden only={['md', 'lg', 'xl']}>
            {authDomain['LINKNOTIFI'] && (
              <div className={cx('wrapper-notifi')}>
                <span className={cx('ping')}></span>
                <b
                  className={cx('notifi')}
                  onClick={() => {
                    redirectToExternalLink(authDomain['LINKNOTIFI']);
                  }}
                >
                  {authDomain['NOTIFI']}
                </b>
              </div>
            )}
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            {authDomain && (
              <Grid item xs={12} className={cx('logo-center')} onClick={() => handleNav()}>
                <label className={cx('color-logo-title-layout2-mobi')}>
                  <b>{authDomain['TITLE_HEADER']}</b>
                </label>
              </Grid>
            )}
            {authDomain['ADDRESS'] && (
              <Grid item xs={12} className={cx('logo-center')}>
                <label className={cx('color-hotline-xs')}>
                  <b className={cx('color-red')}>{authDomain['ADDRESS']}</b>
                </label>
              </Grid>
            )}
          </Hidden>
          {authDomain && (
            <Hidden only={['sm', 'xs']}>
              <header className={cx('wrapper-layout2', `${isFixed ? 'is-sticky' : ''}`)}>
                <div className={cx('inner')}>
                  <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={2} className={cx('logo-left-md')} onClick={() => handleNav()}>
                      <img
                        src={`${API_URL_SIMSO}/showlogo?name=logo_${hostname}.jpg`}
                        alt="sim số đẹp"
                        className={cx('logo-layout2')}
                        onError={(e) => {
                          e.target.src = images[authDomain['logo']];
                        }}
                      />
                      <label className={cx('color-logo-title-layout2')}>
                        <b>{authDomain['TITLE_HEADER']}</b>
                      </label>
                    </Grid>

                    <Grid item md={4}>
                      <Paper
                        elevation={3}
                        sx={{
                          height: '35px',
                          marginTop: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: 10,
                        }}
                        className={cx('filter-color')}
                      >
                        {authDomain['layoutSim'] === '6' && (
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={isSearchSim}
                            label="Age"
                            sx={{
                              height: 35,
                              borderRadius: '20px',
                            }}
                            onChange={(e) => setIsSearchSim(e.target.value)}
                          >
                            <MenuItem value={1}>Sim</MenuItem>
                            <MenuItem value={2}>Sản Phẩm</MenuItem>
                          </Select>
                        )}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder={isSearchSim === 1 ? `Nhập số sim cần tìm ...` : `Nhập tên sản phẩm cần tìm ...`}
                          inputProps={{ 'aria-label': 'Tìm kiếm sim số' }}
                          value={textSearch}
                          onChange={(e) => setTextSearch(e.target.value)}
                          onKeyPress={handleEnterKey}
                          fullWidth
                        />
                        {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}

                        <IconButton
                          sx={{ m: '2px', p: '4px' }}
                          className={cx('bgr-default')}
                          aria-label="directions"
                          onClick={onClickSearchSubmit}
                        >
                          <SearchIcon sx={{ color: 'aliceblue' }} />
                        </IconButton>
                      </Paper>
                    </Grid>

                    <Grid item md={4} sx={{ pt: 3 }}>
                      {authDomain['LINKNOTIFI'] ? (
                        <div className={cx('wrapper-notifi-layout2')}>
                          <span className={cx('ping-layout2')}></span>
                          <b
                            className={cx('notifi-layout2')}
                            onClick={() => {
                              redirectToExternalLink(authDomain['LINKNOTIFI']);
                            }}
                          >
                            {authDomain['NOTIFI']}
                          </b>
                        </div>
                      ) : authDomain['ADDRESS'] ? (
                        <div className={cx('wrapper-notifi-layout2')}>
                          <b className={cx('notifi-layout2')}>{authDomain['ADDRESS']}</b>
                        </div>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item md={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <Stack direction={'row'} spacing={2} sx={{ mt: 0.5 }}>
                        {authDomain['HOTTLINE_SIM'] && (
                          <label className={cx('color-hotline-layout2')}>
                            <b className={cx('color-white', 'dis-flex')}>
                              <PhoneInTalkRoundedIcon sx={{ mr: 1 }} color="error" /> {authDomain['HOTTLINE_SIM']}
                            </b>
                          </label>
                        )}
                        {getToken && (
                          <ButtonUI
                            bgrColorBtn={'#009688'}
                            colorText={'#fff'}
                            borderBtn={'#fff'}
                            small
                            onClick={() => {
                              navigate('/dashboard', { replace: true });
                            }}
                          >
                            Vào.ADMIN
                          </ButtonUI>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </div>
              </header>

              <header className={cx('wrapper-menu-layout2')}>
                <div className={cx('inner')}>
                  <Grid container spacing={1} sx={{ mt: 0 }}>
                    <Stack direction={'row'} spacing={5} sx={{ mt: 0.5 }} className={cx('menu')}>
                      <h3
                        onClick={() => {
                          navigate('/', { replace: true });
                          window.location.reload();
                        }}
                      >
                        TRANG CHỦ
                      </h3>
                      <h3
                        onClick={() => {
                          navigate('/ngu-hanh-tuong-sinh', { replace: true });
                        }}
                      >
                        SIM PHONG THỦY
                      </h3>
                      {authDomain['layoutSim'] === '6' && (
                        <h3
                          onClick={() => {
                            navigate('/shop', { replace: true });
                          }}
                        >
                          SẢN PHẨM MỚI
                        </h3>
                      )}
                      {hostname === 'sieuthivuongkhang.vn' && (
                        <h3
                          onClick={() => {
                            navigate('/kiem-tra-goi-cuoc', { replace: true });
                          }}
                        >
                          GÓI CƯỚC VINAPHONE
                        </h3>
                      )}
                      {/* <h3>ĐỊNH GIÁ SIM</h3> */}
                      {/* <h3>XEM ĐẠI CÁT</h3> */}
                      <h3
                        onClick={() => {
                          navigate('/bai-viet', { replace: true });
                        }}
                      >
                        TIN TỨC SIM
                      </h3>
                      <h3
                        onClick={() => {
                          navigate('/info', { replace: true });
                        }}
                      >
                        THANH TOÁN
                      </h3>
                    </Stack>
                  </Grid>
                </div>
              </header>
            </Hidden>
          )}

          <Hidden only={['md', 'lg', 'xl']}>
            <header className={cx('wrapper-layout2-mobi', `${isFixed ? 'is-sticky' : ''}`)}>
              <div className={cx('inner')}>
                <Grid container>
                  <Grid item xs={1.2} className={cx('logo-left-md')} onClick={() => handleNav()}>
                    <img
                      src={`${API_URL_SIMSO}/showlogo?name=logo_${hostname}.jpg`}
                      alt="sim số đẹp"
                      className={cx('logo-layout2-mobi')}
                      onError={(e) => {
                        e.target.src = images[authDomain['logo']];
                      }}
                    />
                    {/* <label className={cx('color-logo-title-layout2')}>
                        <b>{authDomain['TITLE_HEADER']}</b>
                      </label> */}
                  </Grid>

                  <Grid item xs={9.8}>
                    <Paper
                      elevation={3}
                      sx={{
                        height: '35px',
                        marginTop: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 10,
                      }}
                      className={cx('filter-color')}
                    >
                      {authDomain['layoutSim'] === '6' && (
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={isSearchSim}
                          label="Age"
                          sx={{
                            height: 35,
                            borderRadius: '20px',
                          }}
                          onChange={(e) => setIsSearchSim(e.target.value)}
                        >
                          <MenuItem value={1}>Sim</MenuItem>
                          <MenuItem value={2}>Sản Phẩm</MenuItem>
                        </Select>
                      )}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={isSearchSim === 1 ? `Nhập số sim cần tìm ...` : `Tìm sản phẩm ...`}
                        inputProps={{ 'aria-label': 'Tìm kiếm sim số' }}
                        value={textSearch}
                        onChange={(e) => setTextSearch(e.target.value)}
                        onKeyPress={handleEnterKey}
                        fullWidth
                      />
                      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}

                      <IconButton
                        sx={{ m: '2px', p: '4px' }}
                        className={cx('bgr-default')}
                        aria-label="directions"
                        onClick={onClickSearchSubmit}
                      >
                        <SearchIcon sx={{ color: 'aliceblue' }} />
                      </IconButton>
                    </Paper>
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton sx={{ p: 0 }} onClick={handleClickOpen}>
                      <MenuRoundedIcon sx={{ color: 'aliceblue', fontSize: '40px' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </header>
          </Hidden>
        </div>
      )}

      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar sx={{ position: 'relative', background: authDomain?.background?.split(',')[0] }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              DỊCH VỤ
            </Typography>

            <Button autoFocus color="inherit" onClick={handleClose}>
              Đóng
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          {hostname === 'sieuthivuongkhang.vn' && (
            <>
              <ListItemButton
                onClick={() => {
                  navigate('/kiem-tra-goi-cuoc', { replace: true });
                  handleClose();
                }}
              >
                <ListItemText primary="GÓI CƯỚC VINAPHONE" />
              </ListItemButton>
              <Divider />
            </>
          )}
          {authDomain['layoutSim'] === '6' && (
            <>
              <ListItemButton
                onClick={() => {
                  navigate('/shop', { replace: true });
                  handleClose();
                }}
              >
                <ListItemText primary="SẢN PHẨM MỚI" />
              </ListItemButton>
              <Divider />
            </>
          )}
          <ListItemButton
            onClick={() => {
              navigate('/ngu-hanh-tuong-sinh', { replace: true });
              handleClose();
            }}
          >
            <ListItemText primary="SIM PHONG THỦY" />
          </ListItemButton>
          <Divider />
          <ListItemButton
            onClick={() => {
              navigate('/bai-viet', { replace: true });
              handleClose();
            }}
          >
            <ListItemText primary="TIN TỨC SIM" />
          </ListItemButton>
          <Divider />
          <ListItemButton
            onClick={() => {
              navigate('/info', { replace: true });
              handleClose();
            }}
          >
            <ListItemText primary="THANH TOÁN" />
          </ListItemButton>
          <Divider />
          <ListItemButton
            onClick={() => {
              navigate('/user/login', { replace: true });
              handleClose();
            }}
          >
            <ListItemText primary="ĐĂNG NHẬP" />
          </ListItemButton>
        </List>
      </Dialog>
    </>
  );
}
export default Header;
