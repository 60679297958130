import * as reqAuth from '../utils/requestAuthServer';
// check login
export const checkSimDaily = async (sim) => {
  try {
    const res = await reqAuth.getSim(`/checkSimDaily?q=${sim}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const checkSimTho = async (sim) => {
  try {
    const res = await reqAuth.getSim(`/checktho?q=${sim}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
