import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SearchIcon from '@mui/icons-material/Search';
import SimCardIcon from '@mui/icons-material/SimCard';

import styles from './digishop.module.scss';
import {
  Badge,
  Grid,
  Hidden,
  Pagination,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import { Box } from '@mui/system';
import { ButtonUI } from '../../component/CustomerMUI';
import * as simService from '../../services/apiWinServer';
import { numberFormat, numberFormatText } from '../../utils/helper';
import { Link } from 'react-router-dom';
import OrderSim from './orderSim/ordersim';
import { DataSim } from '../../utils/DataSim';
const cx = classNames.bind(styles);

const dataSimVina = [
  {
    showPackage: {
      name: '6VD89P',
      duration: '6 Tháng',
      price: '535000',
      km: '- TẶNG THÊM 1 THÁNG',
      sms: '6VD89P DL 0918524989',
    },
    data_detail: {
      data: '120GB/tháng (4GB/ngày)',
      ngoaimang: '50 phút ngoại mạng',
      noimang: '1500p nội mạng',
    },
    price_mon: [
      { name: 'VD89P', duration: '1 Tháng', price: '89000', km: '', sms: 'VD89P DL 0918524989' },
      { name: '6VD89P', duration: '6 Tháng', price: '535000', km: '- TẶNG THÊM 1 THÁNG', sms: '6VD89P DL 0918524989' },
      {
        name: '12VD89P',
        duration: '12 Tháng',
        price: '1068000',
        km: '- TẶNG THÊM 2 THÁNG',
        sms: '12VD89P DL 0918524989',
      },
    ],
  },
  {
    showPackage: {
      name: '6D79P',
      duration: '5 THÁNG',
      price: '395000',
      km: '- TẶNG THÊM 1 THÁNG',
      sms: '6D79P DL 0918524989',
    },
    data_detail: {
      data: '90GB/tháng (3GB/ngày)',
      ngoaimang: '50 phút ngoại mạng',
      noimang: '1500p nội mạng',
    },
    price_mon: [
      { name: 'D79P', duration: '1 THÁNG', price: '79000', km: '', sms: 'D79P DL 0918524989' },
      { name: '3D79P', duration: '3 THÁNG', price: '237000', km: '', sms: '3D79P DL 0918524989' },
      { name: '6D79P', duration: '5 THÁNG', price: '395000', km: '- TẶNG THÊM 1 THÁNG', sms: '6D79P DL 0918524989' },
      { name: '12D79P', duration: '10 THÁNG', price: '790000', km: '- TẶNG THÊM 2 THÁNG', sms: '12D79P DL 0918524989' },
    ],
  },
  {
    showPackage: {
      name: '6VD149',
      duration: '6 THÁNG',
      price: '750000',
      km: 'TẶNG 1 THÁNG',
      sms: '6VD149 DL 0918524989',
    },
    data_detail: {
      data: '180GB/tháng (6GB/ngày)',
      ngoaimang: '50 phút ngoại mạng',
      noimang: '1500p nội mạng',
    },
    price_mon: [
      { name: 'VD149', duration: '1 THÁNG', price: '149000', km: '', sms: 'VD149 DL 0918524989' },
      {
        name: '3VD149',
        duration: '3 THÁNG',
        price: '375000',
        km: '- GIẢM TIỀN NỬA THÁNG',
        sms: '3VD149 DL 0918524989',
      },
      { name: '6VD149', duration: '5 THÁNG', price: '750000', km: '- TẶNG THÊM 1 THÁNG', sms: '6VD149 DL 0918524989' },
      {
        name: '12VD149',
        duration: '10 THÁNG',
        price: '1500000',
        km: '- TẶNG THÊM 2 THÁNG',
        sms: '12VD149 DL 0918524989',
      },
    ],
  },
];

const arrDauSim = [
  { id: 0, dauso: 'Tất cả' },
  { id: 8491, dauso: '091' },
  { id: 8494, dauso: '094' },
  { id: 8488, dauso: '088' },
  { id: 8481, dauso: '081' },
  { id: 8482, dauso: '082' },
  { id: 8483, dauso: '083' },
  { id: 8484, dauso: '084' },
  { id: 8485, dauso: '085' },
];

function DiGiShop() {
  useEffect(() => {
    document.title = 'Kho số Vinaphone giá tận gốc';
  });

  const [listSimDefaut, setListSimDefaut] = useState([]);
  const [loading, setLoading] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [packageSim, setPackageSim] = useState(dataSimVina);
  const [detailSim, setDetailSim] = useState({});
  const [open, setOpen] = useState(false);
  const [menuSim, setMenuSim] = useState(0);
  const [dausoSim, setDauSoSim] = useState(0);

  const fetchApiSim = async (page_number = 0, page_record = 20) => {
    const resultAPI = await simService.listSimVinaDefault(page_number, page_record);
    if (resultAPI.data.length > 0) {
      setListSimDefaut(resultAPI);
      setLoading(false);
    }
  };

  const fetchApiSearchSim = async (timsim, dauso = '') => {
    const resultAPI = await simService.searchSim(timsim, dauso);
    if (resultAPI.message === 'success') {
      setListSimDefaut(resultAPI);
      setLoading(false);
    } else {
      setListSimDefaut([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApiSim();
  }, []);

  const handleChangeSearchSim = (e) => {
    setTextSearch(e.target.value);
  };

  const onClickSearchSubmit = () => {
    const dauso = dausoSim === 0 ? '' : dausoSim;
    if (textSearch) {
      fetchApiSearchSim(textSearch, dauso);
    } else {
      fetchApiSim();
    }
    setLoading(true);
  };
  const onClickSearchDauSim = (dausoS) => {
    const dauso = dausoS === 0 ? '' : dausoS;
    if (textSearch) {
      fetchApiSearchSim(textSearch, dauso);
    } else {
      fetchApiSim();
    }
    setLoading(true);
  };

  const handlePagination = (e) => {
    const page = e.target.textContent;
    fetchApiSim(page - 1, 20);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      const dauso = dausoSim === 0 ? '' : dausoSim;
      if (textSearch) {
        fetchApiSearchSim(textSearch, dauso);
      } else {
        fetchApiSim();
      }
    }
  };

  const onUpdatePackageSim = (pak, i) => {
    const newState = packageSim.map((obj, index) => {
      if (index === i) {
        return { ...obj, showPackage: pak };
      }
      return obj;
    });
    setPackageSim(newState);
  };

  //click open modal
  const favourite = (number) => {
    const last4Str = String(number).slice(-4);
    const last4Num = parseInt(last4Str);
    const tailnumbers = last4Num / 80;
    const surplus = getDecimalPart(tailnumbers);
    const totalHungCat = '0.' + surplus;
    const total = Math.round(parseFloat(totalHungCat) * 80);
    if (DataSim[total]['mucdo'] === 'Đại Cát' || DataSim[total]['mucdo'] === 'Cát') {
      return DataSim[total];
    } else {
      return '';
    }
  };

  const getDecimalPart = (num) => {
    // console.log(Number.isInteger(num), num);
    if (Number.isInteger(num)) {
      return 0;
    }

    const decimalStr = num.toString().split('.')[1];
    return parseInt(decimalStr);
  };
  const handleModalOpen = (sim) => {
    const favoriteSim = favourite(sim.so_tb.toString()) && favourite(sim.so_tb.toString())['ynghia'];

    const simDetailDailog = {
      sim: sim.so_tb.toString(),
      price: sim.price.toString(),
      afi: '0984494949',
      subCard: 'Trả Trước',
      netWork: 'Vinaphone',
      means: favoriteSim,
    };
    // console.log(simDetailDailog);
    setOpen(true);
    setDetailSim(simDetailDailog);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {detailSim && <OrderSim openDiaLog={open} onClose={handleClose} detailSim={detailSim} />}
      {/* <div className={cx('inner')}> */}
      <Grid container>
        <Grid item md={6} xs={12} sx={{ ml: 'auto', mr: 'auto' }}>
          <header className={cx('wrapper')}>
            <div className={cx('inner')}>
              <Grid container>
                <Grid item xs={12} className={cx('logo-center')}>
                  <label className={cx('color-trang')}>
                    <b className={cx('font-size-title')}>
                      {(menuSim === 0 && `XẢ KHO SIM VINAPHONE GIÁ RẺ`) ||
                        (menuSim === 1 && `KIỂM TRA GÓI MẠNG THEO THUÊ BAO`) ||
                        (menuSim === 2 && `CÁCH THỨC GIAO DỊCH SIM`)}
                      <br />
                      {/* {menuSim === 0 && `TÌM KIẾM SIM THEO YÊU CẦU`} */}
                    </b>
                  </label>
                </Grid>

                <Grid item md={12} xs={12} sx={{ mb: 7.5 }}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: '0px 2px',
                      display: 'flex',
                      border: '2px solid #fe4960',
                      alignItems: 'center',
                      borderRadius: 6,
                    }}
                  >
                    {/* <IconButton>
                  <SearchIcon />
                </IconButton> */}
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Nhập đuôi sim cần tìm. Vd đuôi 39 -> *39"
                      inputProps={{ 'aria-label': 'Tìm kiếm sim số' }}
                      onChange={handleChangeSearchSim}
                      onKeyPress={handleEnterKey}
                    />
                    {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                    <IconButton
                      sx={{ m: '2px' }}
                      className={cx('bgr-default')}
                      aria-label="directions"
                      onClick={onClickSearchSubmit}
                    >
                      <SearchIcon sx={{ color: 'aliceblue' }} />
                    </IconButton>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </header>
        </Grid>
      </Grid>
      {/* </div> */}

      {/* ĐẦU SIM SỐ */}
      <Grid container>
        <Grid item md={6} xs={12} sx={{ mb: 4, mt: -6, ml: 'auto', mr: 'auto' }}>
          <Stack direction={'row'} spacing={0.5} alignItems={'center'} justifyContent="center">
            {arrDauSim.map((ds, i) => (
              <Badge
                key={i}
                color={dausoSim === ds.id ? 'success' : 'default'}
                overlap="rectangular"
                badgeContent=" "
                variant="dot"
                onClick={() => {
                  setDauSoSim(ds.id);
                  onClickSearchDauSim(ds.id);
                }}
              >
                <IconButton
                  className={cx(dausoSim === ds.id ? 'active-dauso' : 'no-active-dauso')}
                  aria-label="directions"
                >
                  <b>{ds.dauso}</b>
                </IconButton>
              </Badge>
            ))}
          </Stack>
        </Grid>
      </Grid>

      <div className={cx('inner')}>
        <Grid container>
          <Grid item md={6} xs={12} sx={{ mb: 1, mt: menuSim === 0 ? -3 : -5, ml: 'auto', mr: 'auto', zIndex: 10 }}>
            <Paper
              elevation={3}
              className={cx('box-shadow-menu')}
              sx={{ p: '4px 2px', display: 'flex', alignItems: 'center', bgcolor: '#ffffe4' }}
            >
              <Grid item md={4} xs={4} sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => setMenuSim(0)}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Badge
                    color={menuSim === 0 ? 'success' : 'default'}
                    overlap="circular"
                    badgeContent=" "
                    variant="dot"
                  >
                    <IconButton
                      className={cx(menuSim === 0 ? 'active-cate' : 'no-active-cate')}
                      aria-label="directions"
                    >
                      <SimCardIcon className={cx('color-cate')} />
                    </IconButton>
                  </Badge>
                  <b className={cx('font-12')}>Sim Số</b>
                </Box>
              </Grid>

              <Grid item md={4} xs={4} sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => setMenuSim(1)}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Badge
                    color={menuSim === 1 ? 'success' : 'default'}
                    overlap="circular"
                    badgeContent=" "
                    variant="dot"
                  >
                    <IconButton
                      className={cx(menuSim === 1 ? 'active-cate' : 'no-active-cate')}
                      aria-label="directions"
                      component={Link}
                      to="/kiem-tra-goi-cuoc-vinaphone"
                    >
                      <SystemSecurityUpdateGoodIcon className={cx('color-cate')} />
                    </IconButton>
                  </Badge>
                  <b className={cx('font-12')}>Kiểm Tra Gói Cước</b>
                </Box>
              </Grid>

              <Grid item md={4} xs={4} sx={{ display: 'flex', justifyContent: 'center' }} onClick={() => setMenuSim(2)}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Badge
                    color={menuSim === 2 ? 'success' : 'default'}
                    overlap="circular"
                    badgeContent=" "
                    variant="dot"
                  >
                    <IconButton
                      className={cx(menuSim === 2 ? 'active-cate' : 'no-active-cate')}
                      aria-label="directions"
                      component={Link}
                      to="/cach-giao-dich"
                    >
                      <LocalShippingIcon className={cx('color-cate')} />
                    </IconButton>
                  </Badge>
                  <b className={cx('font-12')}>Giao Dịch</b>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>

      <div>
        {/* <div className={cx('inner')}>
          <Grid container item md={6} xs={12} sx={{ mb: 2, ml: 'auto', mr: 'auto' }}>
            <div className={cx('box-list-subcate')}>
              <div className={cx('list-subcate')}>
                <Stack direction="row">
                  {packageSim.map((data, index) => (
                    <div key={index} className={cx('sim-0d-content')}>
                      <div className={cx('sim-info')}>
                        <div className={cx('content')}>
                          <div className={cx('content__title')}>Gói cước áp dụng cho sim mua tại website</div>
                          <div className={cx('content__info')}>
                            <span className={cx('content__info__sim')}>
                              <strong>
                                GÓI {data.showPackage.name} - {data.showPackage.duration}
                              </strong>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={cx('booking-info')}>
                        <div className={cx('item-package')}>
                          <ul className={cx('item-package__info')}>
                            <li>- {data.data_detail.data}</li>
                            <li>- {data.data_detail.ngoaimang}</li>
                            <li>- {data.data_detail.noimang}</li>
                            <li>
                              <b className={cx('color-red')}>{data.showPackage.km}</b>
                            </li>
                          </ul>
                        </div>
                        <div className={cx('item-book')}>
                          <span className={cx('item-book__price')}>{numberFormat(data.showPackage.price)}</span>
                          <ButtonUI
                            borderBtn="#b91b2a"
                            bgrColorBtn="#b91b2a"
                            colorText="#FFF"
                            borderRadius={30}
                            href={`sms:1543?&body=${data?.showPackage?.sms}`}
                          >
                            Đăng ký
                          </ButtonUI>
                        </div>
                      </div>

                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent={'center'}
                        sx={{ pl: 1, pr: 1, alignItems: 'center', mb: 1 }}
                      >
                        <h3> Gói: </h3>
                        {data?.price_mon?.map((row, i) => (
                          <ButtonUI
                            key={i}
                            bgrColorBtn={row.name === data?.showPackage.name ? '#ff000026' : '#F3F4F6'}
                            colorText={row.name === data?.showPackage.name ? '#ff0000' : '#444444'}
                            borderBtn={row.name === data?.showPackage.name ? '#ff00004a' : '#dbdbdbc9'}
                            onClick={() => onUpdatePackageSim(row, index)}
                          >
                            <b>{row.duration}</b>
                          </ButtonUI>
                        ))}
                      </Stack>

                      <Hidden only={['sm', 'xs']}>
                        <Stack direction="row" spacing={1} justifyContent={'center'} sx={{ pl: 1, pr: 1, mb: 1 }}>
                          <span>
                            Soạn Cú Pháp: <b>{data?.showPackage?.sms} gửi 1543</b>
                          </span>
                        </Stack>
                      </Hidden>
                      <Stack direction="row" spacing={1} justifyContent={'center'} sx={{ pl: 1, pr: 1, mb: 1 }}>
                        <span>
                          <b>LƯU Ý:</b> Quý khách đăng ký gói giữ nguyên cú pháp <br /> để hệ thống đăng ký thành công
                        </span>
                      </Stack>
                    </div>
                  ))}
                </Stack>
              </div>
            </div>
          </Grid>
        </div> */}

        {/* <div className={cx('inner')}>
          <Hidden only={['md', 'lg', 'xl']}>
            <Grid
              container
              item
              md={6}
              xs={12}
              sx={{
                mt: 1,
                mb: 1,
                ml: 'auto',
                mr: 'auto',
                bgcolor: 'red',
                color: 'gold',
                alignItems: 'center',
                borderStyle: 'double',
                borderRadius: 3,
                display: 'flex',
                justifyContent: 'center',
              }}
              className={cx('bgr-image')}
            >
              <Stack direction={'row'} spacing={1} sx={{ p: 0.5, alignItems: 'center' }}>
                <Box>
                  <b className={cx('font-16')}>ƯU ĐÃI GÓI DATA VD89P</b>
                </Box>
              </Stack>
              <Stack direction={'row'} spacing={1} sx={{ p: 0.5, alignItems: 'center' }}>
                <Stack direction={'column'} spacing={0.5}>
                  <b className={cx('boder-right')}>120GB/ Tháng</b>
                  <b className={cx('boder-right')}>4GB/ Ngày</b>
                </Stack>
                <Stack direction={'column'} spacing={0.5}>
                  <b className={cx('boder-right')}>50 Phút Gọi Ngoại Mạng</b>
                  <b className={cx('boder-right')}>1500 Phút Gọi Nội Mạng</b>
                </Stack>
              </Stack>
            </Grid>
          </Hidden>
          <Hidden only={['sm', 'xs']}>
            <Grid
              container
              item
              md={6}
              xs={12}
              sx={{
                mt: 1,
                mb: 1,
                ml: 'auto',
                mr: 'auto',
                bgcolor: 'red',
                color: 'gold',
                alignItems: 'center',
                borderStyle: 'double',
                borderRadius: 3,
                display: 'flex',
                justifyContent: 'center',
              }}
              className={cx('bgr-image')}
            >
              <Stack direction={'row'} spacing={1} sx={{ p: 0.5, alignItems: 'center' }}>
                <Box>
                  <b className={cx('font-16')}>ƯU ĐÃI GÓI DATA VD89P</b>
                </Box>
              </Stack>
              <Stack direction={'row'} spacing={1} sx={{ p: 0.5, alignItems: 'center' }}>
                <Stack direction={'row'} spacing={0.5}>
                  <b className={cx('boder-right')}>120GB/ Tháng</b>
                  <b className={cx('boder-right')}>4GB/ Ngày</b>
                </Stack>
                <Stack direction={'row'} spacing={0.5}>
                  <b className={cx('boder-right')}>50 Phút Gọi Ngoại Mạng</b>
                  <b className={cx('boder-right')}>1500 Phút Gọi Nội Mạng</b>
                </Stack>
              </Stack>
            </Grid>
          </Hidden>
        </div> */}

        <div className={cx('inner')}>
          <Grid container>
            <Grid
              container
              item
              md={6}
              xs={12}
              sx={{
                mb: 2,
                ml: 'auto',
                mr: 'auto',
                borderBottom: '5px solid #005ccd',
                borderRadius: 5,
              }}
            >
              <TableContainer component={Paper} sx={{ borderRadius: 3 }}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={cx('table_brg-right', 'pd-none')}>
                        <b>SIM SỐ</b>
                      </TableCell>
                      <TableCell align="center" className={cx('table_brg-right', 'pd-none')}>
                        <b>GIÁ THANH LÝ</b>
                      </TableCell>
                      {/* <TableCell align="center" className={cx('table_brg-right', 'pd-none')}>
                        <b>COMBO 1</b>
                      </TableCell>

                      <TableCell align="center" className={cx('table_brg', 'pd-none')}>
                        <b>COMBO 2</b>
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && (
                      <TableRow>
                        <TableCell component="th" scope="row" sx={{ p: '10px', borderBottom: '1px dashed #f93782' }}>
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" sx={{ p: '10px', borderBottom: '1px dashed #f93782' }}>
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" sx={{ p: '10px', borderBottom: '1px dashed #f93782' }}>
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center" sx={{ p: '10px', borderBottom: '1px dashed #f93782' }}>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      {/* <TableCell align="center" className={cx('table_brg-right', 'pd-none')}>
                        <b>SIÊU ƯU ĐÃI</b>
                      </TableCell>
                      <TableCell align="center" className={cx('table_brg-right', 'pd-none')}>
                        <b>(Chưa kèm gói)</b>
                      </TableCell> */}

                      {/* <TableCell align="center" className={cx('table_brg-right', 'pd-none')}>
                        <b>(sim + kèm gói 7 tháng)</b>
                      </TableCell>
                      <TableCell align="center" className={cx('table_brg', 'pd-none')}>
                        <b>(sim + kèm gói 14 tháng)</b>
                      </TableCell> */}
                    </TableRow>
                    <TableRow>
                      <TableCell className={cx('table_brg-right', 'pd-none')}></TableCell>
                      <TableCell align="center" className={cx('table_brg-right', 'pd-none')}></TableCell>

                      {/* <TableCell align="center" className={cx('table_brg-right', 'pd-none')}>
                        <b>SALE còn</b>
                      </TableCell>
                      <TableCell align="center" className={cx('table_brg', 'pd-none')}>
                        <b>SALE còn</b>
                      </TableCell> */}
                    </TableRow>
                    {!loading &&
                      listSimDefaut?.data?.map((row, i) => (
                        <TableRow key={i} onClick={() => handleModalOpen(row)} sx={{ bgcolor: '#feffe9' }}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={{ p: '5px' }}
                            className={cx('brg-right')}
                          >
                            {favourite(row.so_tb.toString()) ? (
                              <Typography
                                className={cx('UTM-A')}
                                sx={{ color: '#f93782', fontWeight: 600, fontSize: '1.3rem' }}
                              >
                                {row.so_tb.substring(1)}
                              </Typography>
                            ) : (
                              <Typography
                                className={cx('UTM-A')}
                                sx={{ color: '#0095d9', fontWeight: 600, fontSize: '1.3rem' }}
                              >
                                {row.so_tb.substring(1)}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell align="center" sx={{ p: '5px' }} className={cx('brg-right')}>
                            <b className={cx('price-old-item')}>350K</b>
                            <b className={cx('price-item', 'UTM-A')}>79.000đ</b>
                          </TableCell>

                          {/* <TableCell align="center" sx={{ p: '5px' }} className={cx('brg-right')}>
                            <b className={cx('price-old-item', 'UTM-A')}>835K</b>
                            <b className={cx('price-item', 'UTM-A')}>735K</b>
                          </TableCell>
                          <TableCell align="center" sx={{ p: '5px' }}>
                            <b className={cx('price-old-item', 'UTM-A')}>1.368K</b>
                            <b className={cx('price-item', 'UTM-A')}>1.168K</b>
                          </TableCell> */}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {listSimDefaut.totalItems > 20 && (
              <Grid item md={12} xs={12} sx={{ mb: 2, ml: 'auto', mr: 'auto' }}>
                <Grid item md={6} xs={12} sx={{ mb: 2, ml: 'auto', mr: 'auto' }}>
                  <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                    <Pagination
                      count={Math.ceil(listSimDefaut.totalItems / 20)}
                      variant="outlined"
                      color="primary"
                      onClick={handlePagination}
                    />
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default DiGiShop;
