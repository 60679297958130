import { useCallback, useEffect, useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { toPng, toBlob } from 'html-to-image';
import html2canvas from 'html2canvas';

import classNames from 'classnames/bind';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import styles from './ImageExcel.module.scss';
import { Box, Button, Grid, LinearProgress, Stack } from '@mui/material';
import { numberFormatText } from '../../../utils/helper';
const cx = classNames.bind(styles);
const ImageExcel = (data) => {
  const tableRef = useRef([]);
  const [arrImage, setArrImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataBase64, setDataBase64] = useState([]);

  const columnCount = data?.column || 2;
  const colorTable = data?.color || 'blue';
  const handleExportImage = (index) => {
    const timer = setTimeout(() => {
      html2canvas(tableRef.current[index], { scale: 3 })
        .then((canvas) => {
          // Convert canvas to image data URL
          const dataUrl = canvas.toDataURL('image/png', 1);
          saveAs(dataUrl, 'images.png');
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error exporting image:', error);
          setLoading(false);
        });
    }, 500);
    return () => clearTimeout(timer);
  };
  useEffect(() => {
    handleOpenImage(data?.soluong);
  }, [data]);

  const handleOpenImage = async (soluong) => {
    let arrDataImage = [];

    if (data?.dataSim?.length > 0) {
      const iterations = Math.ceil(data?.dataSim?.length / soluong);
      for (let i = 0; i < iterations; i++) {
        const startIndex = i * soluong;
        const endIndex = (i + 1) * soluong;
        const batch = data?.dataSim?.slice(startIndex, endIndex);
        try {
          const rowCount1 = Math.ceil(batch.length / columnCount);
          // Tạo mảng 2 chiều để chứa dữ liệu cho bảng
          const tableData1 = Array(columnCount)
            .fill(null)
            .map(() => Array(rowCount1).fill(null));

          // Đổ dữ liệu vào mảng 2 chiều
          batch.forEach((item, index) => {
            const row = Math.floor(index / rowCount1);
            const col = index % rowCount1;
            tableData1[row][col] = item;
          });
          arrDataImage.push(tableData1);
        } catch (error) {
          console.error(error);
        }
      }
      setArrImage(arrDataImage);
    }
  };

  const handleDownloadZip = () => {
    const timer = setTimeout(() => {
      const zip = new JSZip();
      const promises = [];

      tableRef.current.forEach((ref, index) => {
        promises.push(
          new Promise((resolve) => {
            toPng(ref, { pixelRatio: 3 }).then((dataUrl) => {
              // Convert canvas to image data URL
              // const dataUrl = canvas.toDataURL('image/png', 1);

              // Convert data URL to Blob
              fetch(dataUrl)
                .then((res) => res.blob())
                .then((blob) => {
                  // Add image to zip file
                  zip.file(`image_${index}.png`, blob);
                  resolve();
                });
            });
          }),
        );
      });

      Promise.all(promises).then(() => {
        // Generate and download zip file
        zip.generateAsync({ type: 'blob' }).then((blob) => {
          saveAs(blob, 'images.zip');
          setLoading(false);
        });
      });
    }, 500);
    return () => clearTimeout(timer);
  };

  // const onHandleArraySim = () => {
  //   // if (tableRef.current.length > 0) {
  //   //   return;
  //   // }
  //   let arrData = [];
  //   tableRef.current.forEach((ref, index) => {
  //     toPng(ref, { pixelRatio: 3, cacheBust: true })
  //       .then((dataUrl) => {
  //         fetch(dataUrl)
  //           .then((res) => res.blob())
  //           .then((blob) => {
  //             // Add image to zip file
  //             const image = URL.createObjectURL(blob);
  //             console.log(image);
  //             arrData.push(image);
  //           });
  //         console.log(arrData);
  //         renderDivsImg();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   });
  //   setDataBase64(arrData);
  // };

  return (
    <>
      <Box sx={{ width: '100%', mb: 1 }}>
        <Button
          variant="contained"
          size="small"
          color="success"
          onClick={() => {
            handleDownloadZip();
            setLoading(true);
          }}
          disabled={loading}
        >
          Tải tất cả Ảnh
        </Button>
      </Box>
      {loading && (
        <Box sx={{ width: '100%', mb: 1 }}>
          <LinearProgress />
        </Box>
      )}
      {arrImage.map((itemtable, indexTB) => (
        <div key={indexTB}>
          <Stack direction={'row'}>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => {
                handleExportImage(indexTB);
                setLoading(true);
              }}
              sx={{ mt: 1 }}
              disabled={loading}
            >
              Tải ảnh số {indexTB + 1} <ArrowDownwardIcon />
            </Button>
          </Stack>
          <div ref={(ref) => (tableRef.current[indexTB] = ref)} style={{ marginRight: 10 }}>
            {data?.title && (
              <div className={cx(`title-${colorTable}`)}>
                <b>{data?.title}</b>
              </div>
            )}

            <div style={{ display: 'flex' }}>
              {itemtable.map((item, index) => (
                <div style={{ marginLeft: 0 }} className={cx(`border-${colorTable}`)} key={index}>
                  <table className={cx(`table-${colorTable}`)}>
                    <thead>
                      <tr>
                        <th className={cx(`th-${colorTable}`)}>{data?.titleColsA}</th>
                        {!data?.isPrice && <th className={cx(`th-${colorTable}`)}>{data?.titleColsB}</th>}
                      </tr>
                    </thead>

                    <tbody>
                      {item.map((cell, colIndex) => (
                        <tr key={colIndex}>
                          {cell?.sim_show && <td>{cell?.sim_show}</td>}
                          {!data?.isPrice && cell?.price && <td>{numberFormatText(cell?.price)}</td>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>

            {data?.titleFooter && (
              <div className={cx(`title-footer-${colorTable}`)}>
                <b>{data?.titleFooter}</b>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default ImageExcel;
