import * as reqAuth from '../utils/requestAuthServer';
// gết danh sach
export const getDiscount = async (domain) => {
  try {
    const res = await reqAuth.getSim(`/getDiscount?u=${domain}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getDiscountID = async (id) => {
  try {
    const res = await reqAuth.getSim(`/getDiscountID?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addDiscountAll = async (data) => {
  try {
    const res = await reqAuth.postSim(`/addDiscountAll`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateDiscountID = async (data) => {
  try {
    const res = await reqAuth.postSim(`/updateDiscountID`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const deleDiscount = async (data) => {
  try {
    const res = await reqAuth.postSim(`/deleDiscount`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
