import { useEffect } from 'react';
import Iframe from 'react-iframe';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Stack } from '@mui/material';
function Photo({ sim = '', price = '', isQl = true }) {
  const navigate = useNavigate();
  const token = Cookies.get('token_sv');

  return (
    <>
      {isQl ? (
        <Stack direction={'column'} spacing={4}>
          <Button
            variant={'contained'}
            size="small"
            color="info"
            onClick={() => {
              navigate('/dashboard', { replace: true });
            }}
          >
            Quay lại trang chủ
          </Button>
          <Iframe
            src={`https://photo.khoso.com.vn/#/?fb=${token}`}
            position="absolute"
            width="100%"
            height="100%"
          ></Iframe>
        </Stack>
      ) : (
        <Iframe
          src={`https://photo.khoso.com.vn/#/?fb=${token}&sim=${sim}&price=${price}`}
          position="absolute"
          width="100%"
          height="100%"
        ></Iframe>
      )}
    </>
  );
}

export default Photo;
