import axios from 'axios';
import { API_URL_SIMSO, API_URL_TT } from '../config/config';

const requestAPISIM = axios.create({
  baseURL: API_URL_SIMSO,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getSim = async (path, options = {}) => {
  const response = await requestAPISIM.get(path, options);
  return response.data;
};

export const postSim = async (path, options = {}) => {
  const response = await requestAPISIM.post(path, options);
  return response.data;
};

// API NEWS

const requestAPISIMTT = axios.create({
  baseURL: API_URL_TT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getSimTT = async (path, options = {}) => {
  const response = await requestAPISIMTT.get(path, options);
  return response.data;
};

export const postSimTT = async (path, options = {}) => {
  const response = await requestAPISIMTT.post(path, options);
  return response.data;
};

export const putSimTT = async (path, options = {}) => {
  const response = await requestAPISIMTT.put(path, options);
  return response.data;
};

export const deleteSimTT = async (path, options = {}) => {
  const response = await requestAPISIMTT.delete(path, options);
  return response.data;
};
