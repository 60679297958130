export const LogicTranhSo = (phoneNumber, sotranh) => {
  const intersections = phoneNumber.split('').filter((e) => sotranh.indexOf(parseInt(e)) !== -1);
  const bolSim = intersections.length > 0 ? false : true;
  return bolSim;
};

export const LogicNoTranhSo = (phoneNumber, sotranh) => {
  const intersections = phoneNumber.split('').filter((e) => sotranh.indexOf(parseInt(e)) !== -1);
  const bolSim = intersections.length > 0 ? true : false;
  return bolSim;
};
