export const LogicCategoryShowName = (catesim) => {
  // console.log();

  if (catesim) {
    let arrNameCate = '';
    switch (catesim) {
      case 'than-tai':
        arrNameCate = 'Sim Thần Tài';
        break;
      case 'phat-loc':
        arrNameCate = 'Sim Phát Lộc';
        break;
      case 'loc-phat':
        arrNameCate = 'Sim Lộc Phát';
        break;
      case 'tho-dia':
        arrNameCate = 'Sim Ông Địa';
        break;
      case 'phong-thuy':
        arrNameCate = 'Sim Phong Thủy';
        break;
      case 'tam-hoa':
        arrNameCate = 'Sim Tam Hoa';
        break;
      case 'sanh-tien':
        arrNameCate = 'Sim Sảnh Tiến';
        break;
      case 'sanh-tien-3':
        arrNameCate = 'Sảnh Tiến 3';
        break;
      case 'sanh-tien-4':
        arrNameCate = 'Sảnh Tiến 4';
        break;
      case 'ngu-quy':
        arrNameCate = 'Sim Ngũ Quý';
        break;
      case 'tu-quy':
        arrNameCate = 'Sim Tứ Quý';
        break;
      case 'luc-quy':
        arrNameCate = 'Sim Lục Quý';
        break;
      case 'doc-la':
        arrNameCate = 'Sim Độc Lạ';
        break;
      case 'dac-biet':
        arrNameCate = 'Sim Đặc Biệt';
        break;
      case 'ganh-dao':
        arrNameCate = 'Sim Gánh Đảo';
        break;
      case 'lap':
        arrNameCate = 'Sim Lặp';
        break;
      case 'tam-hoa-kep':
        arrNameCate = 'Tam Hoa Kép';
        break;
      case 'taxi':
        arrNameCate = 'Sim Taxi';
        break;
      case 'taxi-2':
        arrNameCate = 'Sim Taxi 2';
        break;
      case 'tu-quy-giua':
        arrNameCate = 'Tứ Quý Giữa';
        break;
      case 'ngu-quy-giua':
        arrNameCate = 'Ngũ Quý Giữa';
        break;
      case 'nam-sinh':
        arrNameCate = 'Sim Năm Sinh';
        break;
      case 'taxi-tien':
        arrNameCate = 'Sim Taxi Tiến';
        break;
      case 'moc':
        arrNameCate = 'Sim Mệnh Mộc';
        break;
      case 'thuy':
        arrNameCate = 'Sim Mệnh Thủy';
        break;
      case 'tho':
        arrNameCate = 'Sim Mệnh Thổ';
        break;
      case 'hoa':
        arrNameCate = 'Sim Mệnh Hỏa';
        break;
      case 'kim':
        arrNameCate = 'Sim Mệnh Kim';
        break;

      default:
        arrNameCate = '';
        break;
    }

    return arrNameCate ? arrNameCate : ``;
  }
};
