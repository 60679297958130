export const LogicNetWork = (phoneNumber, networkDefault = '') => {
  if (networkDefault) {
    return networkDefault.trim();
  } else {
    if (phoneNumber) {
      let dauso = '';
      if (phoneNumber.trim().length === 9) {
        dauso = '0' + phoneNumber.slice(0, 2);
      } else {
        dauso = phoneNumber.slice(0, 3);
      }
      var netWork;
      switch (dauso) {
        case '086':
        case '096':
        case '097':
        case '098':
        case '032':
        case '033':
        case '034':
        case '035':
        case '036':
        case '037':
        case '038':
        case '039':
          netWork = 'Viettel';
          break;
        case '088':
        case '091':
        case '094':
        case '083':
        case '084':
        case '085':
        case '081':
        case '082':
          netWork = 'Vinaphone';
          break;
        case '089':
        case '090':
        case '093':
        case '070':
        case '079':
        case '077':
        case '076':
        case '078':
          netWork = 'Mobifone';
          break;
        case '092':
        case '052':
        case '056':
        case '058':
          netWork = 'Vietnamobile';
          break;
        case '099':
        case '059':
          netWork = 'Gmobile';
          break;
        case '087':
          netWork = 'Itelecom';
          break;
        case '055':
          netWork = 'Reddi';
          break;

        default:
          netWork = '';
          break;
      }
      const arrNetWork = netWork;
      return arrNetWork;
    }
  }
};
