import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames/bind';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';

import SimCardIcon from '@mui/icons-material/SimCard';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import LoyaltyTwoToneIcon from '@mui/icons-material/LoyaltyTwoTone';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowCircleUpSharpIcon from '@mui/icons-material/ArrowCircleUpSharp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoopIcon from '@mui/icons-material/Loop';
import ShareIcon from '@mui/icons-material/Share';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
import { ButtonUI, FrameInfoSim } from '../../../component/CustomerMUI';
import styles from '../Home.module.scss';
import images from '../../../assets/images';
import ThemeLoad from '../../../component/CustomerMUI/ThemeLoad/ThemeLoad';
import DetailHome from '../DetailHome/DetailHome';
import { LogicSearch } from '../LogicSearch/LogicSearch';
import { DataSim } from '../../../utils/DataSim';
import auth from '../../../config/auth';
import { bgrCateSim } from '../../../utils/bgrCateSim';
import {
  getMultipleRandom,
  mapRecords,
  numberFormatText,
  numberFormatDeleteDots,
  totalDiscount,
  numberFormat,
} from '../../../utils/helper';
import {
  Avatar,
  Backdrop,
  Badge,
  Checkbox,
  CircularProgress,
  Dialog,
  Drawer,
  Fab,
  FormControl,
  FormControlLabel,
  Hidden,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  NativeSelect,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LogicCategoryShowName } from '../LogicSearch/LogicCategoryShowName';
import * as listSimService from '../../../services/listSim';
import { Box } from '@mui/system';
import { LogicNetWork } from '../LogicSearch/LogicNetWork';
const cx = classNames.bind(styles);
const tablePriceGDH = [
  {
    priceStart: 0,
    priceEnd: 2499999,
    cafe: 200000,
  },
  {
    priceStart: 2500000,
    priceEnd: 3999999,
    cafe: 250000,
  },
  {
    priceStart: 4000000,
    priceEnd: 6999999,
    cafe: 300000,
  },
  {
    priceStart: 7000000,
    priceEnd: 9999999,
    cafe: 350000,
  },
  {
    priceStart: 10000000,
    priceEnd: 19999999,
    cafe: 500000,
  },
  {
    priceStart: 20000000,
    priceEnd: 39999999,
    cafe: 800000,
  },
  {
    priceStart: 40000000,
    priceEnd: 100000000,
    cafe: 1000000,
  },
];
const homeNetWork = [
  {
    titleNetWork: 'Mobifone',
    srcNetwork: images.logo_mb,
    width: '60px',
  },
  {
    titleNetWork: 'Viettel',
    srcNetwork: images.logo_vt,
    width: '60px',
  },
  {
    titleNetWork: 'Vinaphone',
    srcNetwork: images.logo_vn,
    width: '60px',
  },
  {
    titleNetWork: 'Itelecom',
    srcNetwork: images.logo_itel,
    width: '40px',
  },
  {
    titleNetWork: 'Vietnamobile',
    srcNetwork: images.logo_vnmb,
    width: '60px',
  },
];

const priceRange = [
  {
    id: 2,
    titlePrice: 'Dưới 1 triệu',
  },
  {
    id: 3,
    titlePrice: '1 - 2 triệu',
  },
  {
    id: 4,
    titlePrice: '2 - 3 triệu',
  },
  {
    id: 5,
    titlePrice: '3 - 5 triệu',
  },
  {
    id: 6,
    titlePrice: '5 - 10 triệu',
  },
  {
    id: 7,
    titlePrice: '10 - 20 triệu',
  },
  {
    id: 8,
    titlePrice: '20 - 50 triệu',
  },
  {
    id: 9,
    titlePrice: 'Trên 50 triệu',
  },
];
const searchDauSoSim = [
  {
    dauso: '09*',
    titleDauSo: '09',
  },
  {
    dauso: '08*',
    titleDauSo: '08',
  },
  {
    dauso: '03*',
    titleDauSo: '03',
  },
  {
    dauso: '07*',
    titleDauSo: '07',
  },
  {
    dauso: '05*',
    titleDauSo: '05',
  },
];

const arrayFormatCate = [
  {
    title: 'AAAA',
    link: 'dd-aaaa',
  },
  {
    title: 'AB.BA',
    link: 'dd-abba',
  },
  {
    title: 'ABA.ACA',
    link: 'dd-abaaca',
  },
  {
    title: 'AB.AB.AC',
    link: 'dd-ababac',
  },
  {
    title: 'AB.AB.AB',
    link: 'dd-ababab',
  },
  {
    title: 'AB.AB.AA',
    link: 'dd-ababaa',
  },
  {
    title: 'AB.AC.AD',
    link: 'dd-abacad',
  },
  {
    title: 'AA.BC.AA',
    link: 'dd-aabcaa',
  },
  {
    title: 'AB.BA.BA',
    link: 'dd-abbaba',
  },
  {
    title: 'AA.BA.BA',
    link: 'dd-aababa',
  },
  {
    title: 'AB.AC.AA',
    link: 'dd-abacaa',
  },
  {
    title: 'ABC.ABC',
    link: 'dd-abcabc',
  },
  {
    title: 'AAB.AAB',
    link: 'dd-aabaab',
  },
  {
    title: 'ABC.ABA',
    link: 'dd-abcaba',
  },
  {
    title: 'AAB.ABB',
    link: 'dd-aababb',
  },
  {
    title: 'ABA.ABC',
    link: 'dd-abaabc',
  },
  {
    title: 'AAA.BBB',
    link: 'dd-aaabbb',
  },
  {
    title: 'AAB.AAA',
    link: 'dd-aabaaa',
  },
  {
    title: 'ABA.ABB',
    link: 'dd-abaabb',
  },
  {
    title: 'ABA.AAB',
    link: 'dd-abaaab',
  },
  {
    title: 'AAC.BBC',
    link: 'dd-aacbbc',
  },
  {
    title: 'AAB.ABB',
    link: 'dd-aababb',
  },
  {
    title: 'AAB.BBA',
    link: 'dd-aabbba',
  },
  {
    title: 'ABB.CDD',
    link: 'dd-abbcdd',
  },
  {
    title: 'ABA.ABA',
    link: 'dd-abaaba',
  },
  {
    title: 'BAA.BAA',
    link: 'dd-baabaa',
  },
  {
    title: 'ABC.ACB',
    link: 'dd-abcacb',
  },
  {
    title: 'AAAAB',
    link: 'dd-aaaab',
  },
  {
    title: 'AAAABC',
    link: 'dd-aaaabc',
  },
  {
    title: 'AAAAAB',
    link: 'dd-aaaaab',
  },
  {
    title: 'AAAAABC',
    link: 'dd-aaaaabc',
  },
  {
    title: 'ABCD.ABCD',
    link: 'dd-abcdabcd',
  },
];

const searchTranhso = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const favourite = (number) => {
  const last4Str = String(number).slice(-4);
  const last4Num = parseInt(last4Str);
  const tailnumbers = last4Num / 80;
  const surplus = getDecimalPart(tailnumbers);
  const totalHungCat = '0.' + surplus;
  const total = Math.round(parseFloat(totalHungCat) * 80);
  if (DataSim[total]['mucdo'] === 'Đại Cát' || DataSim[total]['mucdo'] === 'Cát') {
    return DataSim[total];
  } else {
    return '';
  }
};

const getDecimalPart = (num) => {
  // console.log(Number.isInteger(num), num);
  if (Number.isInteger(num)) {
    return 0;
  }

  const decimalStr = num.toString().split('.')[1];
  return parseInt(decimalStr);
};

function HomeDaily() {
  const elementRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const hostname = window.location.hostname;
  const queryParams = new URLSearchParams(window.location.search);
  const sx = queryParams.get('sx');
  const gia = queryParams.get('gia');
  const ts = queryParams.get('ts');
  const ists = queryParams.get('ists');
  const timsim = queryParams.get('timsim');
  const mang = queryParams.get('mang');
  const loaisim = queryParams.get('loaisim');
  const tongsodau = queryParams.get('tongsodau');
  const tongsocuoi = queryParams.get('tongsocuoi');
  // console.log(timsim);

  const [simResult, setSimResult] = useState([]);
  const [simDataSort, setSimDataSort] = useState([]);
  const [simInfinteResult, setSimInfinteResult] = useState([]);
  const [menuSimResult, setMenuSimResult] = useState([]);
  const [data, setData] = useState();
  const [page, setPage] = useState(30);
  const [loadinglist, setLoadingList] = useState(true);
  const [isSimDetail, setIsSimDetail] = useState(false);
  const [loadingTheme, setLoadingTheme] = useState(true);
  const [loading, setLoading] = useState(true);
  const [actionBtnSort, setActionBtnSort] = useState(1);
  const [actionRangePrice, setActionRangePrice] = useState(1);
  const [actionNetWork, setActionNetWork] = useState('');
  const [actionMenuCate, setActionMenuCate] = useState(0);
  const [searchDauSim, setSearchDauSim] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [textSearchChange, setTextSearchChange] = useState('');
  const [actionTranhso, setActionTranhso] = useState([]);
  const [isSttTranhso, setIsSttTranhso] = useState(1); // 1 - tranh 6 số
  const [actionSimStart, setActionSimStart] = useState({ sodau: '', totalSoDau: '' });
  const [actionSimEnd, setActionSimEnd] = useState({ socuoi: '', totalSoCuoi: '' });
  const contextSearch = useContext(ThemeContext);
  // let textSearch = contextSearch.textSearch;
  let isBoLoc = contextSearch.boloc;
  const [isShowGDH, setIsShowGDH] = useState(false);

  const [open, setOpen] = useState(false);
  const [detailSim, setDetailSim] = useState({});
  const [pos, setPos] = useState(false);
  const [checkCoppy, setCheckCoppy] = useState({});
  const [isCopied, setIsCopied] = useState(false);
  const [textCopied, setTextCopied] = useState('');
  const [textCopiedSim, setTextCopiedSim] = useState('');
  const [textCopiedSimAndPrice, setTextCopiedSimAndPrice] = useState('');
  const [textCopiedChot, setTextCopiedChot] = useState('');
  const [layoutSim, setLayoutSim] = useState(auth[hostname]['layoutSim']);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenDrawerCate, setIsOpenDrawerCate] = useState({ isCate: false, cateNumber: 0 });
  const [isCopyLink, setIsCopyLink] = useState('');
  const [iconCopy, setIconCopy] = useState('🍂');
  const [isShowCopyLink, setIsShowCopyLink] = useState(false);
  const [simRandomLucky, setSimRandomLucky] = useState({});
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'));
  const detailSimSo = JSON.parse(localStorage.getItem('simdetail'));

  const fetchApiSim = async () => {};
  const fetchApiSimCache = async () => {};
  useEffect(() => {
    // setData(useGoogleSheets);
    fetchApiSim();
  }, []);

  const getParamsSim = () => {
    //sap xep
    if (sx) {
      setActionBtnSort(parseInt(sx));
    }
    //gia
    if (gia || params.range) {
      const price = gia || params.range;
      setActionRangePrice(parseInt(price));
    }
    // tranhso
    if (ts || params.tranhso) {
      const arrTS = (ts && ts.split(',')) || (params.tranhso && params.tranhso.split(','));
      const arrNumberTS = arrTS.map(Number);
      setActionTranhso(arrNumberTS);
    }
    // 1-6 2-tatca
    if (ists || params.istranhso) {
      const isTS = ists || params.istranhso;
      setIsSttTranhso(isTS);
    }

    if (timsim || params.search) {
      const keySearch = timsim || params.search;
      // contextSearch.toggleDataSim(keySearch);
      setTextSearch(keySearch);
      setTextSearchChange(keySearch);
    }

    if (mang || params.netWork) {
      const netWorkSim = mang || params.netWork;
      setActionNetWork(netWorkSim);
    }

    if (loaisim || params.cate) {
      const cateSim = loaisim || params.cate;
      setActionMenuCate(cateSim);
    }

    if (tongsodau || params.tongdau) {
      const arrSimStart = (tongsodau && tongsodau.split(',')) || (params.tongdau && params.tongdau.split(','));
      setActionSimStart({ sodau: arrSimStart[0], totalSoDau: arrSimStart[1] });
    }

    if (tongsocuoi || params.tongcuoi) {
      const arrSimEnd = (tongsocuoi && tongsocuoi.split(',')) || (params.tongcuoi && params.tongcuoi.split(','));
      setActionSimEnd({ socuoi: arrSimEnd[0], totalSoCuoi: arrSimEnd[1] });
    }
  };
  const fetchApiListSim = () => {
    if (data) {
      const headerData = data[0]['values'][0];
      const record = data[0]['values'];
      const mapRecord = mapRecords(record, headerData);
      const result = getMultipleRandom(mapRecord);
      const randomSimLucKy = getMultipleRandom(result);
      //   console.log(randomSimLucKy);
      setSimDataSort(result);
      setSimResult(result);
      setSimRandomLucky(randomSimLucKy[0]);
      getParamsSim();
      const pageResult = result.slice(0, 30);
      setSimInfinteResult(pageResult);
      setLoadingList(false);
      setLoadingTheme(false);
      setLoading(false);

      if (window.location.hash.replace(/[^\w\s]/gi, '') === `sim${params?.sim}`) {
        // contextSearch.toggleDataSim(params?.sim);
        setTextSearch(params?.sim);
        setTextSearchChange(params?.sim);
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  };
  //Api list data
  useEffect(() => {
    if (auth[hostname]['authGuard'] === 1) {
      const items = JSON.parse(localStorage.getItem('items'));
      if (data) {
        const headerData = data[2]['values'][0];
        const record = data[2]['values'];
        const result = mapRecords(record, headerData);
        if (items) {
          const userName = result[0]['user'];
          const passWord = result[0]['pass'];
          if (userName === items.user && passWord === items.pass) {
            fetchApiListSim();
          } else {
            navigate('/login', { replace: true });
          }
        } else {
          navigate('/login', { replace: true });
        }
      }
      // navigate('/login', { replace: true });
    } else {
      fetchApiListSim();
    }
  }, [loading]);
  //Api menu data
  useEffect(() => {
    const fetchApiMenu = () => {
      const headerData = data[1]['values'][0];
      const record = data[1]['values'];
      const result = mapRecords(record, headerData);
      setMenuSimResult(result);
    };
    if (data) {
      fetchApiMenu();
    }
  }, [loading]);

  // click boloc tư header
  useEffect(() => {
    toggleDrawer(isBoLoc);
  }, [isBoLoc]);

  const handleDetailSim = (netWork, subCard, titleSim, noteSims, price) => {
    if (layoutSim === 1) {
      return [netWork && `Mạng: <b>${netWork}<b/>`, price && `Sale: ${numberFormatText(price)}`];
    } else {
      return [
        netWork && `Mạng: <b>${netWork}<b/>`,
        `Sim: ${
          LogicCategoryShowName(titleSim, simInfinteResult, menuSimResult).length > 0
            ? LogicCategoryShowName(titleSim, simInfinteResult, menuSimResult)[0]
            : 'Dễ Nhớ'
        }`,
        subCard && `Thuê bao: ${subCard}`,
        `Tình trạng: sim còn`,
      ];
    }
  };
  const fetchNextPage = () => {
    setPage(page + 30);
  };

  // console.log('Home123', textSearch);

  // sort data network
  useEffect(() => {
    LogicSearch(
      simResult,
      setSimDataSort,
      actionNetWork,
      actionBtnSort,
      actionRangePrice,
      actionMenuCate,
      textSearch,
      actionTranhso,
      isSttTranhso,
      actionSimStart,
      actionSimEnd,
    );
    setPage(30);
    handleTop();
    setCheckCoppy([]);
    if (textSearch?.length === 0) {
      setSearchDauSim('');
    }
    document.title = auth[hostname]['TITLE_HOME'];
  }, [
    actionNetWork,
    actionBtnSort,
    actionRangePrice,
    actionMenuCate,
    textSearch,
    actionTranhso,
    actionSimStart,
    actionSimEnd,
  ]);

  useEffect(() => {
    const handleScroll = async () => {
      let fetching = false;
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 300;

      if (!fetching && bottom) {
        setLoadingList(true);
        fetching = true;
        if (simResult.length >= simInfinteResult.length) await fetchNextPage();
        fetching = false;
      }

      if (window.scrollY > 300) {
        if (!pos) setPos(true);
      } else {
        if (pos) setPos(false);
      }
    };

    let pageResult = simDataSort.slice(0, page);

    setSimInfinteResult([...pageResult]);
    setLoadingList(false);
    // window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
    // Remove the event listener
    // return () => {
    //   elementRef.current.removeEventListener('scroll', handleScroll);
    // };
    if (simDataSort?.length === 1) {
      localStorage.setItem('simdetail', JSON.stringify(simDataSort[0]));
    } else {
      if (window.location.hash.replace(/[^\w\s]/gi, '') === `sim${detailSimSo?.simSearch}`) {
        localStorage.setItem('simdetail', JSON.stringify(detailSimSo));
      } else {
        localStorage.removeItem('simdetail');
      }
    }
  }, [page, simDataSort]);

  const handleClearSim = () => {
    setActionMenuCate(0);
    setActionNetWork('');
    setActionRangePrice(1);
    setActionTranhso([]);
    setActionSimStart({ sodau: '', totalSoDau: '' });
    setActionSimEnd({ socuoi: '', totalSoCuoi: '' });
    setSearchDauSim('');
    setTextSearch('');
    setTextSearchChange('');
    contextSearch.toggleBoLoc(false);
  };

  //click open modal
  const handleModalOpen = (sim) => {
    const favoriteSim = favourite(sim['simSearch']) && favourite(sim['simSearch'])['ynghia'];

    const cateSim =
      LogicCategoryShowName(sim['simSearch'], simInfinteResult, menuSimResult).length > 0
        ? LogicCategoryShowName(sim['simSearch'], simInfinteResult, menuSimResult)[0]
        : 'Dễ Nhớ';
    const meansSim = {
      means: favoriteSim,
      cateSim: cateSim,
      netWork: LogicNetWork(sim?.simSearch, sim?.netWork),
    };
    const simDetailDailog = { ...sim, ...meansSim, ...params };
    // console.log(simDetailDailog);
    // setOpen(true);
    localStorage.setItem('simdetail', JSON.stringify(simDetailDailog));
    setDetailSim(simDetailDailog);
    navigate(`/sim-${sim.simSearch}`, { replace: true });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTextSearch('');
    setTextSearchChange('');
    // contextSearch.toggleDataSim('');
  };

  // handle scroll to top
  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setPos(false);
  };
  const handleCoppyIcon = (e) => {
    handleTextCopiedSimAndPrice(checkCoppy, e.target.value);
    handleTextCopiedSim(checkCoppy, e.target.value);
  };

  // hanlde check coppy
  const handleCoppyAll = (e) => {
    let arrsim = {};
    if (e.target.checked) {
      simInfinteResult?.data?.map((sim, index) => {
        arrsim[index] = sim;
      });
      setCheckCoppy(arrsim);
      handleTextCopiedSimAndPrice(arrsim);
      handleTextCopied(arrsim);
      handleTextCopiedSim(arrsim);
    } else {
      setCheckCoppy({});
    }
  };

  const handleCoppy = (index, sim) => {
    let state = { ...checkCoppy, [index]: sim };
    setCheckCoppy(state);
    handleTextCopiedSimAndPrice(state);
    handleTextCopied(state);
    handleTextCopiedSim(state);
  };
  const handleDeleteCoppy = (index) => {
    let state = { ...checkCoppy };
    delete state[index];
    setCheckCoppy(state);
    handleTextCopiedSimAndPrice(state);
    handleTextCopied(state);
    handleTextCopiedSim(state);
  };
  const handleTextCopiedSim = (state, iconcopy = iconCopy) => {
    let listSim = Object.values(state);
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `${iconcopy?.trim()}${sim.sim_show}`;

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      setTextCopiedSim(sims);
    }
  };

  const handleTextCopiedSimAndPrice = (state, iconcopy = iconCopy) => {
    let listSim = Object.values(state);
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `${iconcopy?.trim()}${sim.sim_show} = ${numberFormatText(sim.price)}`;

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      setTextCopiedSimAndPrice(sims);
    }
  };

  const handleTextCopied = (state) => {
    let listSim = Object.values(state);
    var simSo = [];
    var simPrice = 0;
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `- Sim: ${sim.sim_show}\n💰 Giá sale: ${numberFormatText(sim.price)} \n👉 Mạng: ${
        sim.nhamang
      } \n- Thuê bao: ${sim.thuebao}\n- Tình trạng: sim còn\n ${sim['note_sim'] ? '- ' + sim['note_sim'] : ''} \n ${
        listSim.length > 1 ? `-----------` : ''
      } `;
      simSo.push(`- Sim mua: ${sim.sim_show}\n👉 Mạng: ${sim.nhamang}\n ${listSim.length > 1 ? `-----------` : ''}`);
      simPrice = numberFormatDeleteDots(simPrice) + numberFormatDeleteDots(sim.price);

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      const simso = simSo.toString().replace(re, '\n');
      let textSimCopied = `THÔNG TIN CHI TIẾT SIM\n${sims}`;
      let textSimCopiedChot = `✈️𝑻𝒉𝒐̂𝒏𝒈 𝒕𝒊𝒏 𝑪𝒉𝒐̂́𝒕 𝑺𝒊𝒎!!!\n- Tên KH:\n${simso}\n- Liên hệ KH:\n- Địa chỉ Khách: \n ☛ Tổng thu KH : ${numberFormatText(
        simPrice.toString(),
      )}`;
      setTextCopied(textSimCopied);
      setTextCopiedChot(textSimCopiedChot);
    }
  };

  const submitBtnCopied = () => {
    setCheckCoppy({});
    setTextCopied('');
    setTextCopiedSimAndPrice('');
    setTextCopiedChot('');
  };

  const handleCopyLink = () => {
    if (
      actionNetWork &&
      !actionMenuCate &&
      actionRangePrice === 1 &&
      actionBtnSort === 1 &&
      actionTranhso.length === 0 &&
      !textSearch &&
      !actionSimStart['sodau'] &&
      !actionSimEnd['socuoi']
    ) {
      const textCopy = `${auth[hostname]['BASE_URL']}/Mang-${actionNetWork}`;
      setIsCopyLink(textCopy);
    } else if (
      !actionNetWork &&
      actionMenuCate &&
      actionRangePrice === 1 &&
      actionBtnSort === 1 &&
      actionTranhso.length === 0 &&
      !textSearch &&
      !actionSimStart['sodau'] &&
      !actionSimEnd['socuoi']
    ) {
      const textCopy = `${auth[hostname]['BASE_URL']}/${actionMenuCate}`;
      setIsCopyLink(textCopy);
    } else if (
      !actionNetWork &&
      !actionMenuCate &&
      actionBtnSort === 1 &&
      actionRangePrice !== 1 &&
      actionTranhso.length === 0 &&
      !textSearch &&
      !actionSimStart['sodau'] &&
      !actionSimEnd['socuoi']
    ) {
      const textCopy = `${auth[hostname]['BASE_URL']}/sim-theo-gia-${actionRangePrice}`;
      setIsCopyLink(textCopy);
    } else if (
      !actionNetWork &&
      !actionMenuCate &&
      actionRangePrice === 1 &&
      actionBtnSort === 1 &&
      actionTranhso.length > 0 &&
      !textSearch &&
      !actionSimStart['sodau'] &&
      !actionSimEnd['socuoi']
    ) {
      const textCopy = `${auth[hostname]['BASE_URL']}/ts-${actionTranhso}-${isSttTranhso}`;
      setIsCopyLink(textCopy);
    } else if (
      !actionNetWork &&
      !actionMenuCate &&
      actionBtnSort === 1 &&
      actionRangePrice === 1 &&
      actionTranhso.length === 0 &&
      textSearch &&
      !actionSimStart['sodau'] &&
      !actionSimEnd['socuoi']
    ) {
      const textCopy = `${auth[hostname]['BASE_URL']}/tim-sim-${textSearch}`;
      setIsCopyLink(textCopy);
    } else if (
      !actionNetWork &&
      !actionMenuCate &&
      actionBtnSort === 1 &&
      actionRangePrice === 1 &&
      actionTranhso.length === 0 &&
      !textSearch &&
      (actionSimStart['sodau'] || actionSimEnd['socuoi'])
    ) {
      const textCopy = `${auth[hostname]['BASE_URL']}/tim-tong-${actionSimStart || 0}-${actionSimEnd || 0}`;
      setIsCopyLink(textCopy);
    } else {
      const textCoppy = `https://${hostname}?sx=${actionBtnSort}${
        actionRangePrice !== 1 ? '&gia=' + actionRangePrice : ''
      }${actionTranhso.length > 0 ? '&ts=' + actionTranhso : ''}${isSttTranhso !== 1 ? '&ists=' + isSttTranhso : ''}${
        textSearch && '&timsim=' + textSearch
      }${actionNetWork && '&mang=' + actionNetWork}${actionMenuCate !== 0 ? '&loaisim=' + actionMenuCate : ''}${
        actionSimStart.sodau && '&tongsodau=' + (actionSimStart.sodau + ',' + actionSimStart.totalSoDau)
      }${actionSimEnd.socuoi && '&tongsocuoi=' + (actionSimEnd.socuoi + ',' + actionSimEnd.totalSoCuoi)}`;
      setIsCopyLink(textCoppy);
    }
  };

  const handleChangeSelectPrice = (event) => {
    setActionRangePrice(event.target.value);
  };

  const handleChangeSelectDauSo = (event) => {
    setSearchDauSim(event.target.value);
    setTextSearch(event.target.value);
    setTextSearchChange(event.target.value);
    // contextSearch.toggleDataSim(event.target.value);
  };
  const handleChangeSelectDauSoDrawer = (value) => {
    setSearchDauSim(value);
    setTextSearch(value);
    setTextSearchChange(value);
    // contextSearch.toggleDataSim(value);
  };
  const handleCategory = () => {
    const menuSim = menuSimResult.filter((dataCate) => {
      return dataCate.link?.includes(actionMenuCate);
    });
    return menuSim.length > 0 ? menuSim[0]['title'] : 'SIM SỐ ĐẸP';
  };

  const handleLayoutSim = (e) => {
    setLayoutSim(e.target.value);
  };

  //thanh tim kiem nang cao
  const toggleDrawer = (open) => {
    setIsOpenDrawer(open);
    if (!open) {
      contextSearch.toggleBoLoc(false);
    }
  };
  const toggleDrawerCate = (open) => {
    setIsOpenDrawerCate({ ...isOpenDrawerCate, isCate: open });
  };

  // handle tranh so
  const handleTranhSo = (val) => {
    const i = actionTranhso.indexOf(val);
    if (i > -1) {
      const checkDelSo = actionTranhso.filter((item) => item !== val);
      setActionTranhso(checkDelSo);
    } else {
      const stateTS = [...actionTranhso, val];
      setActionTranhso(stateTS);
    }
  };
  const handleSttTranhso = (e) => {
    setIsSttTranhso(e.target.value);
    setActionTranhso([]);
  };

  // handle cắt chuỗi loại sim
  const handleCutCateTitle = (str) => {
    const array = str.split(' ');
    let title = '';
    if (array.length < 4) {
      title = `${array.length > 0 ? array[0] : ''} ${array.length >= 2 ? array[1] : ''} ${
        array.length === 3 ? array[2] : ''
      }`;
    } else {
      title = `${array[0]} ${array[1]} ${array[2]}...`;
    }
    return title;
  };

  const handleRandomSimLucky = () => {
    const randomSimLucKy = getMultipleRandom(simInfinteResult);
    setSimRandomLucky(randomSimLucKy[0]);
  };

  const handleChangeSearchSim = (e) => {
    setTextSearchChange(e.target.value);
  };

  const onClickSearchSubmit = () => {
    setTextSearch(textSearchChange);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      setTextSearch(e.target.value);
    }
  };
  return (
    <>
      {open ? (
        <DetailHome onClose={handleClose} />
      ) : (
        <>
          <Container sx={{ p: 0, pb: '50px' }}>
            <Fragment>
              <Drawer
                anchor={'right'}
                open={isOpenDrawer}
                onClose={() => toggleDrawer(false)}
                sx={{
                  '& .MuiPaper-root': {
                    borderTopLeftRadius: '10px',
                  },
                }}
              >
                <label className={cx('title-sort-sim')}>
                  <FilterAltRoundedIcon />
                  <b>BỘ LỌC SIM</b>
                </label>
                <Box sx={{ width: 250, p: 1 }}>
                  <Stack direction="column" spacing={1} marginBottom="10px">
                    <label>
                      <b>DANH MỤC SIM</b>
                    </label>
                    <FormControl size="small">
                      <Select
                        sx={{ borderRadius: 2.5, fontSize: '0.8rem', ':active': '#ff00004a' }}
                        value={actionMenuCate}
                      >
                        <MenuItem onClick={() => setActionMenuCate(0)} value="0">
                          Loại sim
                        </MenuItem>
                        {menuSimResult.map((menu) => (
                          <MenuItem key={menu.link} value={menu.link} onClick={() => setActionMenuCate(menu.link)}>
                            {menu.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1} marginBottom="10px">
                    <label>
                      <b>CHỌN MẠNG</b>
                    </label>
                    <Box spacing={1}>
                      {homeNetWork.map((network, index) => (
                        <ButtonUI
                          key={index}
                          borderRadius={6}
                          bgrColorBtn={actionNetWork === network.titleNetWork ? '#ff000026' : '#ffffff'}
                          borderBtn={actionNetWork === network.titleNetWork ? '#ff0000' : '#44444426'}
                          onClick={() => setActionNetWork(network.titleNetWork)}
                          sx={{ mr: 0.5, mb: 0.5 }}
                        >
                          {network.titleNetWork}
                        </ButtonUI>
                      ))}
                    </Box>
                  </Stack>

                  <Stack direction="column" spacing={1} marginBottom="10px">
                    <label>
                      <b>CHỌN KHOẢNG GIÁ</b>
                    </label>
                    <Box spacing={1}>
                      {priceRange.map((price, index) => (
                        <ButtonUI
                          key={index}
                          borderRadius={6}
                          bgrColorBtn={actionRangePrice === price.id ? '#ff000026' : '#ffffff'}
                          borderBtn={actionRangePrice === price.id ? '#ff0000' : '#44444426'}
                          onClick={() => setActionRangePrice(price.id)}
                          sx={{ mr: 0.5, mb: 0.5 }}
                        >
                          {price.titlePrice}
                        </ButtonUI>
                      ))}
                    </Box>
                  </Stack>

                  <Stack direction="column" spacing={1} marginBottom="10px">
                    <label>
                      <b>ĐẦU SỐ</b>
                    </label>
                    <Box spacing={1}>
                      {searchDauSoSim.map((dauso, index) => (
                        <ButtonUI
                          key={index}
                          borderRadius={6}
                          bgrColorBtn={searchDauSim === dauso.dauso ? '#ff000026' : '#ffffff'}
                          borderBtn={searchDauSim === dauso.dauso ? '#ff0000' : '#44444426'}
                          onClick={() => handleChangeSelectDauSoDrawer(dauso.dauso)}
                          sx={{ mr: 0.5, mb: 0.5 }}
                        >
                          {dauso.titleDauSo}
                        </ButtonUI>
                      ))}
                    </Box>
                  </Stack>

                  <Stack direction="column" spacing={1} marginBottom="10px">
                    <label>
                      <b>TRÁNH SỐ</b>
                    </label>
                    <Stack direction="row" spacing={1}>
                      <RadioGroup value={isSttTranhso} onChange={handleSttTranhso} row>
                        <FormControlLabel
                          value="1"
                          control={<Radio size="small" />}
                          label={<Typography sx={{ fontSize: 14 }}>Tránh 6 sô</Typography>}
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio size="small" />}
                          label={<Typography sx={{ fontSize: 14 }}>Tránh tất cả</Typography>}
                        />
                      </RadioGroup>
                    </Stack>
                    <Box spacing={1}>
                      {searchTranhso.map((sim, index) => (
                        <ButtonUI
                          key={index}
                          borderRadius={6}
                          bgrColorBtn={actionTranhso.indexOf(sim) > -1 ? '#ff000026' : '#ffffff'}
                          borderBtn={actionTranhso.indexOf(sim) > -1 ? '#ff0000' : '#44444426'}
                          onClick={() => handleTranhSo(sim)}
                          sx={{ mr: 0.5, mb: 0.5 }}
                        >
                          {sim}
                        </ButtonUI>
                      ))}
                    </Box>
                  </Stack>

                  <Stack direction="column" spacing={1} marginBottom="10px">
                    <label>
                      <b>TỔNG SỐ ĐẦU - SỐ CUỐI</b>
                    </label>
                    <Stack direction="row" spacing={1} marginBottom="10px" alignItems={'center'}>
                      <label>Số đầu:</label>
                      <TextField
                        label="vd: 5"
                        variant="outlined"
                        size="small"
                        value={actionSimStart.sodau}
                        onChange={(e) => setActionSimStart({ ...actionSimStart, sodau: e.target.value })}
                      />
                      <label>=</label>
                      <TextField
                        label="vd: 23"
                        variant="outlined"
                        size="small"
                        value={actionSimStart.totalSoDau}
                        onChange={(e) => setActionSimStart({ ...actionSimStart, totalSoDau: e.target.value })}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1} marginBottom="10px" alignItems={'center'}>
                      <label>Số cuối:</label>
                      <TextField
                        label="vd: 5"
                        variant="outlined"
                        size="small"
                        value={actionSimEnd.socuoi}
                        onChange={(e) => setActionSimEnd({ ...actionSimEnd, socuoi: e.target.value })}
                      />
                      <label>=</label>
                      <TextField
                        label="vd: 36"
                        variant="outlined"
                        size="small"
                        value={actionSimEnd.totalSoCuoi}
                        onChange={(e) => setActionSimEnd({ ...actionSimEnd, totalSoCuoi: e.target.value })}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={1} marginBottom="10px" justifyContent={'flex-end'}>
                    <ButtonUI
                      color="error"
                      borderBtn="#ff0000"
                      borderRadius={4}
                      sx={{ mr: 0.5, mb: 0.5 }}
                      onClick={() => toggleDrawer(false)}
                    >
                      XEM KẾT QUẢ
                    </ButtonUI>
                  </Stack>
                </Box>
              </Drawer>
            </Fragment>

            <Fragment>
              <Drawer
                anchor={'bottom'}
                open={isOpenDrawerCate.isCate}
                onClose={() => toggleDrawerCate(false)}
                sx={{
                  '& .MuiPaper-root': {
                    borderTopLeftRadius: '10px',
                  },
                }}
              >
                {isOpenDrawerCate.cateNumber === 1 && (
                  <label className={cx('title-sort-sim')}>
                    <DashboardCustomizeOutlinedIcon />
                    <b>THỂ LOẠI SIM</b>
                  </label>
                )}
                {isOpenDrawerCate.cateNumber === 2 && (
                  <label className={cx('title-sort-sim')}>
                    <ClassOutlinedIcon />
                    <b>SIM ĐỊNH DẠNG</b>
                  </label>
                )}
                <Box sx={{ p: 1 }}>
                  <Stack direction="column" spacing={1} marginBottom="10px">
                    <label>
                      <b>{isOpenDrawerCate.cateNumber === 1 && `CHỌN SỐ THEO THỂ LOẠI`}</b>
                      <b>{isOpenDrawerCate.cateNumber === 2 && `CHỌN SỐ THEO ĐỊNH DẠNG`}</b>
                    </label>
                    {isOpenDrawerCate.cateNumber === 1 && (
                      <Box>
                        {menuSimResult.map((menu, index) => (
                          <ButtonUI
                            key={index}
                            borderRadius={6}
                            bgrColorBtn={actionMenuCate === menu.link ? '#ff000026' : '#ffffff'}
                            borderBtn={actionMenuCate === menu.link ? '#ff0000' : '#44444426'}
                            onClick={() => {
                              setActionMenuCate(actionMenuCate === menu.link ? 0 : menu.link);
                              toggleDrawerCate(false);
                            }}
                            sx={{ mr: 0.5, mb: 0.5 }}
                          >
                            {menu.title}
                          </ButtonUI>
                        ))}
                      </Box>
                    )}
                    {isOpenDrawerCate.cateNumber === 2 && (
                      <Box>
                        {arrayFormatCate.map((menu, index) => (
                          <ButtonUI
                            key={index}
                            borderRadius={6}
                            bgrColorBtn={actionMenuCate === menu.link ? '#ff000026' : '#ffffff'}
                            borderBtn={actionMenuCate === menu.link ? '#ff0000' : '#44444426'}
                            onClick={() => {
                              setActionMenuCate(actionMenuCate === menu.link ? 0 : menu.link);
                              toggleDrawerCate(false);
                            }}
                            sx={{ mr: 0.5, mb: 0.5 }}
                          >
                            {menu.title}
                          </ButtonUI>
                        ))}
                      </Box>
                    )}
                  </Stack>
                </Box>
              </Drawer>
            </Fragment>

            <Grid container spacing={0.5}>
              <Hidden only={['sm', 'xs']}>
                <Grid item md={3}>
                  <div className={cx(`header-title-grey`)}>
                    <div className={cx(`header-title-item-grey`)}>
                      <SimCardIcon sx={{ pr: '5px' }} fontSize="small" /> <b> SIM THEO GIÁ</b>
                    </div>
                    {priceRange.map((price, index) => (
                      <div
                        key={index}
                        className={cx('detail-item-sim', `${actionRangePrice === price.id ? 'bgr-active' : ''}`)}
                        onClick={() => {
                          price.id !== actionRangePrice ? setActionRangePrice(price.id) : setActionRangePrice(1);
                        }}
                      >
                        <div className={cx('title-item')}>Sim {price.titlePrice}</div>
                      </div>
                    ))}
                  </div>
                  <div className={cx(`header-title-grey`)}>
                    <div className={cx(`header-title-item-grey`)}>
                      <SimCardIcon sx={{ pr: '5px' }} fontSize="small" /> <b> SIM THEO NHÀ MẠNG</b>
                    </div>
                    {homeNetWork.map((network, index) => (
                      <div
                        key={index}
                        className={cx(
                          'detail-item-sim',
                          `${actionNetWork === network.titleNetWork ? 'bgr-active' : ''}`,
                        )}
                        onClick={() => {
                          network.titleNetWork !== actionNetWork
                            ? setActionNetWork(network.titleNetWork)
                            : setActionNetWork('');
                        }}
                      >
                        <div className={cx('title-item')}>Sim {network.titleNetWork}</div>
                      </div>
                    ))}
                  </div>
                  <div className={cx(`header-title-grey`)}>
                    <div className={cx(`header-title-item-grey`)}>
                      <SimCardIcon sx={{ pr: '5px' }} fontSize="small" /> <b> SIM THEO THỂ LOẠI</b>
                    </div>
                    {menuSimResult.map((menu, index) => (
                      <div
                        key={index}
                        className={cx('detail-item-sim', `${actionMenuCate === menu.link ? 'bgr-active' : ''}`)}
                        onClick={() => {
                          menu.link !== actionMenuCate ? setActionMenuCate(menu.link) : setActionMenuCate(0);
                        }}
                      >
                        <div className={cx('title-item')}>Sim {menu.title}</div>
                      </div>
                    ))}
                  </div>

                  <div className={cx(`header-title-grey`)}>
                    <div className={cx(`header-title-item-grey`)}>
                      <SimCardIcon sx={{ pr: '5px' }} fontSize="small" /> <b> SIM THEO ĐỊNH DẠNG</b>
                    </div>
                    {arrayFormatCate.map((menu, index) => (
                      <div
                        key={index}
                        className={cx('detail-item-sim', `${actionMenuCate === menu.link ? 'bgr-active' : ''}`)}
                        onClick={() => {
                          menu.link !== actionMenuCate ? setActionMenuCate(menu.link) : setActionMenuCate(0);
                        }}
                      >
                        <div className={cx('title-item')}>Sim {menu.title}</div>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Hidden>

              <Grid item md={9} xs={12}>
                <Hidden only={['md', 'lg', 'xl', 'sm', 'xs']}>
                  <Grid item md={12} xs={12}>
                    <div className={cx('wrapper-content')}>
                      <div className={cx('main-content-cate-border')}>
                        <div>
                          <div className={cx('list-subcate')}>
                            <Stack spacing={2} direction="row">
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                onClick={() => {
                                  setActionMenuCate(0);
                                  handleClearSim();
                                }}
                                className={cx('cate-border')}
                                sx={{
                                  background: '#e1e00021',
                                }}
                              >
                                <Badge
                                  color={actionMenuCate === 0 ? 'success' : 'default'}
                                  overlap="circular"
                                  badgeContent=" "
                                  variant="dot"
                                  sx={{ mb: 0.5 }}
                                >
                                  <Avatar
                                    sx={{
                                      background:
                                        actionMenuCate === 0
                                          ? 'linear-gradient(-180deg, deeppink, crimson)'
                                          : `linear-gradient(180deg, #ff933f, #f93782 69%, #f93782)`,
                                    }}
                                  >
                                    <b>T</b>
                                  </Avatar>
                                </Badge>
                                <b className={cx('bgr-title-cate')}>Tổng Sim</b>
                              </Box>
                              {menuSimResult.map((menu, i) => (
                                <Box
                                  key={menu.link}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  alignItems={'center'}
                                  onClick={() => {
                                    menu.link !== actionMenuCate ? setActionMenuCate(menu.link) : setActionMenuCate(0);
                                  }}
                                  className={cx('cate-border')}
                                  sx={{
                                    background: bgrCateSim[i],
                                  }}
                                >
                                  <Badge
                                    color={actionMenuCate === menu.link ? 'success' : 'default'}
                                    overlap="circular"
                                    badgeContent=" "
                                    variant="dot"
                                    sx={{ mb: 0.5 }}
                                  >
                                    <Avatar
                                      sx={{
                                        background:
                                          actionMenuCate === menu.link
                                            ? `linear-gradient(-180deg, deeppink, crimson)`
                                            : `linear-gradient(180deg, #ff933f, #f93782 69%, #f93782)`,
                                      }}
                                    >
                                      <b>{menu.title?.slice(0, 1)}</b>
                                    </Avatar>
                                  </Badge>
                                  <b className={cx('bgr-title-cate')}> {handleCutCateTitle(menu.title)}</b>
                                </Box>
                              ))}
                            </Stack>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Hidden>

                <Grid container>
                  <Grid item md={12} xs={12}>
                    <header className={cx('wrapper-header')}>
                      <div className={cx('inner-header')}>
                        <Grid container>
                          <Grid item md={12} xs={12}>
                            <Paper
                              elevation={3}
                              sx={{ p: '0px 2px', display: 'flex', alignItems: 'center', borderRadius: 2 }}
                            >
                              <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder={`Tìm sim trên ${hostname}`}
                                inputProps={{ 'aria-label': 'Tìm kiếm sim số' }}
                                value={textSearchChange}
                                onChange={handleChangeSearchSim}
                                onKeyPress={handleEnterKey}
                                fullWidth
                              />
                              {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                              <IconButton
                                sx={{ m: '2px' }}
                                className={cx('bgr-default')}
                                aria-label="directions"
                                onClick={onClickSearchSubmit}
                              >
                                <SearchIcon sx={{ color: 'aliceblue' }} />
                              </IconButton>
                            </Paper>
                          </Grid>

                          <Grid item xs={12} sx={{ mt: 1 }}>
                            <label className={cx('color-trang')}>
                              <b>Tìm sim đuôi 3979 gõ 3979 - 09 đuôi bất kỳ gõ 09*</b>
                            </label>
                          </Grid>
                        </Grid>
                      </div>
                    </header>
                  </Grid>
                </Grid>

                <div className={cx('main-content-cate')}>
                  <Grid container justifyContent={'center'} spacing={1}>
                    <Grid item xs={6} md={3} onClick={() => setIsOpenDrawerCate({ isCate: true, cateNumber: 1 })}>
                      <Paper elevation={0}>
                        <Card variant="outlined" sx={{ borderRadius: 2 }}>
                          <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                            {' '}
                            <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                              <h3>THỂ LOẠI SIM</h3>
                              <DashboardCustomizeOutlinedIcon color="action" />
                            </Stack>
                            <Stack direction="column" spacing={0}>
                              <span>
                                {menuSimResult.filter((x) => x.link === actionMenuCate).length > 0
                                  ? '-> ' + menuSimResult.filter((x) => x.link === actionMenuCate)[0].title
                                  : `sim thần tài, lộc phát, taxi...`}
                              </span>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Paper>
                    </Grid>

                    <Grid item xs={6} md={3} onClick={() => setIsOpenDrawerCate({ isCate: true, cateNumber: 2 })}>
                      <Paper elevation={0}>
                        <Card variant="outlined" sx={{ borderRadius: 2 }}>
                          <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                            {' '}
                            <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                              <h3>SIM ĐỊNH DẠNG</h3>
                              <ClassOutlinedIcon color="action" />
                            </Stack>
                            <Stack direction="column" spacing={0}>
                              <span>
                                {arrayFormatCate.filter((x) => x.link === actionMenuCate).length > 0
                                  ? '-> ' + arrayFormatCate.filter((x) => x.link === actionMenuCate)[0].title
                                  : `abc.abc, ababac, aabbcc...`}
                              </span>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Paper>
                    </Grid>

                    <Grid item xs={6} md={3} component={Link} to="/ngu-hanh-tuong-sinh">
                      <Paper elevation={0}>
                        <Card variant="outlined" sx={{ borderRadius: 2 }}>
                          <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                            {' '}
                            <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                              <h3>SIM PHONG THỦY</h3>
                              <AutoAwesomeOutlinedIcon color="action" />
                            </Stack>
                            <Stack direction="column" spacing={0}>
                              <span>sim hợp mệnh năm sinh</span>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Paper>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      md={3}
                      component={Link}
                      to="/nam-sinh"
                      onClick={() => {
                        setActionMenuCate(actionMenuCate === 'nam-sinh' ? 0 : 'nam-sinh');
                      }}
                    >
                      <Paper elevation={0}>
                        <Card variant="outlined" sx={{ borderRadius: 2 }}>
                          <CardContent
                            sx={{ p: 1, '&:last-child': { pb: 1 } }}
                            className={cx(actionMenuCate === 'nam-sinh' ? 'active-cate-ns' : '')}
                          >
                            {' '}
                            <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                              <h3>SIM NĂM SINH</h3>
                              <CalendarMonthOutlinedIcon color="action" />
                            </Stack>
                            <Stack direction="column" spacing={0}>
                              <span>sim theo đuôi năm sinh</span>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>

                <div ref={elementRef} className={cx('wrapper-content')}>
                  <div className={cx('main-content')}>
                    <div>
                      <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                        <b className={cx('dis-center')}>
                          <Checkbox
                            checked={Object.values(checkCoppy).length > 0 ? true : false}
                            onClick={handleCoppyAll}
                          />{' '}
                          Tổng: {simDataSort.length} sim
                        </b>

                        <b className={cx('layout-product')}>
                          Giao diện:
                          <FormControl sx={{ m: 0.5 }} size="small" error>
                            <Select
                              variant="standard"
                              sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                              value={layoutSim}
                              onChange={handleLayoutSim}
                              displayEmpty
                            >
                              <MenuItem value={2}>chi tiết</MenuItem>
                              <MenuItem value={1}>thu gọn</MenuItem>
                            </Select>
                          </FormControl>
                        </b>
                      </Stack>
                      {/* <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                  <label className={cx('list-product-title-Cate')}>{handleCategory()}</label>
                </Stack> */}
                      <Hidden only={['md', 'lg', 'xl']}>
                        <div className={cx('block-list-filter', 'margin-5')}>
                          <Stack direction="row">
                            {/* <label className={cx('block-list-filter_title')}>Theo tiêu chí</label> */}
                            <div className={cx('box-list-subcate')}>
                              <div className={cx('list-subcate')}>
                                <Stack direction="row" spacing={1}>
                                  <ButtonUI
                                    startIcon={<FilterAltRoundedIcon />}
                                    bgrColorBtn={isOpenDrawer === true ? '#ff000026' : '#F3F4F6'}
                                    colorText={isOpenDrawer === true ? '#ff0000' : '#444444'}
                                    borderBtn={isOpenDrawer === true ? '#ff00004a' : '#44444426'}
                                    onClick={() => toggleDrawer(true)}
                                  >
                                    Bộ Lọc
                                  </ButtonUI>

                                  <FormControl size="small">
                                    <Select
                                      sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                                      value={actionBtnSort}
                                      onChange={(e) => setActionBtnSort(e.target.value)}
                                      displayEmpty
                                    >
                                      <MenuItem value={1}>Sắp xếp</MenuItem>
                                      <MenuItem value={2}>Giá thấp</MenuItem>
                                      <MenuItem value={3}>Giá cao</MenuItem>
                                    </Select>
                                  </FormControl>

                                  <FormControl size="small">
                                    <Select
                                      sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                                      value={actionNetWork}
                                      onChange={(e) => setActionNetWork(e.target.value)}
                                      displayEmpty
                                    >
                                      <MenuItem value="">Nhà mạng</MenuItem>
                                      {homeNetWork.map((network, index) => (
                                        <MenuItem key={index} value={network.titleNetWork}>
                                          {network.titleNetWork}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <FormControl size="small">
                                    <Select
                                      sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                                      value={searchDauSim}
                                      onChange={handleChangeSelectDauSo}
                                      displayEmpty
                                    >
                                      <MenuItem value="">Đầu số</MenuItem>
                                      <MenuItem value={'09*'}>Đầu số 09</MenuItem>
                                      <MenuItem value={'07*'}>Đầu số 07</MenuItem>
                                      <MenuItem value={'03*'}>Đầu số 03</MenuItem>
                                      <MenuItem value={'08*'}>Đầu số 08</MenuItem>
                                      <MenuItem value={'05*'}>Đầu số 05</MenuItem>
                                    </Select>
                                  </FormControl>

                                  <FormControl size="small">
                                    <Select
                                      sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                                      value={actionRangePrice}
                                      onChange={handleChangeSelectPrice}
                                    >
                                      <MenuItem value={1}>Khoảng giá</MenuItem>
                                      <MenuItem value={2}>Dưới 1 triệu</MenuItem>
                                      <MenuItem value={3}>1 - 2 triệu</MenuItem>
                                      <MenuItem value={4}>2 - 3 triệu</MenuItem>
                                      <MenuItem value={5}>3 - 5 triệu</MenuItem>
                                      <MenuItem value={6}>5 - 10 triệu</MenuItem>
                                      <MenuItem value={7}>10 - 20 triệu</MenuItem>
                                      <MenuItem value={8}>20 - 50 triệu</MenuItem>
                                      <MenuItem value={9}> Trên 50 triệu</MenuItem>
                                    </Select>
                                  </FormControl>

                                  <FormControl size="small">
                                    <Select
                                      sx={{ borderRadius: 2.5, fontSize: '0.8rem', ':active': '#ff00004a' }}
                                      value={actionMenuCate}
                                    >
                                      <MenuItem onClick={() => setActionMenuCate(0)} value="0">
                                        Loại sim
                                      </MenuItem>
                                      {menuSimResult.map((menu) => (
                                        <MenuItem
                                          key={menu.link}
                                          value={menu.link}
                                          onClick={() => setActionMenuCate(menu.link)}
                                        >
                                          {menu.title}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <ButtonUI
                                    bgrColorBtn={'#F3F4F6'}
                                    colorText={'#444444'}
                                    borderBtn={'#44444426'}
                                    startIcon={<ShareIcon />}
                                    onClick={() => {
                                      handleCopyLink();
                                      setIsShowCopyLink(!isShowCopyLink);
                                    }}
                                  >
                                    Coppy Link
                                  </ButtonUI>

                                  <ButtonUI
                                    bgrColorBtn={'#F3F4F6'}
                                    colorText={'#444444'}
                                    borderBtn={'#44444426'}
                                    startIcon={<LoopIcon />}
                                    onClick={fetchApiSimCache}
                                  >
                                    Tải lại trang
                                  </ButtonUI>
                                </Stack>
                              </div>
                            </div>
                          </Stack>
                        </div>
                        <div className={cx('list-product')}>
                          <Stack direction="row" spacing={1}>
                            {isShowCopyLink && (
                              <CopyToClipboard text={isCopyLink} onCopy={() => setIsShowCopyLink(false)}>
                                <TextField
                                  error
                                  label="ấn sao chép link"
                                  variant="outlined"
                                  size="small"
                                  value={isCopyLink}
                                  disabled
                                  fullWidth
                                  sx={{ m: 1 }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <ContentCopyIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </CopyToClipboard>
                            )}
                          </Stack>
                        </div>
                      </Hidden>
                      <Grid container justifyContent={'center'} spacing={1}>
                        {/* Theme Layout load */}
                        {loadingTheme && <ThemeLoad />}
                        {loadingTheme && <ThemeLoad />}
                        <Backdrop
                          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                          open={loadingTheme}
                          onClick={handleClose}
                        >
                          <CircularProgress color="inherit" />
                          <b className={cx('loadingsim')}>Hệ thống đang tải dữ liệu sim ...</b>
                        </Backdrop>

                        {simInfinteResult &&
                          simInfinteResult.map(
                            (sim, index) =>
                              sim.simSearch && (
                                <Grid key={index} item xs={6} md={3}>
                                  {layoutSim !== 1 && (
                                    <Paper elevation={2}>
                                      <Card variant="outlined">
                                        <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                                          <FrameInfoSim
                                            bgrFrame={
                                              LogicNetWork(sim?.simSearch, sim?.netWork) === ('Mobifone' || 'mobifone')
                                                ? 'df'
                                                : LogicNetWork(sim?.simSearch, sim?.netWork) ===
                                                  ('Vinaphone' || 'vinaphone')
                                                ? 'blue'
                                                : LogicNetWork(sim?.simSearch, sim?.netWork) ===
                                                  ('Itelecom' || 'itelecom' || 'Itel' || 'itel')
                                                ? 'red'
                                                : 'red'
                                            }
                                            title={sim.sim}
                                            arrDetailSim={handleDetailSim(
                                              LogicNetWork(sim?.simSearch, sim?.netWork),
                                              sim.subCard,
                                              sim.simSearch,
                                              sim.noteSims,
                                              sim.price,
                                            )}
                                            onClick={() => handleModalOpen(sim)}
                                          />

                                          {sim.priceOld && (
                                            <div className={cx('detail-item-price')}>
                                              <Stack direction="row" spacing={1}>
                                                <b>Giá gốc:</b>
                                                {sim.price && sim.priceOld && (
                                                  <span>
                                                    <b className={cx('price-item')}>
                                                      {numberFormatText(
                                                        (
                                                          sim.price -
                                                          sim.price *
                                                            (parseInt(totalDiscount(sim.price, sim.priceOld)) / 100)
                                                        ).toString(),
                                                      )}
                                                    </b>
                                                  </span>
                                                )}
                                              </Stack>
                                              <Stack direction="row" spacing={1}>
                                                <b>Hoa hồng:</b>
                                                {sim.price && sim.priceOld && (
                                                  <span>
                                                    <b>
                                                      {numberFormatText(
                                                        (
                                                          sim.price *
                                                          (parseInt(totalDiscount(sim.price, sim.priceOld)) / 100)
                                                        ).toString(),
                                                      )}
                                                    </b>
                                                  </span>
                                                )}
                                              </Stack>
                                              <Stack direction="row" spacing={0.5}>
                                                <b>
                                                  Giá bán: {sim.price && numberFormatText(sim.price)} (
                                                  <b>{sim.priceOld && totalDiscount(sim.price, sim.priceOld)}</b>)
                                                </b>
                                              </Stack>
                                            </div>
                                          )}

                                          {sim.noteSims && (
                                            <div className={cx('detail-item-note-height')}>
                                              <div className={cx('detail-item-note')}>
                                                - Phí thuê giao dịch hộ giao tận nơi là{' '}
                                                {tablePriceGDH.map((price, i) => (
                                                  <b key={i} className={cx('price-item')}>
                                                    {sim.price >= price.priceStart && sim.price <= price.priceEnd
                                                      ? numberFormatText(price.cafe.toString())
                                                      : ''}
                                                  </b>
                                                ))}
                                              </div>
                                            </div>
                                          )}
                                        </CardContent>
                                      </Card>
                                      <Stack direction="row" spacing={0} justifyContent="flex-end">
                                        <div className={cx('icon-checkbox')}>
                                          {favourite(sim.simSearch) && <FavoriteIcon sx={{ mr: 1 }} color="error" />}
                                          {!checkCoppy[index] && (
                                            <CheckBoxOutlineBlankIcon
                                              sx={{ mr: 1 }}
                                              color="info"
                                              onClick={() => handleCoppy(index, sim)}
                                            />
                                          )}
                                          {checkCoppy[index] && (
                                            <CheckBoxIcon
                                              sx={{ mr: 1 }}
                                              color="info"
                                              onClick={() => handleDeleteCoppy(index)}
                                            />
                                          )}
                                        </div>
                                        {sim.price ? (
                                          <ButtonUI
                                            color="error"
                                            borderBtn="#ff0000"
                                            borderRadius={4}
                                            small
                                            onClick={() => handleModalOpen(sim)}
                                          >
                                            Mua Ngay
                                          </ButtonUI>
                                        ) : null}
                                      </Stack>
                                    </Paper>
                                  )}

                                  {layoutSim === 1 && (
                                    <Paper elevation={0}>
                                      <Card variant="outlined" sx={{ borderRadius: 3 }}>
                                        <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                                          <div className={cx('detail-item-price')}>
                                            <Stack
                                              direction="row"
                                              spacing={1}
                                              alignItems="center"
                                              justifyContent="space-around"
                                            >
                                              <Avatar
                                                sx={{ bgcolor: 'red' }}
                                                src={
                                                  LogicNetWork(sim?.simSearch, sim?.netWork) ===
                                                  ('Mobifone' || 'mobifone')
                                                    ? images.mobifone_small
                                                    : LogicNetWork(sim?.simSearch, sim?.netWork) ===
                                                      ('Viettel' || 'viettel')
                                                    ? images.viettel_small
                                                    : LogicNetWork(sim?.simSearch, sim?.netWork) ===
                                                      ('Vinaphone' || 'vinaphone')
                                                    ? images.vinaphone_small
                                                    : LogicNetWork(sim?.simSearch, sim?.netWork) ===
                                                      ('Itelecom' || 'itelecom' || 'Itel' || 'itel')
                                                    ? images.itel_small
                                                    : LogicNetWork(sim?.simSearch, sim?.netWork) ===
                                                      ('Vietnammobile' || 'vietnammobile' || 'vnmb')
                                                    ? images.vnmb_small
                                                    : LogicNetWork(sim?.simSearch, sim?.netWork) ===
                                                      ('Reddi' || 'reddi')
                                                    ? images.reddi_small
                                                    : ''
                                                }
                                                onClick={() => handleModalOpen(sim)}
                                              >
                                                {' '}
                                                <SimCardIcon />{' '}
                                              </Avatar>
                                              <Stack direction="column" justifyContent="flex-end">
                                                <Stack
                                                  direction="row"
                                                  justifyContent="flex-end"
                                                  onClick={() => handleModalOpen(sim)}
                                                >
                                                  <b
                                                    className={cx(
                                                      sim.netWork === ('Mobifone' || 'mobifone')
                                                        ? 'title-sim-df'
                                                        : sim.netWork === ('Vinaphone' || 'vinaphone')
                                                        ? 'title-sim-blue'
                                                        : sim.netWork === ('Itelecom' || 'itelecom' || 'Itel' || 'itel')
                                                        ? 'title-sim-red'
                                                        : 'title-sim-red',
                                                    )}
                                                  >
                                                    {sim.sim}
                                                  </b>
                                                </Stack>
                                                <Stack
                                                  direction="row"
                                                  spacing={0.5}
                                                  justifyContent="flex-end"
                                                  alignItems="center"
                                                >
                                                  {/* <b>Giá sale:</b> */}
                                                  {/* <b className={cx('price-old-item')}>
                                              {sim.priceOld && numberFormatText(sim.priceOld)}
                                            </b> */}

                                                  {/* {favourite(sim.simSearch) && <FavoriteIcon sx={{ mr: 1 }} color="error" />} */}
                                                  <b>Giá:</b>
                                                  <b>{sim.price && numberFormatText(sim.price)}</b>
                                                </Stack>
                                              </Stack>
                                            </Stack>
                                          </div>
                                        </CardContent>
                                      </Card>
                                    </Paper>
                                  )}
                                </Grid>
                              ),
                          )}
                      </Grid>
                      {/* {loadinglist && <h4>LoadingList...</h4>} */}
                    </div>
                  </div>
                  <Box
                    style={{
                      position: 'fixed',
                      bottom: 320,
                      right: 10,
                      display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                      zIndex: 10,
                    }}
                  >
                    <TextField
                      label="Thêm icon:"
                      size="small"
                      sx={{ width: '100px', background: '#fff' }}
                      variant="outlined"
                      value={iconCopy}
                      color="error"
                      onChange={(e) => {
                        setIconCopy(e.target.value);
                        handleCoppyIcon(e);
                      }}
                      focused
                    />
                  </Box>
                  <CopyToClipboard text={textCopiedSimAndPrice} onCopy={() => setIsCopied(true)}>
                    <ButtonUI
                      borderBtn="#bef5ff"
                      color="info"
                      style={{
                        position: 'fixed',
                        bottom: 280,
                        right: 10,
                        display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                        zIndex: 10,
                      }}
                      onClick={submitBtnCopied}
                    >
                      Copy {Object.values(checkCoppy).length} sim và giá
                    </ButtonUI>
                  </CopyToClipboard>
                  <CopyToClipboard text={textCopied} onCopy={() => setIsCopied(true)}>
                    <ButtonUI
                      borderBtn="#bef5ff"
                      color="info"
                      style={{
                        position: 'fixed',
                        bottom: 240,
                        right: 10,
                        display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                        zIndex: 10,
                      }}
                      onClick={submitBtnCopied}
                    >
                      Copy {Object.values(checkCoppy).length} sim chi tiết
                    </ButtonUI>
                  </CopyToClipboard>
                  <CopyToClipboard text={textCopiedSim} onCopy={() => setIsCopied(true)}>
                    <ButtonUI
                      borderBtn="#bef5ff"
                      color="info"
                      style={{
                        position: 'fixed',
                        bottom: 200,
                        right: 10,
                        display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                        zIndex: 10,
                      }}
                      onClick={submitBtnCopied}
                    >
                      Copy {Object.values(checkCoppy).length} sim
                    </ButtonUI>
                  </CopyToClipboard>
                  <CopyToClipboard text={textCopiedChot} onCopy={() => setIsCopied(true)}>
                    <ButtonUI
                      borderBtn="#d1fae5"
                      color="success"
                      style={{
                        position: 'fixed',
                        bottom: 160,
                        right: 10,
                        display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                        zIndex: 10,
                      }}
                      onClick={submitBtnCopied}
                    >
                      Mẫu đặt sim
                    </ButtonUI>
                  </CopyToClipboard>
                  <ButtonUI
                    borderBtn="#f9a6a6"
                    colorText="#fff"
                    bgrColorBtn="#e10000"
                    style={{
                      position: 'fixed',
                      bottom: 70,
                      right: 10,
                      display: pos ? 'flex' : 'none',
                      zIndex: 10,
                    }}
                    onClick={handleTop}
                  >
                    <ArrowCircleUpSharpIcon size="medium" />
                  </ButtonUI>
                </div>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
}

export default HomeDaily;
