import PropTypes from 'prop-types';
import DoneIcon from '@mui/icons-material/Done';
import SimCardIcon from '@mui/icons-material/SimCard';
import classNames from 'classnames/bind';
import styles from './FrameInfoSim.module.scss';
import { Stack } from '@mui/material';
const cx = classNames.bind(styles);

function FrameInfoSimDF({
  bgrFrame = 'red',
  title = '',
  des = '',
  fontSize = 'small',
  fontSizeTitle = '15',
  fontSizeDes = '15',
  arrDetailSim = [],
  onClick,
}) {
  let CompIcon = DoneIcon;
  const props = { onClick };
  return (
    <div className={cx(`header-title-${bgrFrame}`)} {...props}>
      <div className={cx(`header-title-item-${bgrFrame}`)}>
        <Stack direction={'column'} alignItems={'center'}>
          <b className={cx(`font-title-${fontSizeTitle}`)}> {title}</b>
          <b className={cx(`font-title-${fontSizeDes}`, 'dis-center')}>
            {' '}
            <SimCardIcon />
            {des}
          </b>
        </Stack>
      </div>
      <div className={cx('detail-item-sim', `font-size-${fontSize}`)}>
        {arrDetailSim &&
          arrDetailSim.map((sim, index) => (
            <div key={index} className={cx('title-item')}>
              {sim && <CompIcon fontSize="small" className={cx(`svg_icons_ticker_${bgrFrame}`)} />}
              <span dangerouslySetInnerHTML={{ __html: sim }} />
            </div>
          ))}
      </div>
    </div>
  );
}

FrameInfoSimDF.propTypes = {
  bgrFrame: PropTypes.string,
  title: PropTypes.string,
  fontSize: PropTypes.string,
  arrDetailSim: PropTypes.array,
  onClick: PropTypes.func,
};

export default FrameInfoSimDF;
