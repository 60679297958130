import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Card, CardContent, Grid, Paper } from '@mui/material';

function ThemeLoad() {
  return (
    <Grid item xs={6} md={4}>
      <Paper elevation={2}>
        <Card variant="outlined">
          <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
            <div style={{ padding: '10px' }}>
              <Skeleton variant="rectangular" height={125} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </div>
          </CardContent>
        </Card>
      </Paper>
    </Grid>
  );
}

export default ThemeLoad;
