import { LogicTranhSo } from './LogicTranhSo';

export const LogicMenhSim = (phoneNumber) => {
  if (phoneNumber) {
    const tho3 = LogicTranhSo(phoneNumber.slice(-2), '0,1,3,4');
    const tho6 = LogicTranhSo(phoneNumber.slice(-3), '3,4');
    const kim3 = LogicTranhSo(phoneNumber.slice(-2), '3,4,9');
    const kim6 = LogicTranhSo(phoneNumber.slice(-3), '9');
    const hoa3 = LogicTranhSo(phoneNumber.slice(-2), '0,1,6,7');
    const hoa6 = LogicTranhSo(phoneNumber.slice(-3), '0,1');
    const thuy3 = LogicTranhSo(phoneNumber.slice(-2), '2,5,8,9');
    const thuy6 = LogicTranhSo(phoneNumber.slice(-3), '2,5,8');
    const moc3 = LogicTranhSo(phoneNumber.slice(-2), '2,5,6,7,8');
    const moc6 = LogicTranhSo(phoneNumber.slice(-3), '6,7');
    let simTho = '';
    let simKim = '';
    let simHoa = '';
    let simThuy = '';
    let simMoc = '';
    if (tho3 && tho6) {
      simTho = 'Thổ';
    }
    if (kim3 && kim6) {
      simKim = 'Kim';
    }
    if (thuy3 && thuy6) {
      simThuy = 'Thủy';
    }
    if (hoa3 && hoa6) {
      simHoa = 'Hỏa';
    }
    if (moc3 && moc6) {
      simMoc = 'Mộc';
    }

    // const totalSimOld = totalSim <= 9 ? totalSim : parseInt(totalSim.slice(0, 1) + totalSim.slice(1, 2));
    // console.log(totalSim, totalSimOld, totalSimFinal, simMenh, simHopTuoi);
    const arrMenhSim = [simTho, simKim, simThuy, simHoa, simMoc];
    var filtered = arrMenhSim.filter(function (el) {
      return el !== '';
    });
    return filtered.toString();
  }
};
