import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { CircularProgress, Container, Grid } from '@mui/material';

import LocalMallIcon from '@mui/icons-material/LocalMall';

import { ThemeContext } from '../../../../component/ThemeContext/ThemeContext';
import styles from './shops.module.scss';
import classNames from 'classnames/bind';
import { API_URL_SIMSO } from '../../../../config/config';
import { listShopSize } from '../../../../services/listSim';
import FrameImageShop from '../../../../component/CustomerMUI/FrameInfoSim/FrameImageShop';
import { getLocalStorage, setLocalStorage } from '../../../../utils/helper';
import { listUserID } from '../../../../services/apiUsers';
const cx = classNames.bind(styles);

function ShopHome() {
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const [authDomain, setAuthDomain] = useState({});
  const contextApiUser = useContext(ThemeContext);
  const [resultShops, setResultShops] = useState([]);
  const [resultShopCounts, setResultShopCounts] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const loader = useRef(null);
  let textSimHeader = contextApiUser.textSearch;

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const textSP = urlParams.get('q') || '';

  const handleSubmit = () => {
    navigate('/', { replace: true });
  };

  const fetchApiShops = async (page = 1) => {
    const resultApi = await listShopSize(hostname, textSP, page, 6);
    if (resultApi['data'].length > 0) {
      setLoading(false);
      let arrResult = resultShops;
      setResultShops(arrResult.concat(resultApi['data']));
      setResultShopCounts(resultApi['totalRows']);
    }
  };

  const fetchApiShopsSearch = async (page = 1) => {
    setPage(1);
    setResultShops([]);
    setResultShopCounts(0);
    const resultApi = await listShopSize(hostname, textSP, 1, 6);
    if (resultApi['data'].length > 0) {
      setLoading(false);
      setResultShops(resultApi['data']);
      setResultShopCounts(resultApi['totalRows']);
    }
  };

  const fetchApiUserID = async () => {
    const resultAPISIM = await listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setLocalStorage('khoso', resultAPISIM[0]);
      setAuthDomain(resultAPISIM[0]);
      contextApiUser.toggleDataUser(resultAPISIM[0]);
    }
  };

  useEffect(() => {
    if (getLocalStorage('khoso')?.link_domain === hostname) {
      setAuthDomain(getLocalStorage('khoso'));
      contextApiUser.toggleDataUser(getLocalStorage('khoso'));
    } else {
      fetchApiUserID();
    }
    document.title = 'Tất cả sản phẩm ưu đãi tại ' + hostname;
  }, []);

  useEffect(() => {
    contextApiUser.toggleDataSim({ text: textSP, isSim: 2 });
    if (textSimHeader?.text !== '') {
      if (textSimHeader?.isSim === 1) {
        navigate(`/sim?q=${textSimHeader?.text}`, { replace: true });
      }
      if (textSimHeader?.isSim === 2) {
        fetchApiShopsSearch();
      }
    } else {
      fetchApiShops();
    }
  }, [textSimHeader?.text, textSP]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) observer.observe(loader.current);

    return () => observer.disconnect();
  }, [resultShops]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && Math.ceil(resultShopCounts / 6) >= page) {
      setPage((page) => page + 1);
    }
  };

  useEffect(() => {
    if (page !== 1 && Math.ceil(resultShopCounts / 6) >= page) {
      setLoading(true);
      fetchApiShops(page);
    }
  }, [page]);

  return (
    <Container sx={{ p: 0, pb: '90px' }}>
      <div className={cx(`header-title-grey-layout2`)}>
        <div>
          <div className={cx(`header-title-item-grey-layout2`)}>
            <h1 className={cx('title-sim-new')}>
              <div className={cx('dis-center')}>
                <LocalMallIcon />
                SẢN PHẨM HOT
              </div>
            </h1>
          </div>
        </div>
        <Grid container sx={{ p: 1 }} spacing={1}>
          {resultShops &&
            resultShops.map((item, i) => (
              <Grid item md={3} xs={6} key={i}>
                <FrameImageShop
                  image={`${API_URL_SIMSO}/imgshop?name=${item.image}.jpg`}
                  title={item.title}
                  price={item.price}
                  price_old={item.price_old}
                  id={item.id}
                />
              </Grid>
            ))}
        </Grid>
        <div ref={loader}>
          {loading && (
            <div className={cx('css-load-sim')}>
              <CircularProgress color="inherit" size={20} />
              <b className={cx('pl-1')}>Tải dữ liệu...</b>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}

export default ShopHome;
