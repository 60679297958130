import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames/bind';

import styles from './Info.module.scss';
import config from '../../config';
import { useContext, useEffect, useState } from 'react';
import auth from '../../config/auth';
import * as userIDService from '../../services/apiUsers';
import { getLocalStorage, setLocalStorage } from '../../utils/helper';
import { ThemeContext } from '../../component/ThemeContext/ThemeContext';
const cx = classNames.bind(styles);

function Info() {
  const hostname = window.location.hostname;
  const [authDomain, setAuthDomain] = useState({});
  const contextApiUser = useContext(ThemeContext);

  const fetchApiUserID = async () => {
    const resultAPISIM = await userIDService.listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setLocalStorage('khoso', resultAPISIM[0]);
      setAuthDomain(resultAPISIM[0]);
      contextApiUser.toggleDataUser(resultAPISIM[0]);
    }
  };

  useEffect(() => {
    if (getLocalStorage('khoso')?.link_domain === hostname) {
      setAuthDomain(getLocalStorage('khoso'));
      contextApiUser.toggleDataUser(getLocalStorage('khoso'));
    } else {
      fetchApiUserID();
    }
    document.title = 'Cách thức đặt sim và giao dịch';
  }, []);
  return (
    <>
      <Accordion expanded={true} sx={{ pb: '40px', mt: '65px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>
            <b>HƯỚNG DẪN MUA SIM</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={cx('guide-order-sim')}>
            <p>
              <b>Bước 1: ĐẶT SIM</b>
              <br />
              Quý khách điền đầy đủ thông tin và đặt mua, hoặc liên hệ {authDomain['HOTTLINE_SIM']} để được đặt hàng.
            </p>
            <p>
              <b>Bước 2: XÁC NHẬN ĐƠN HÀNG</b>
              <br />
              Khi nhận được đơn hàng của quý khách, nhân viên bán hàng sẽ kiểm tra số trong kho của chúng tôi và gọi
              điện lại báo cho quý khách về tình trạng sim quý khách đã đặt mua.
            </p>
            <p>
              <b>Bước 3 &amp; 4: ĐĂNG KÝ THÔNG TIN VÀ GIAO SIM</b>
              <br />▪ Cách 1: Giao sim tận nhà miễn phí trong ngày
              <br />– Chúng tôi sẽ có giao dịch viên giao sim và đăng ký chính chủ tại nơi quý khách đang ở hiện tại.
              <br />– Quý khách kiểm tra đúng chính chủ, đúng số sim quý khách mới phải thanh toán
            </p>
            <p>
              ▪ Cách 2: Nhận sim tại cửa hàng Viettel, Vinaphone, Mobifone, Vietnammobile, Itelcom gần nhất
              <br />– Giao dịch viên của chúng tôi sẽ cùng quý khách ra điểm giao dịch gần nhất để giao dịch.
              <br />– Quý khách kiểm tra đúng chính chủ, đúng số sim quý khách mới phải thanh toán.
            </p>
            <p>
              ▪ Cách 3: Quý khách có thể thanh toán toàn bộ tiền sim hoặc đặt cọc tối thiểu 10% giá trị sim bằng chuyển
              khoản hay thẻ cào và gửi hồ sơ (gồm ảnh CCCD 2 mặt + 1 ảnh chân dung) qua Zalo cho chúng tôi . Chúng tôi
              sẽ gửi chuyển phát nhanh sim đến tận tay quý khách. Quý khách kiểm tra đúng số sim và thông tin chính chủ
              rồi trả nốt phần tiền còn lại cho nhân viên giao hàng (ship COD).
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default Info;
