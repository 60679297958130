import { useEffect, useState } from 'react';
import { Alert, Button, Card, CardContent, Container, Grid, Paper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import classNames from 'classnames/bind';
import styles from './formatsim.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { addSimDigi, updatesxSimDigi } from '../../../services/apiDigiShop';
import { checkTokenDashboad } from '../../../utils/helper';
const cx = classNames.bind(styles);
function DigiShop() {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handelUpdateSimDigiShop = async () => {
    const resultAPISIM = await addSimDigi();
    if (resultAPISIM.message === 'Ok') {
      setMessage('Cập Nhật Thành Công...');
    }
  };
  const handelUpdateSXSimDigiShop = async () => {
    const resultAPISIM = await updatesxSimDigi();
    if (resultAPISIM.message === 'Ok') {
      setMessage('Cập Nhật Thành Công...');
    }
  };

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          {message && <Alert severity="success">{message}</Alert>}
        </Grid>
        <Grid item xs={12} md={3} onClick={handelUpdateSimDigiShop}>
          <Paper elevation={0}>
            <Card variant="outlined" sx={{ borderRadius: 2 }}>
              <CardContent sx={{ p: 1, '&:last-child': { pb: 1 }, cursor: 'pointer' }}>
                <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                  <h3>Cập Nhật Sim DiGiShop</h3>
                  {/* <AutoAwesomeOutlinedIcon color="action" /> */}
                </Stack>
                <Stack direction="column" spacing={0}>
                  <span>Tự lọc sim số đẹp...</span>
                </Stack>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} onClick={handelUpdateSXSimDigiShop}>
          <Paper elevation={0}>
            <Card variant="outlined" sx={{ borderRadius: 2 }}>
              <CardContent sx={{ p: 1, '&:last-child': { pb: 1 }, cursor: 'pointer' }}>
                <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                  <h3>Cập Nhật SX Sim DiGiShop</h3>
                  {/* <AutoAwesomeOutlinedIcon color="action" /> */}
                </Stack>
                <Stack direction="column" spacing={0}>
                  <span>Tự sắp xếp sim số đẹp...</span>
                </Stack>
              </CardContent>
            </Card>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} component={Link} to="/dashboard/digishop/formatsim">
          <Paper elevation={0}>
            <Card variant="outlined" sx={{ borderRadius: 2 }}>
              <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                  <h3>Quản Lý Dạng Sim</h3>
                  {/* <AutoAwesomeOutlinedIcon color="action" /> */}
                </Stack>
                <Stack direction="column" spacing={0}>
                  <span>Thêm sửa xóa dạng sim...</span>
                </Stack>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default DigiShop;
