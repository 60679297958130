import { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { checkLoginUsers, listUserIDAll, updateImageLogo, updateUserID } from '../../../services/apiUsers';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  checkTokenDashboad,
  getCookie,
  getLocalStorage,
  numberFormat,
  numberFormatText,
  removeLocalStorage,
  setLocalStorage,
} from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { ButtonUI } from '../../../component/CustomerMUI';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import FormatColorFillRoundedIcon from '@mui/icons-material/FormatColorFillRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import NetworkCellIcon from '@mui/icons-material/NetworkCell';
import { Theme, useTheme } from '@mui/material/styles';
import ImageIcon from '@mui/icons-material/Image';

import styles from './admin.module.scss';
import classNames from 'classnames/bind';
import images from '../../../assets/images';
import { totalPriceSim } from '../../../services/listSim';
import { API_URL_SIMSO } from '../../../config/config';
const cx = classNames.bind(styles);

const names = ['Viettel', 'Mobifone', 'Vinaphone', 'Itelecom', 'Vietnamobile', 'Wintel'];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

function DashboardAdmin() {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000).getTime();
  const arrColor = [
    {
      name: 'Đỏ',
      color: '#cf000e',
      value: '#cf000e,0deg',
    },
    {
      name: 'Xanh',
      color: '#087b37',
      value: '#087b37,110deg',
    },
    {
      name: 'Tím',
      color: '#69087b',
      value: '#69087b,300deg',
    },
    {
      name: 'Lam',
      color: '#08547b',
      value: '#08547b,190deg',
    },
    {
      name: 'Xanh',
      color: '#081f7b',
      value: '#081f7b,240deg',
    },
    {
      name: 'Xanh',
      color: '#023fd3',
      value: '#023fd3,240deg',
    },
    {
      name: 'Xanh',
      color: '#0092d5',
      value: '#0092d5,205deg',
    },
    {
      name: 'Cam',
      color: '#f37c02',
      value: '#f37c02,0deg',
    },
    {
      name: 'Đen',
      color: '#1b1b1b',
      value: '#1b1b1b,0deg',
    },
  ];
  const inputFile = useRef(null);
  const inputFileLogo = useRef(null);
  const hostname = window.location.hostname;
  const [resultAdmin, setResultAdmin] = useState({});
  const [message, setMessage] = useState('');
  const [resultAdminID, setResultAdminID] = useState({});
  const [resultPassWord, setResultPassWord] = useState({ pass_old: '', pass_new: '' });
  const [isPassWord, setIsPassWord] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(true);
  const [loadingEditPass, setLoadingEditPass] = useState(false);
  const [totalPriceAll, setTotalPriceAll] = useState({});
  const navigate = useNavigate();
  const getToken = getCookie('token');
  const [imagesLogoLoading, setImagesLogoLoading] = useState({});
  const [imagesLogo, setImagesLogo] = useState({});
  const [isCheckLogo, setIsCheckLogo] = useState(true);
  const [messageImageSize, setMessageImageSize] = useState({});

  const theme = useTheme();
  const fetchApiAdmin = async () => {
    if (getToken) {
      setResultAdmin(getToken);
      setResultAdminID({
        ADDRESS: getToken['ADDRESS'],
        GMAIL_NOTIFI: getToken['GMAIL_NOTIFI'],
        HOTTLINE_SIM: getToken['HOTTLINE_SIM'],
        HOTTLINE_SIM_CALL: getToken['HOTTLINE_SIM_CALL'],
        HOTTLINE_ZALO: getToken['HOTTLINE_ZALO'],
        TITLE_HEADER: getToken['TITLE_HEADER'],
        TITLE_HOME: getToken['TITLE_HOME'],
        layoutSim: getToken['layoutSim'],
        link_gg: getToken['link_gg'],
        background: getToken['background'],
        bank: getToken['bank'],
        username: getToken['username'],
        mang_show: getToken['mang_show'] || '',
      });
    } else {
      const resultAPISIM = await listUserIDAll(hostname);
      if (resultAPISIM && resultAPISIM.length > 0) {
        setResultAdmin(resultAPISIM[0]);
        setResultAdminID({
          ADDRESS: resultAPISIM[0]['ADDRESS'],
          GMAIL_NOTIFI: resultAPISIM[0]['GMAIL_NOTIFI'],
          HOTTLINE_SIM: resultAPISIM[0]['HOTTLINE_SIM'],
          HOTTLINE_SIM_CALL: resultAPISIM[0]['HOTTLINE_SIM_CALL'],
          HOTTLINE_ZALO: resultAPISIM[0]['HOTTLINE_ZALO'],
          TITLE_HEADER: resultAPISIM[0]['TITLE_HEADER'],
          TITLE_HOME: resultAPISIM[0]['TITLE_HOME'],
          layoutSim: resultAPISIM[0]['layoutSim'],
          link_gg: resultAPISIM[0]['link_gg'],
          background: resultAPISIM[0]['background'],
          bank: resultAPISIM[0]['bank'],
          username: resultAPISIM[0]['username'],
          mang_show: resultAPISIM[0]['mang_show'] || '',
        });
      }
    }
  };

  const totalPriceSimSo = async () => {
    const resultAPISIM = await totalPriceSim(hostname);
    setTotalPriceAll(resultAPISIM[0]);
  };

  useEffect(() => {
    fetchApiAdmin();
    totalPriceSimSo();
    checkImageExists(`${API_URL_SIMSO}/showlogo?name=logo_${hostname}.jpg`);
  }, []);

  const handleChangValueAdmin = (jsonVL) => {
    setResultAdminID({ ...resultAdminID, ...jsonVL });
  };

  const handelUpdateAdmin = async () => {
    setLoading(true);
    const body = {
      idUser: resultAdmin.id,
      data: resultAdminID,
    };
    const resultAPISIM = await updateUserID(body);
    if (resultAPISIM?.message === 'Ok') {
      setMessage('Thành Công');
      const resultAPISIM = await listUserIDAll(hostname);
      removeLocalStorage('token');
      setLocalStorage('token', { ...resultAPISIM[0], exp: futureDate });
      setResultAdmin({ ...resultAPISIM[0], exp: futureDate });
      setLoading(false);
      setLoadingEdit(true);
    } else {
      setMessage('Thất Bại');
      setLoading(false);
      setLoadingEdit(true);
    }
  };

  const checkPassWordOld = async (user, pass) => {
    const dataUser = {
      user: user,
      pass: pass,
      domain: hostname,
    };
    const checkLogin = await checkLoginUsers(dataUser);
    if (checkLogin.length > 0) {
      setLoadingEditPass(false);
    } else {
      setLoadingEditPass(true);
    }
  };

  //ẢNH LOGO LOADING
  const onFilechange = (e) => {
    e.preventDefault();
    let imagesss = e.target.files[0];
    if (imagesss.size <= 1048576) {
      let objImage = {
        image: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0]),
      };
      setImagesLogoLoading(objImage);
      setMessageImageSize({ ...messageImageSize, logoload: '' });
    } else {
      setMessageImageSize({ ...messageImageSize, logoload: 'Kích thước ảnh không được vượt quá 1MB.' });
    }
  };

  const onButtonClickFileImage = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const submitUpdateLogoLoading = async () => {
    const formData = new FormData();
    formData.append('image', imagesLogoLoading?.image);
    formData.append('name', `logoload_${hostname}`);
    const upload = await updateImageLogo(formData);
    if (upload.message === 'OK') {
      setImagesLogoLoading({ ...imagesLogoLoading, image: '' });
    }
  };

  //ẢNH LOGO LOADING
  const onFilechangeLogo = (e) => {
    e.preventDefault();
    let imagess = e.target.files[0];
    if (imagess.size <= 1048576) {
      let objImage = {
        image: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0]),
      };
      setImagesLogo(objImage);
      setMessageImageSize({ ...messageImageSize, logo: '' });
    } else {
      setMessageImageSize({ ...messageImageSize, logo: 'Kích thước ảnh không được vượt quá 1MB.' });
    }
  };

  const onButtonClickFileImageLogo = () => {
    // `current` points to the mounted file input element
    inputFileLogo.current.click();
  };

  const submitUpdateLogo = async () => {
    const formData = new FormData();
    formData.append('image', imagesLogo?.image);
    formData.append('name', `logo_${hostname}`);
    const upload = await updateImageLogo(formData);
    if (upload.message === 'OK') {
      setImagesLogo({ ...imagesLogo, image: '' });
    }
  };

  function checkImageExists(imageUrl) {
    let img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setIsCheckLogo(true);
    };
    img.onerror = () => {
      setIsCheckLogo(false);
    };
  }
  return (
    <Container sx={{ pb: '90px', pl: '5px', pr: '5px' }}>
      <Grid container justifyContent={'center'}>
        {!loadingEdit && (
          <Grid item xs={12} md={6}>
            <Stack direction={'row'} sx={{ mb: 1, justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                size="small"
                color={loadingEdit ? 'success' : 'warning'}
                onClick={() => setLoadingEdit(!loadingEdit)}
              >
                {loadingEdit ? 'Chỉnh Sửa' : 'Quay Lại'}
              </Button>
            </Stack>
          </Grid>
        )}
        {/* <Grid item xs={12} md={12}>
          {message && (
            <Alert severity={message === 'Thành Công' ? 'success' : 'error'}>
              {message === 'Thành Công' ? 'Cập Nhật Thành Công' : 'Cập Nhật Thất Bại'}
            </Alert>
          )}
        </Grid> */}
        {loadingEdit && (
          <Grid item xs={12} md={6}>
            <Stack
              direction={'column'}
              spacing={1}
              className={cx('header')}
              style={{ background: `linear-gradient(90deg, ${resultAdmin?.background?.split(',')[0]}, #f63)` }}
            >
              <Stack direction={'row'} spacing={2}>
                <Badge color="success" overlap="circular" badgeContent="VIP" className={cx('nowrap')}>
                  <Avatar className={cx('font-family-1')} src={images.logokhoso} sx={{ width: 56, height: 56 }}>
                    {resultAdmin?.TITLE_HEADER?.slice(0, 1) || 'KS'}
                  </Avatar>
                </Badge>
                <Stack direction={'column'} spacing={0.5}>
                  <h1 className={cx('font-family-1', 'dis-center')}>
                    {resultAdmin?.TITLE_HEADER || ''} <CheckCircleIcon sx={{ color: '#68d0ff' }} />
                  </h1>
                  <b>Ngày hết hạn: {resultAdmin?.hanweb || ''}</b>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction={'row'} spacing={1} className={cx('thongke')} justifyContent={'space-around'}>
              <Stack direction={'column'} spacing={0.5}>
                <h3>GIÁ TRỊ BẢNG</h3>
                <h1 style={{ color: '#009cff' }}>
                  {numberFormatText(totalPriceAll?.total_price) || 'đang tải dữ liệu....'}
                </h1>
              </Stack>
              <Stack direction={'column'} spacing={0.5}>
                <h3>SỐ LƯỢNG SIM</h3>
                <h1 style={{ color: '#009cff' }}>{numberFormat(totalPriceAll?.total_records?.toString())}</h1>
              </Stack>
            </Stack>
            <Stack direction={'column'} spacing={1} className={cx('thongke')}>
              <Stack direction={'row'} spacing={0.5} justifyContent={'space-between'} alignItems={'center'}>
                <h3>
                  <b>THÔNG TIN TÀI KHOẢN</b>
                </h3>
                <Button
                  variant="text"
                  size="small"
                  color={loadingEdit ? 'success' : 'warning'}
                  onClick={() => setLoadingEdit(!loadingEdit)}
                >
                  <BorderColorIcon />
                  <b>{loadingEdit ? 'Sửa' : 'Đóng Lại'}</b>
                </Button>
              </Stack>
              <Stack direction={'column'} spacing={1}>
                <h3 className={cx('dis-center')}>
                  <PhoneInTalkIcon color="error" sx={{ mr: 1 }} /> {resultAdmin?.HOTTLINE_SIM || ''}
                </h3>
                <h3 className={cx('dis-center')}>
                  <img src={images.iconZalo} alt="zalo" width={'25px'} style={{ marginRight: '8px' }} />{' '}
                  {resultAdmin?.HOTTLINE_ZALO || ''}
                </h3>
                <h3 className={cx('dis-center')}>
                  <MarkEmailUnreadIcon color="info" sx={{ mr: 1 }} /> {resultAdmin?.GMAIL_NOTIFI || ''}
                </h3>
                {resultAdmin?.bank && (
                  <h3 className={cx('dis-center')}>
                    <AccountBalanceWalletIcon color="secondary" sx={{ mr: 1 }} /> {resultAdmin?.bank || ''}
                  </h3>
                )}
                <h3 className={cx('dis-center')}>
                  <ViewQuiltRoundedIcon color="warning" sx={{ mr: 1 }} /> Giao diện:{' '}
                  {resultAdmin?.layoutSim === '1'
                    ? 'Giao diện 1 - kiểu thu gọn'
                    : resultAdmin?.layoutSim === '2'
                    ? 'Giao diện 1 - kiểu chi tiết'
                    : resultAdmin?.layoutSim === '3'
                    ? 'Giao diện 1 - kiểu excel'
                    : resultAdmin?.layoutSim === '4'
                    ? 'Giao diện 2 - kiểu excel'
                    : resultAdmin?.layoutSim === '5'
                    ? 'Giao diện 2 - kiểu thu gọn'
                    : resultAdmin?.layoutSim === '6'
                    ? 'Giao diện 2 - kiểu chi tiết'
                    : resultAdmin?.layoutSim === '7'
                    ? 'Giao diện 3 - Bảng Phong Thủy'
                    : ''}
                </h3>
                <h3 className={cx('dis-center')}>
                  <FormatColorFillRoundedIcon sx={{ mr: 1, color: resultAdmin?.background?.split(',')[0] || 'red' }} />{' '}
                  Màu giao diện:{' '}
                  {arrColor.map(
                    (color, i) =>
                      color.color === resultAdmin?.background?.split(',')[0] && (
                        <b style={{ color: `${color.color}` }} key={i}>
                          {' '}
                          {color.name}
                        </b>
                      ),
                  )}
                </h3>
                <h3 className={cx('dis-center')}>
                  <NetworkCellIcon sx={{ mr: 1 }} color="error" /> Chuyên Nhà Mạng: {resultAdmin?.mang_show}
                </h3>
                <h3 className={cx('dis-center')}>
                  <EventNoteRoundedIcon color="action" /> Địa chỉ: {resultAdmin?.ADDRESS}
                </h3>
              </Stack>
            </Stack>

            <Stack
              direction={'column'}
              spacing={1}
              className={cx('thongke')}
              onClick={() => {
                setIsPassWord(true);
                setLoadingEdit(false);
              }}
              style={{ cursor: 'pointer' }}
            >
              <h3 className={cx('dis-center')}>
                <VpnKeyRoundedIcon color="error" sx={{ mr: 1 }} /> ĐỔI MẬT KHẨU
              </h3>
            </Stack>

            <Stack direction={'column'} spacing={1} className={cx('thongke')} style={{ cursor: 'pointer' }}>
              <h3 className={cx('dis-center')}>
                <ImageIcon color="error" sx={{ mr: 1 }} /> CẬP NHẬT ẢNH LOGO & LOGO LOADING
              </h3>
              <label>Ảnh loading trang chủ:</label>
              <Stack direction={'row'} spacing={1}>
                <div className={cx('frameImage', 'dis-content-center')} onClick={onButtonClickFileImage}>
                  {' '}
                  <ImageIcon color="success" sx={{ cursor: 'pointer' }} />
                  <input
                    type="file"
                    name="image"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={onFilechange}
                  />
                </div>
                {imagesLogoLoading?.url ? (
                  <img src={imagesLogoLoading?.url} width={'75px'} height={'75px'} alt="logo" />
                ) : (
                  <img
                    src={`${API_URL_SIMSO}/showlogo?name=logoload_${hostname}.jpg`}
                    alt="not fount"
                    width={'75px'}
                    height={'75px'}
                    onError={(e) => {
                      e.target.src = images.logoloading;
                    }}
                  />
                )}
              </Stack>
              <b style={{ color: 'red' }}>{messageImageSize.logoload}</b>
              {imagesLogoLoading?.image && (
                <Button variant="contained" color="success" onClick={submitUpdateLogoLoading}>
                  Lưu lại
                </Button>
              )}
              <Divider />
              <label>Ảnh LOGO trang chủ:</label>
              <Stack direction={'row'} spacing={1}>
                <div className={cx('frameImage', 'dis-content-center')} onClick={onButtonClickFileImageLogo}>
                  {' '}
                  <ImageIcon color="success" sx={{ cursor: 'pointer' }} />
                  <input
                    type="file"
                    name="image"
                    id="file"
                    ref={inputFileLogo}
                    style={{ display: 'none' }}
                    onChange={onFilechangeLogo}
                  />
                </div>
                {imagesLogo?.url ? (
                  <img src={imagesLogo?.url} width={'75px'} height={'75px'} alt="logo" />
                ) : (
                  <img
                    src={`${API_URL_SIMSO}/showlogo?name=logo_${hostname}.jpg`}
                    alt="not fount"
                    width={'75px'}
                    height={'75px'}
                    onError={(e) => {
                      e.target.src = images[resultAdmin['logo']];
                    }}
                  />
                )}
              </Stack>
              <b style={{ color: 'red' }}>{messageImageSize.logo}</b>
              {imagesLogo?.image && (
                <Button variant="contained" color="success" onClick={submitUpdateLogo}>
                  Lưu lại
                </Button>
              )}
            </Stack>
          </Grid>
        )}
        {!loadingEdit && !isPassWord && (
          <Grid item md={6} xs={12} sx={{ m: 1 }}>
            <Stack direction={'column'} spacing={2}>
              <TextField
                label="ID URL Trang Tính"
                size="small"
                placeholder="nhập id trang tính"
                value={resultAdminID?.link_gg || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  handleChangValueAdmin({ link_gg: e.target.value });
                }}
                focused
                required
                disabled={loadingEdit}
              />

              <TextField
                label="Email thông báo sim"
                size="small"
                placeholder="nhập mail thông báo đặt sim"
                value={resultAdminID?.GMAIL_NOTIFI || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  handleChangValueAdmin({ GMAIL_NOTIFI: e.target.value });
                }}
                focused
                required
                disabled={loadingEdit}
              />
              <TextField
                label="Ghi chú đầu trang"
                size="small"
                placeholder="nhập ghi chú - có thể ghi địa chỉ"
                value={resultAdminID?.ADDRESS || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  handleChangValueAdmin({ ADDRESS: e.target.value });
                }}
                focused
                required
                disabled={loadingEdit}
              />
              <TextField
                label="Hotline hiển thị 1 or nhiều"
                size="small"
                placeholder="nhập hotline hiển thị"
                value={resultAdminID?.HOTTLINE_SIM || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  handleChangValueAdmin({ HOTTLINE_SIM: e.target.value });
                }}
                focused
                required
                disabled={loadingEdit}
              />
              <TextField
                label="Hotline liên hệ"
                size="small"
                placeholder="nhập hotline liên hệ không dấu ."
                value={resultAdminID?.HOTTLINE_SIM_CALL || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  handleChangValueAdmin({ HOTTLINE_SIM_CALL: e.target.value });
                }}
                focused
                required
                disabled={loadingEdit}
              />
              <TextField
                label="Zalo liên hệ"
                size="small"
                placeholder="nhập zalo liên hệ không dấu ."
                value={resultAdminID?.HOTTLINE_ZALO || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  handleChangValueAdmin({ HOTTLINE_ZALO: e.target.value });
                }}
                focused
                required
                disabled={loadingEdit}
              />
              <TextField
                label="Chữ trên header"
                size="small"
                placeholder="nhập chữ hiển thị"
                value={resultAdminID?.TITLE_HEADER || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  handleChangValueAdmin({ TITLE_HEADER: e.target.value });
                }}
                focused
                required
                disabled={loadingEdit}
              />
              <TextField
                label="Title trang sim"
                size="small"
                placeholder="nhập title trang sim"
                value={resultAdminID?.TITLE_HOME || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  handleChangValueAdmin({ TITLE_HOME: e.target.value });
                }}
                focused
                required
                disabled={loadingEdit}
              />
              <TextField
                label="Thông tin tài khoản ngân hàng"
                size="small"
                placeholder="Nhập thông tin tài khoản"
                value={resultAdminID?.bank || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  handleChangValueAdmin({ bank: e.target.value });
                }}
                focused
                required
                disabled={loadingEdit}
              />
              <Typography sx={{ color: 'red', fontSize: '12px' }}>
                VD mẫu TK Ngân Hàng: <br /> NGÂN HÀNG MBBANK,LE MINH DUY,0984.49.49.49
              </Typography>
              <FormControl>
                <InputLabel>Chọn giao diện</InputLabel>
                <Select
                  value={resultAdminID?.layoutSim || ''}
                  onChange={(e) => {
                    handleChangValueAdmin({ layoutSim: e.target.value });
                  }}
                  disabled={loadingEdit}
                >
                  <MenuItem value={1}>Giao diện 1 - kiểu thu gọn</MenuItem>
                  <MenuItem value={2}>Giao diện 1 - kiểu chi tiết</MenuItem>
                  <MenuItem value={3}>Giao diện 1 - kiểu excel</MenuItem>
                  <MenuItem value={5}>Giao diện 2 - kiểu thu gọn</MenuItem>
                  <MenuItem value={6}>Giao diện 2 - kiểu chi tiết</MenuItem>
                  <MenuItem value={4}>Giao diện 2 - kiểu excel</MenuItem>
                  <MenuItem value={7}>Giao diện 3 - Bảng Phong Thủy</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Chọn hiển thị Nhà Mạng</InputLabel>
                <Select
                  multiple
                  value={resultAdminID?.mang_show?.split(',') || []}
                  onChange={(e) => {
                    handleChangValueAdmin({ mang_show: e.target.value.toString() });
                  }}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected?.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, resultAdminID?.mang_show?.split(','), theme)}
                      color="info"
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Grid item md={12} xs={12} sx={{ m: 1 }}>
              <Stack direction={'column'}>
                <b>Chọn Màu Cho Giao Diện:</b>
              </Stack>
              {arrColor.map((color, i) => (
                <ButtonUI
                  bgrColorBtn={color.color}
                  colorText="#fff"
                  key={i}
                  onClick={() => {
                    handleChangValueAdmin({ background: color.value });
                  }}
                  sx={{ m: 0.3 }}
                >
                  {color.name}{' '}
                  <CheckCircleIcon
                    fontSize="small"
                    sx={{ color: resultAdminID?.background === color.value ? 'gold' : '#d8d8d854' }}
                  />
                </ButtonUI>
              ))}
            </Grid>
            <Stack direction={'column'} spacing={2}>
              <Button variant="contained" color="error" onClick={handelUpdateAdmin} disabled={loading}>
                Cập Nhật
              </Button>
            </Stack>
          </Grid>
        )}

        {!loadingEdit && isPassWord && (
          <Grid item md={6} xs={12} sx={{ m: 1 }}>
            <Stack direction={'column'} spacing={2}>
              <TextField
                label="Mật khẩu cũ"
                size="small"
                placeholder="nhập mật khẩu cũ"
                value={resultPassWord?.pass_old || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  setResultPassWord({ ...resultPassWord, pass_old: e.target.value });
                }}
                focused
                required
                disabled={loadingEdit}
              />

              <TextField
                label="Mật khẩu mới"
                size="small"
                placeholder="nhập mật khẩu mới"
                value={resultAdminID?.pass_word || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  handleChangValueAdmin({ pass_word: e.target.value });
                  checkPassWordOld(resultAdmin?.username, resultPassWord?.pass_old);
                }}
                focused
                required
                type="password"
                disabled={loadingEdit}
              />
              <TextField
                label="Nhập lại mật khẩu mới"
                size="small"
                placeholder="nhập lại mật khẩu mới"
                value={resultPassWord?.pass_new || ''}
                //   defaultValue={resultAdmin?.NOTIFI}
                onChange={(e) => {
                  setResultPassWord({ ...resultPassWord, pass_new: e.target.value });
                }}
                type="password"
                focused
                required
                disabled={loadingEdit}
              />
              <b style={{ color: 'red' }}>
                {resultPassWord.pass_new?.length > 0 &&
                resultAdminID?.pass_word?.length > 0 &&
                resultPassWord.pass_new !== resultAdminID?.pass_word
                  ? 'Mật khẩu mới chưa khớp nhau'
                  : ''}
                {loadingEditPass && <b style={{ color: 'red' }}>Mật khẩu cũ không đúng</b>}
              </b>
            </Stack>
            <Stack direction={'column'} spacing={2}>
              <Button variant="contained" color="error" onClick={handelUpdateAdmin} disabled={loadingEditPass}>
                Cập Nhật
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default DashboardAdmin;
