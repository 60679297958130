import * as requestAPI from '../utils/requestAPI';

//API THÊM USER
export const addNewsID = async (data) => {
  try {
    const res = await requestAPI.postSimTT(`/`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listNewsID = async (u) => {
  try {
    const res = await requestAPI.getSimTT(`?u=${u}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getNewsID = async (id, u) => {
  try {
    const res = await requestAPI.getSimTT(`?u=${u}&id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateNewsID = async (data) => {
  try {
    const res = await requestAPI.putSimTT(`/`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteNews = async (data) => {
  try {
    const res = await requestAPI.deleteSimTT(`/`, { data: data });
    return res;
  } catch (error) {
    console.log(error);
  }
};
