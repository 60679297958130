import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  TextField,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import SquareIcon from '@mui/icons-material/Square';
import DonutSmallRoundedIcon from '@mui/icons-material/DonutSmallRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SimCardIcon from '@mui/icons-material/SimCard';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  checkTokenDashboad,
  getCookie,
  getLocalStorage,
  numberFormat,
  numberFormatText,
  numberFormatTextShorten,
} from '../../../utils/helper';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import 'dayjs/locale/vi';
import { ButtonUI } from '../../../component/CustomerMUI';
import { addCongnoAll, deleCongno, getCongnoDate, getCongnoID, updateCongnoID } from '../../../services/apiDebt';
import range from 'lodash-es/range';

import classNames from 'classnames/bind';
import styles from '../../Home/HomeCtv/Debt/Debt.module.scss';
import PieChart from '../../Home/HomeCtv/Debt/PieChart';
import ExportExcelCN from '../../Home/HomeCtv/Debt/exportExcelCN';
import { useNavigate } from 'react-router-dom';
const cx = classNames.bind(styles);

const Debt = () => {
  const resultAPISIM = getCookie('token');
  const bgrDF = (resultAPISIM && resultAPISIM['background']?.split(',')[0]) || '#cf000e';
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const today = new Date();
  const date = today.getDate();
  const month = today.getMonth() + 1; // Lưu ý: Tháng bắt đầu từ 0 nên cần cộng thêm 1
  const year = today.getFullYear();
  const [resultDebt, setResultDebt] = useState([]);
  const [layoutCN, setLayoutCN] = useState(2);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpdateID, setIsUpdateID] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultDetail, setResultDetail] = useState({});
  const [isStatus, setIsStatus] = useState(0);
  const rowDf = {
    date_cn: `${year}-${month}-${date}`,
    sim: '',
    seller: '',
    price: 0,
    hotro: 0,
    gdh: 0,
    dichvu: 0,
    hoahong: 0,
    tong: 0,
    note: '',
    domain: hostname,
    status: 1,
  };

  const handleChange = (jsonValue, i) => {
    // console.log(jsonValue);
    setIsUpdate(true);
    setResultDebt((discount) =>
      discount.map((obj, index) => {
        if (index === i) {
          const total = parseInt(
            (jsonValue?.price || obj.price) -
              (jsonValue?.hotro || obj.hotro) -
              (jsonValue?.gdh || obj.gdh) -
              (jsonValue?.dichvu || obj.dichvu) -
              (jsonValue?.hoahong || obj.hoahong),
          );
          return { ...obj, ...jsonValue, tong: total };
        }
        return obj;
      }),
    );
  };

  const removePrice = (index) => {
    setResultDebt((prevData) => prevData.filter((_, i) => i !== index));
  };

  useEffect(() => {
    handleGetCNDate(`${thisYear}-${thisMonth + 1}`);
  }, []);
  const handleDefault = () => {
    setResultDebt([...resultDebt, rowDf]);
    setIsUpdate(true);
  };

  const handleAddCN = async () => {
    setLoading(true);
    const resultAPISIM = await addCongnoAll(resultDebt);
    if (resultAPISIM.message === 'Ok') {
      setIsUpdate(false);
      setIsUpdateID(false);
      setResultDebt([]);
      setLoading(false);
    } else {
      setIsUpdate(false);
      setLoading(false);
    }
  };
  const handleUpdateCN = async () => {
    setLoading(true);
    await updateCongnoID(resultDebt[0]);
    setIsUpdate(false);
    setIsUpdateID(false);
    setResultDebt([]);
    setLayoutCN(2);
    handleGetCNDate(`${thisYear}-${thisMonth + 1}`);
    setLoading(false);
  };
  const handleDeleteCN = async () => {
    setLoading(true);
    const arrData = { ...resultDebt[0], status: 3 };
    await deleCongno(arrData);
    setIsUpdate(false);
    setIsUpdateID(false);
    setResultDebt([]);
    setLayoutCN(2);
    handleGetCNDate(`${thisYear}-${thisMonth + 1}`);
    setLoading(false);
  };
  const handleGetCNDate = async (date, isStt = isStatus) => {
    setLoading(true);
    const data = { domain: hostname, date: date, status: isStt };
    const resultAPISIM = await getCongnoDate(data);
    if (resultAPISIM?.total?.length > 0) {
      setResultDetail(resultAPISIM);
      setLoading(false);
    } else {
      setResultDetail([]);
      setLoading(false);
    }
  };
  const getIDCN = async (id) => {
    // setLoading(true);
    const resultAPISIM = await getCongnoID(id);
    if (resultAPISIM?.length > 0) {
      setResultDebt(resultAPISIM);
      setIsUpdateID(true);
      setLayoutCN(1);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const weekDays = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];

  const todayObj = dayjs();

  const [dayObj, setDayObj] = useState(dayjs());

  const thisYear = dayObj.year();
  const thisMonth = dayObj.month(); // (January as 0, December as 11)
  const daysInMonth = dayObj.daysInMonth();

  const dayObjOf1 = dayjs(`${thisYear}-${thisMonth + 1}-1`);
  const weekDayOf1 = dayObjOf1.day(); // (Sunday as 0, Saturday as 6)

  const dayObjOfLast = dayjs(`${thisYear}-${thisMonth + 1}-${daysInMonth}`);
  const weekDayOfLast = dayObjOfLast.day();

  const handlePrev = async () => {
    setLoading(true);
    setDayObj(dayObj.subtract(1, 'month'));
    const date = dayObj.subtract(1, 'month');
    const data = { domain: hostname, date: `${date.year()}-${date.month() + 1}`, status: isStatus };
    const resultAPISIM = await getCongnoDate(data);
    if (resultAPISIM?.total?.length > 0) {
      setResultDetail(resultAPISIM);
      setLoading(false);
    } else {
      setLoading(false);
      setResultDetail([]);
    }
  };

  const handleNext = async () => {
    setLoading(true);
    setDayObj(dayObj.add(1, 'month'));
    const date = dayObj.add(1, 'month');
    const data = { domain: hostname, date: `${date.year()}-${date.month() + 1}`, status: isStatus };
    const resultAPISIM = await getCongnoDate(data);
    if (resultAPISIM?.total?.length > 0) {
      setResultDetail(resultAPISIM);
      setLoading(false);
    } else {
      setLoading(false);
      setResultDetail([]);
    }
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  const handleDate = (data) => {
    const [year, month, day] = data.split('-');
    return `${day}/${month}/${year}`;
  };
  return (
    <Grid container sx={{ pb: '90px', background: '#fff' }}>
      <Grid item xs={12} sx={{ m: 1 }}>
        <Grid container sx={{ mt: 1, pb: 1, borderBottom: '2px solid var(--home-color)' }}>
          <Stack direction={'row'} spacing={1}>
            <ButtonUI
              startIcon={<EditCalendarIcon />}
              bgrColorBtn={layoutCN === 1 ? bgrDF + '26' : '#F3F4F6'}
              colorText={layoutCN === 1 ? bgrDF : '#444444'}
              borderBtn={layoutCN === 1 ? bgrDF + '4a' : '#4444441f'}
              onClick={() => setLayoutCN(1)}
              style={{ whiteSpace: 'nowrap' }}
            >
              {!isUpdateID ? `NHẬP CÔNG NỢ ` : 'SỬA CÔNG NỢ'}
            </ButtonUI>
            <ButtonUI
              startIcon={<CalendarMonthIcon />}
              bgrColorBtn={layoutCN === 2 ? bgrDF + '26' : '#F3F4F6'}
              colorText={layoutCN === 2 ? bgrDF : '#444444'}
              borderBtn={layoutCN === 2 ? bgrDF + '4a' : '#4444441f'}
              onClick={() => {
                setLayoutCN(2);
                handleGetCNDate(`${thisYear}-${thisMonth + 1}`);
              }}
            >
              LỊCH
            </ButtonUI>
            <ButtonUI
              startIcon={<DonutSmallRoundedIcon />}
              bgrColorBtn={layoutCN === 3 ? bgrDF + '26' : '#F3F4F6'}
              colorText={layoutCN === 3 ? bgrDF : '#444444'}
              borderBtn={layoutCN === 3 ? bgrDF + '4a' : '#4444441f'}
              onClick={() => {
                setLayoutCN(3);
                handleGetCNDate(`${thisYear}-${thisMonth + 1}`);
              }}
              style={{ whiteSpace: 'nowrap' }}
            >
              BÁO CÁO
            </ButtonUI>
          </Stack>
        </Grid>
        {layoutCN === 1 &&
          resultDebt?.length > 0 &&
          resultDebt.map((dis, i) => (
            <Grid container spacing={1} key={i} sx={{ mt: 1, pb: 1, borderBottom: '1px dashed #ccc' }}>
              <Grid item xs={6} md={2} sx={{ mt: '-10px' }}>
                <LocalizationProvider adapterLocale="vi" dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={['DatePicker', 'MobileDatePicker', 'DesktopDatePicker', 'StaticDatePicker']}
                  >
                    <DemoItem>
                      <DesktopDatePicker
                        sx={{ border: '2px solid red', borderRadius: '6px' }}
                        defaultValue={dayjs(dis.date_cn)}
                        onChange={(e) => {
                          handleChange({ date_cn: `${e.$y}-${e.$M + 1}-${e.$D}` }, i);
                        }}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  label="Sim Số"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Sim:</InputAdornment>,
                  }}
                  color="success"
                  focused
                  value={dis.sim}
                  onChange={(e) => {
                    handleChange({ sim: e.target.value || '' }, i);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  label="Người Bán"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Tên:</InputAdornment>,
                  }}
                  color="success"
                  focused
                  value={dis?.seller}
                  onChange={(e) => {
                    handleChange({ seller: e.target.value || '' }, i);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  label="Doanh Thu"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₫:</InputAdornment>,
                  }}
                  color="success"
                  focused
                  value={numberFormat(dis?.price?.toString())}
                  onChange={(e) => {
                    handleChange({ price: parseInt(e.target.value.replace(/\./g, '')) || 0 }, i);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  label="Hỗ Trợ"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₫:</InputAdornment>,
                  }}
                  color="success"
                  focused
                  value={numberFormat(dis?.hotro?.toString())}
                  onChange={(e) => {
                    handleChange({ hotro: parseInt(e.target.value.replace(/\./g, '')) || 0 }, i);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  label="GDH"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₫:</InputAdornment>,
                  }}
                  color="success"
                  focused
                  value={numberFormat(dis?.gdh?.toString())}
                  onChange={(e) => {
                    handleChange({ gdh: parseInt(e.target.value.replace(/\./g, '')) || 0 }, i);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  label="Dịch Vụ"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₫:</InputAdornment>,
                  }}
                  color="success"
                  focused
                  value={numberFormat(dis?.dichvu?.toString())}
                  onChange={(e) => {
                    handleChange({ dichvu: parseInt(e.target.value.replace(/\./g, '')) || 0 }, i);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  label="Hoa Hồng Người Bán"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₫:</InputAdornment>,
                  }}
                  color="success"
                  focused
                  value={numberFormat(dis?.hoahong?.toString())}
                  onChange={(e) => {
                    handleChange({ hoahong: parseInt(e.target.value.replace(/\./g, '')) || 0 }, i);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  label="Ghi Chú"
                  color="success"
                  focused
                  value={dis?.note}
                  onChange={(e) => {
                    handleChange({ note: e.target.value || '' }, i);
                  }}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <FormControlLabel
                  control={
                    <Android12Switch
                      onChange={(e) => {
                        handleChange({ status: dis.status === 1 ? 2 : 1 }, i);
                      }}
                      defaultChecked={dis.status === 1 ? true : false}
                    />
                  }
                  label={dis.status === 1 ? 'Hoàn Thành' : 'Chưa Hoàn Thành'}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <h3 style={{ color: 'red', fontFamily: 'UTM-Avo' }}>
                  TỔNG DƯ:
                  <span style={{ marginLeft: 5, color: '#2e7d32' }}>
                    {numberFormat((dis.price - dis.hotro - dis.gdh - dis.dichvu - dis.hoahong).toString())} ₫
                  </span>
                </h3>
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
                {layoutCN === 1 && !isUpdateID && (
                  <Button variant="contained" onClick={() => removePrice(i)} size="small" color="error" sx={{ mr: 1 }}>
                    Xóa
                  </Button>
                )}
                {layoutCN === 1 && isUpdateID && (
                  <>
                    <Button variant="contained" onClick={handleDeleteCN} size="small" color="error" sx={{ mr: 1 }}>
                      Xóa
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleUpdateCN}
                      size="small"
                      color="warning"
                      disabled={loading}
                    >
                      Cập Nhật
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          ))}
        {layoutCN === 1 && !isUpdateID && (
          <Grid container spacing={1} sx={{ mt: 1 }}>
            {isUpdate && (
              <Grid item xs={6} md={3}>
                <Button
                  variant="contained"
                  onClick={handleAddCN}
                  size="small"
                  color="error"
                  fullWidth
                  disabled={loading}
                >
                  Lưu Công Nợ
                </Button>
              </Grid>
            )}
            <Grid item xs={6} md={3}>
              <Button variant="contained" onClick={handleDefault} size="small" color="success" fullWidth>
                Tạo Công Nợ Mới
              </Button>
            </Grid>
          </Grid>
        )}

        {layoutCN === 2 && (
          <>
            <Grid container spacing={1} justifyContent={'center'}>
              <Grid item xs={12} md={8}>
                <div className={cx('calendar')}>
                  <div className={cx('header')}>
                    <button
                      type="button"
                      className={cx('nav', 'nav--prev')}
                      onClick={() => {
                        handlePrev();
                      }}
                    >
                      &lt;
                    </button>
                    <div className={cx('datetime')}>{dayObj.locale('vi').format('MMM/YYYY')}</div>
                    <button
                      type="button"
                      className={cx('nav', 'nav--prev')}
                      onClick={() => {
                        handleNext();
                      }}
                    >
                      &gt;
                    </button>
                  </div>
                  <div className={cx('week-container')}>
                    {weekDays.map((d) => (
                      <div className={cx('week-cell', 'bgr-week')} key={d}>
                        {d}
                      </div>
                    ))}
                  </div>
                  <div className={cx('day-container')}>
                    {range(weekDayOf1).map((i) => (
                      <div className={cx('day-cell', 'day-cell--faded')} key={i}>
                        {dayObjOf1.subtract(weekDayOf1 - i, 'day').date()}
                      </div>
                    ))}

                    {range(daysInMonth).map((i) => (
                      <div
                        className={cx(
                          `day-cell`,
                          `day-cell--in-month${
                            i + 1 === todayObj.date() && thisMonth === todayObj.month() && thisYear === todayObj.year()
                              ? 'day-cell--today'
                              : ''
                          }`,
                          `${
                            i + 1 === todayObj.date() && thisMonth === todayObj.month() && thisYear === todayObj.year()
                              ? 'active-day'
                              : ''
                          }`,
                        )}
                        key={i}
                      >
                        <span className={cx('dis-price')}>
                          {i + 1} <br />{' '}
                          <b className={cx('note-price')}>
                            {numberFormatTextShorten(
                              resultDetail?.total?.find(
                                (item) => item.date_cn === `${thisYear}-${thisMonth + 1}-${i + 1}`,
                              )?.loinhuan,
                            )}
                          </b>
                        </span>
                      </div>
                    ))}

                    {range(6 - weekDayOfLast).map((i) => (
                      <div className={cx('day-cell', 'day-cell--faded')} key={i}>
                        {dayObjOfLast.add(i + 1, 'day').date()}
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent={'center'} sx={{ mt: 1 }} className={cx('font-avo')}>
              <Grid item xs={12} md={8}>
                <Stack direction={'row'} spacing={3} justifyContent={'space-between'}>
                  <Stack direction={'column'} spacing={0.5} alignItems={'center'}>
                    <span>Doanh số</span>
                    <b className={cx('ds-color')}>{numberFormat(resultDetail?.totalAll?.doanhthu?.toString())}₫</b>
                  </Stack>
                  <Stack direction={'column'} spacing={0.5} alignItems={'center'}>
                    <span>Dịch vụ</span>
                    <b className={cx('ct-color')}>{numberFormat(resultDetail?.totalAll?.dichvusim.toString())}₫</b>
                  </Stack>
                  <Stack direction={'column'} spacing={0.5} alignItems={'center'}>
                    <span>Tổng</span>
                    <b className={cx('ds-color')}>+{numberFormat(resultDetail?.totalAll?.loinhuan.toString())}₫</b>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }} className={cx('font-avo')}>
              <Grid item xs={12} md={8} sx={{ pb: 1, borderBottom: '2px solid var(--home-color)' }}>
                <span style={{ marginBottom: 5 }}>
                  <b> Lọc theo tiêu chí:</b>
                </span>
                <Stack direction={'row'} spacing={1} sx={{ mt: 0.5 }}>
                  <ButtonUI
                    startIcon={<SentimentSatisfiedAltIcon />}
                    bgrColorBtn={isStatus === 1 ? bgrDF + '26' : '#F3F4F6'}
                    colorText={isStatus === 1 ? bgrDF : '#444444'}
                    borderBtn={isStatus === 1 ? bgrDF + '4a' : '#4444441f'}
                    onClick={() => {
                      handleGetCNDate(`${thisYear}-${thisMonth + 1}`, isStatus === 1 ? 0 : 1);
                      setIsStatus(isStatus === 1 ? 0 : 1);
                    }}
                  >
                    HOÀN THÀNH{' '}
                    {resultDetail?.detail?.length > 0 && isStatus === 1 ? `(${resultDetail?.detail?.length})` : ``}
                  </ButtonUI>
                  <ButtonUI
                    startIcon={<SentimentVeryDissatisfiedIcon />}
                    bgrColorBtn={isStatus === 2 ? bgrDF + '26' : '#F3F4F6'}
                    colorText={isStatus === 2 ? bgrDF : '#444444'}
                    borderBtn={isStatus === 2 ? bgrDF + '4a' : '#4444441f'}
                    onClick={() => {
                      handleGetCNDate(`${thisYear}-${thisMonth + 1}`, isStatus === 2 ? 0 : 2);
                      setIsStatus(isStatus === 2 ? 0 : 2);
                    }}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    CHƯA HOÀN THÀNH{' '}
                    {resultDetail?.detail?.length > 0 && isStatus === 2 ? `(${resultDetail?.detail?.length})` : ``}
                  </ButtonUI>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent={'center'} sx={{ mt: 1 }} className={cx('font-avo')}>
              <Grid item xs={12} md={8}>
                {resultDetail?.total?.map((lich, i) => (
                  <Grid item xs={12} key={i} sx={{ mb: 1 }}>
                    <Stack
                      direction={'row'}
                      spacing={1}
                      justifyContent={'space-between'}
                      className={cx('cus-detail-name')}
                    >
                      <span>
                        {handleDate(lich.date_cn)}(
                        {dayjs(`${lich.date_cn}`).day() > 0
                          ? `Th${parseInt(dayjs(`${lich.date_cn}`).day()) + 1}`
                          : 'CN'}
                        )
                      </span>
                      <span>{numberFormat(lich.loinhuan.toString())}₫</span>
                    </Stack>
                    {resultDetail?.detail?.map(
                      (row, index) =>
                        row.date_cn === lich.date_cn && (
                          <Stack
                            direction={'column'}
                            spacing={1}
                            justifyContent={'space-between'}
                            className={cx('cus-detail-name-1')}
                            key={index}
                          >
                            <Stack
                              direction={'row'}
                              spacing={1}
                              justifyContent={'space-between'}
                              onClick={() => {
                                getIDCN(row.id);
                              }}
                            >
                              <span className={cx('dis-center')}>
                                <SimCardIcon sx={{ color: row.status === 1 ? '#1abc9c' : 'red' }} /> {row.sim}{' '}
                                <small>{row.note ? `(${row.note})` : ''}</small>
                              </span>
                              <span>
                                {numberFormat(row.tong.toString())}₫ {'>'}
                              </span>
                            </Stack>
                            {(row.hotro > 0 ||
                              row.gdh > 0 ||
                              row.dichvu > 0 ||
                              row.hoahong > 0 ||
                              row.hoahong > 0 ||
                              row.status === 2 ||
                              row.seller !== '') && (
                              <>
                                <Hidden only={['md', 'lg', 'xl']}>
                                  <div className={cx('box-list-subcate')}>
                                    <div className={cx('list-subcate')}>
                                      <Stack direction={'row'} spacing={1} justifyContent={'flex-start'}>
                                        {row.status === 2 && (
                                          <ButtonUI
                                            bgrColorBtn={row.status === 2 ? '#fddddd' : '#cefffa'}
                                            colorText={row.status === 2 ? '#d40000' : '#008c7f'}
                                            borderBtn={row.status === 2 ? '#d40000' : '#008c7f'}
                                            borderRadius={6}
                                            small
                                          >
                                            {`Chưa hoàn thành`}
                                          </ButtonUI>
                                        )}
                                        {row.seller !== '' && (
                                          <ButtonUI
                                            bgrColorBtn={'#F3F4F6'}
                                            colorText={'#444444'}
                                            borderBtn={'#4444441f'}
                                            borderRadius={6}
                                            small
                                          >
                                            {`CTV: ${row.seller}`}
                                          </ButtonUI>
                                        )}
                                        {row.hotro > 0 && (
                                          <ButtonUI
                                            bgrColorBtn={'#F3F4F6'}
                                            colorText={'#444444'}
                                            borderBtn={'#4444441f'}
                                            borderRadius={6}
                                            small
                                          >
                                            {`HTrợ: ${numberFormatText(row.hotro)}`}
                                          </ButtonUI>
                                        )}

                                        {row.gdh > 0 && (
                                          <ButtonUI
                                            bgrColorBtn={'#F3F4F6'}
                                            colorText={'#444444'}
                                            borderBtn={'#4444441f'}
                                            borderRadius={6}
                                            small
                                          >
                                            {`GDH: ${numberFormatText(row.gdh)}`}
                                          </ButtonUI>
                                        )}
                                        {row.dichvu > 0 && (
                                          <ButtonUI
                                            bgrColorBtn={'#F3F4F6'}
                                            colorText={'#444444'}
                                            borderBtn={'#4444441f'}
                                            borderRadius={6}
                                            small
                                          >
                                            {`DVụ: ${numberFormatText(row.dichvu)}`}
                                          </ButtonUI>
                                        )}
                                        {row.hoahong > 0 && (
                                          <ButtonUI
                                            bgrColorBtn={'#F3F4F6'}
                                            colorText={'#444444'}
                                            borderBtn={'#4444441f'}
                                            borderRadius={6}
                                            small
                                          >
                                            {`HHồng: ${numberFormatText(row.hoahong)}`}
                                          </ButtonUI>
                                        )}
                                      </Stack>
                                    </div>
                                  </div>
                                </Hidden>
                                <Hidden only={['sm', 'xs']}>
                                  <div className={cx('box-list-subcate')}>
                                    <div className={cx('list-subcate')}>
                                      <Stack direction={'row'} spacing={1} justifyContent={'flex-start'}>
                                        {row.status === 2 && (
                                          <ButtonUI
                                            bgrColorBtn={row.status === 2 ? '#fddddd' : '#cefffa'}
                                            colorText={row.status === 2 ? '#d40000' : '#008c7f'}
                                            borderBtn={row.status === 2 ? '#d40000' : '#008c7f'}
                                          >
                                            {`Chưa hoàn thành`}
                                          </ButtonUI>
                                        )}
                                        {row.seller !== '' && (
                                          <ButtonUI
                                            bgrColorBtn={'#F3F4F6'}
                                            colorText={'#444444'}
                                            borderBtn={'#4444441f'}
                                          >
                                            {`CTV: ${row.seller}`}
                                          </ButtonUI>
                                        )}
                                        {row.hotro > 0 && (
                                          <ButtonUI
                                            bgrColorBtn={'#F3F4F6'}
                                            colorText={'#444444'}
                                            borderBtn={'#4444441f'}
                                          >
                                            {`HTrợ: ${numberFormatText(row.hotro)}`}
                                          </ButtonUI>
                                        )}

                                        {row.gdh > 0 && (
                                          <ButtonUI
                                            bgrColorBtn={'#F3F4F6'}
                                            colorText={'#444444'}
                                            borderBtn={'#4444441f'}
                                          >
                                            {`GDH: ${numberFormatText(row.gdh)}`}
                                          </ButtonUI>
                                        )}
                                        {row.dichvu > 0 && (
                                          <ButtonUI
                                            bgrColorBtn={'#F3F4F6'}
                                            colorText={'#444444'}
                                            borderBtn={'#4444441f'}
                                          >
                                            {`DVụ: ${numberFormatText(row.dichvu)}`}
                                          </ButtonUI>
                                        )}
                                        {row.hoahong > 0 && (
                                          <ButtonUI
                                            bgrColorBtn={'#F3F4F6'}
                                            colorText={'#444444'}
                                            borderBtn={'#4444441f'}
                                          >
                                            {`HHồng: ${numberFormatText(row.hoahong)}`}
                                          </ButtonUI>
                                        )}
                                      </Stack>
                                    </div>
                                  </div>
                                </Hidden>
                              </>
                            )}
                          </Stack>
                        ),
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}

        {layoutCN === 3 && (
          <>
            <Grid container spacing={1} justifyContent={'center'}>
              <Grid item xs={12} md={8}>
                <div className={cx('calendar')}>
                  <div className={cx('header')}>
                    <button
                      type="button"
                      className={cx('nav', 'nav--prev')}
                      onClick={() => {
                        handlePrev();
                      }}
                    >
                      &lt;
                    </button>
                    <div className={cx('datetime')}>{dayObj.locale('vi').format('MMM/YYYY')}</div>
                    <button
                      type="button"
                      className={cx('nav', 'nav--prev')}
                      onClick={() => {
                        handleNext();
                      }}
                    >
                      &gt;
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent={'center'}>
              <Grid item xs={12} md={6}>
                <Stack direction={'row'} justifyContent={'space-around'} sx={{ mt: 1 }}>
                  {(resultDetail?.totalAll?.dichvusim || resultDetail?.totalAll?.loinhuan) && (
                    <PieChart data={[resultDetail?.totalAll?.dichvusim || 0, resultDetail?.totalAll?.loinhuan || 0]} />
                  )}
                  <Stack direction={'column'} justifyContent={'center'}>
                    <span style={{ whiteSpace: 'nowrap' }} className={cx('dis-center')}>
                      <SquareIcon sx={{ color: '#1abc9c' }} />
                      Lợi nhuận (
                      {(
                        (parseInt(resultDetail?.totalAll?.loinhuan) / resultDetail?.totalAll?.doanhthu || 0) * 100
                      ).toFixed(2)}{' '}
                      %)
                    </span>
                    <span style={{ whiteSpace: 'nowrap' }} className={cx('dis-center')}>
                      <SquareIcon sx={{ color: '#bf4040' }} />
                      Dịch vụ (
                      {(
                        (parseInt(resultDetail?.totalAll?.dichvusim) / resultDetail?.totalAll?.doanhthu || 0) * 100
                      ).toFixed(2)}
                      %)
                    </span>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent={'center'} sx={{ mt: 1 }} className={cx('font-avo')}>
              <Grid item xs={12} md={8}>
                <Stack direction={'row'} spacing={2} justifyContent={'space-evenly'} className={cx('chart-report')}>
                  <Stack direction={'column'} spacing={0.5} alignItems={'center'}>
                    <span>Doanh số</span>
                    <b className={cx('ds-color')}>{numberFormat(resultDetail?.totalAll?.doanhthu?.toString())}₫</b>
                  </Stack>
                  <Stack direction={'column'} spacing={0.5} alignItems={'center'}>
                    <span>Dịch vụ</span>
                    <b className={cx('ct-color')}>{numberFormat(resultDetail?.totalAll?.dichvusim.toString())}₫</b>
                  </Stack>
                  <Stack direction={'column'} spacing={0.5} alignItems={'center'}>
                    <span>Lợi nhuận</span>
                    <b className={cx('ds-color')}>+{numberFormat(resultDetail?.totalAll?.loinhuan.toString())}₫</b>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }} className={cx('font-avo')}>
              <Grid item xs={12} md={8}>
                <span style={{ marginBottom: 5 }}>
                  <b> Lọc theo tiêu chí:</b>
                </span>
                <Stack direction={'row'} spacing={1} sx={{ mt: 0.5 }}>
                  <ButtonUI
                    startIcon={<SentimentSatisfiedAltIcon />}
                    bgrColorBtn={isStatus === 1 ? bgrDF + '26' : '#F3F4F6'}
                    colorText={isStatus === 1 ? bgrDF : '#444444'}
                    borderBtn={isStatus === 1 ? bgrDF + '4a' : '#4444441f'}
                    onClick={() => {
                      handleGetCNDate(`${thisYear}-${thisMonth + 1}`, isStatus === 1 ? 0 : 1);
                      setIsStatus(isStatus === 1 ? 0 : 1);
                    }}
                  >
                    HOÀN THÀNH{' '}
                    {resultDetail?.detail?.length > 0 && isStatus === 1 ? `(${resultDetail?.detail?.length})` : ``}
                  </ButtonUI>
                  <ButtonUI
                    startIcon={<SentimentVeryDissatisfiedIcon />}
                    bgrColorBtn={isStatus === 2 ? bgrDF + '26' : '#F3F4F6'}
                    colorText={isStatus === 2 ? bgrDF : '#444444'}
                    borderBtn={isStatus === 2 ? bgrDF + '4a' : '#4444441f'}
                    onClick={() => {
                      handleGetCNDate(`${thisYear}-${thisMonth + 1}`, isStatus === 2 ? 0 : 2);
                      setIsStatus(isStatus === 2 ? 0 : 2);
                    }}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    CHƯA HOÀN THÀNH{' '}
                    {resultDetail?.detail?.length > 0 && isStatus === 2 ? `(${resultDetail?.detail?.length})` : ``}
                  </ButtonUI>
                </Stack>
              </Grid>
            </Grid>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }} className={cx('font-avo')}>
              <Grid item xs={12} md={8}>
                {/* <span style={{ marginBottom: 5 }}>
                          <b> Xuất File Excel:</b>
                        </span> */}
                <Stack direction={'row'} spacing={1} sx={{ mt: 0.5 }}>
                  <ExportExcelCN dataSim={resultDetail} date={`${thisMonth + 1}-${thisYear}`} />
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};
export default Debt;
