export const bgrCateSim = [
  '#fee2e2',
  '#ffedd5',
  '#fef9c3',
  '#ecfccb',
  '#d1fae5',
  '#e0f2fe',
  '#e0e7ff',
  '#ede9fe',
  '#fae8ff',
  '#ccfbf1',
  '#fbfbfb',
  '#fdb4b4',
  '#fee2e2',
  '#ffedd5',
  '#fef9c3',
  '#ecfccb',
  '#d1fae5',
  '#e0f2fe',
  '#e0e7ff',
  '#ede9fe',
  '#fae8ff',
  '#ccfbf1',
  '#fbfbfb',
];
