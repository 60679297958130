import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getLocalStorage, numberFormat, setLocalStorage } from '../../../utils/helper';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { listUserID } from '../../../services/apiUsers';
import { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import classNames from 'classnames/bind';
import styles from '../DetailHome/DetailHome.module.scss';
import { ButtonUI } from '../../../component/CustomerMUI';
import { useNavigate } from 'react-router-dom';
import { sendMailShop } from '../../../services/apiWinServer';
const cx = classNames.bind(styles);

const getTotalPrice = (data) => {
  let totalPrice = 0;

  data.forEach((item) => {
    if (item.price && !isNaN(item.price.replace('.', ''))) {
      totalPrice += parseFloat(item.price.replace('.', ''));
    }
  });

  return totalPrice;
};
export default function Cart() {
  let arrShop = getLocalStorage('addShop') || [];
  const [resultCart, setResultCart] = useState(arrShop);
  const hostname = window.location.hostname;
  const contextApiUser = useContext(ThemeContext);
  const total = getTotalPrice(arrShop);
  const [authDomain, setAuthDomain] = useState({});
  const [statusOrder, setStatusOrder] = useState(false);
  const [nameUser, setNameUser] = useState('');
  const [phoneUser, setPhoneUser] = useState('');
  const [addressUser, setAddressUser] = useState('');
  const [noteUser, setNoteUser] = useState('');
  const [messError, setMessError] = useState('');
  const navigate = useNavigate();

  const fetchApiUserID = async () => {
    const resultAPISIM = await listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setLocalStorage('khoso', resultAPISIM[0]);
      setAuthDomain(resultAPISIM[0]);
      contextApiUser.toggleDataUser(resultAPISIM[0]);
    }
  };
  const removePrice = (index) => {
    const newData = arrShop.slice();
    newData.splice(index, 1);
    setLocalStorage('addShop', newData);
    setResultCart(newData);
  };

  useEffect(() => {
    if (getLocalStorage('khoso')?.link_domain === hostname) {
      contextApiUser.toggleDataUser(getLocalStorage('khoso'));
      setAuthDomain(getLocalStorage('khoso'));
    } else {
      fetchApiUserID();
    }
    document.title = 'Giỏ hàng';
  }, []);

  const fetchApiMail = async () => {
    const text = resultCart
      .map(
        (item) =>
          `<br/>✔️${item.cate === 'sim' ? `sim` : ''} ${item.title} ${
            item.cate === 'sp' ? `- ${item.code}` : ''
          } - ${numberFormat(item.price)}`,
      )
      .join('');
    const mail = {
      mail: authDomain['GMAIL_NOTIFI'],
      nameuser: nameUser,
      namesanpham: text,
      masanpham: '',
      loaisanpham: '',
      phoneuser: phoneUser,
      addressuser: addressUser,
      price: numberFormat(total),
      noteuser: noteUser,
    };
    const resultAPI = await sendMailShop(mail);
    if (resultAPI.success === 'OK') {
      setNameUser('');
      setPhoneUser('');
      setAddressUser('');
      setNoteUser('');
      setStatusOrder(false);
      setLocalStorage('addShop', []);
      setResultCart([]);
      navigate('/camon', { replace: true });
    }
    console.log(resultAPI);
  };

  const numberFormat = (number) => {
    let calcNumber;
    if (isNaN(number)) {
      calcNumber = number;
    } else {
      calcNumber = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);
    }
    return calcNumber;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nameUser.length < 4) {
      setMessError('Bạn nhập chưa đầy đủ họ tên');
    } else if (phoneUser.length !== 10) {
      setMessError('Bạn nhập chưa đúng số điện thoại liên hệ');
    } else if (addressUser.length < 15) {
      setMessError('Bạn nhập chưa đầy đủ địa chỉ ');
    } else {
      fetchApiMail();
      setStatusOrder(true);
    }
  };

  const handleUserName = (e) => {
    setNameUser(e.target.value);
    if (e.target.value.length >= 4) {
      setMessError('');
    }
  };

  const handlePhoneUser = (e) => {
    setPhoneUser(e.target.value);
    if (e.target.value.length === 10) {
      setMessError('');
    }
  };

  const handleAddressUser = (e) => {
    setAddressUser(e.target.value);
    if (e.target.value.length >= 15) {
      setMessError('');
    }
  };

  const handleClose = () => {
    navigate(-1);
    setNameUser('');
    setPhoneUser('');
    setAddressUser('');
    setNoteUser('');
    setMessError('');
  };

  const form = useRef();
  return (
    <Container sx={{ p: 0, pb: '90px', mt: 2 }}>
      <TableContainer component={Paper}>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <h2 style={{ fontFamily: 'UTM-Avo' }}>SẢN PHẨM GIỎ HÀNG</h2>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b style={{ fontFamily: 'UTM-Avo' }}>Tên Sản Phẩm</b>
              </TableCell>
              <TableCell align="center">
                <b style={{ fontFamily: 'UTM-Avo' }}>Số Lượng</b>
              </TableCell>
              <TableCell align="right">
                <b style={{ fontFamily: 'UTM-Avo' }}>Giá</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resultCart?.map((row, i) => (
              <TableRow key={i}>
                <TableCell style={{ fontFamily: 'UTM-Avo', width: '30vw', whiteSpace: 'normal' }}>
                  <div>{row.cate === 'sim' ? 'sim ' + row.title : row.title}</div>
                </TableCell>
                <TableCell align="center" style={{ fontFamily: 'UTM-Avo', width: '10vw', whiteSpace: 'normal' }}>
                  {row.soluong}
                </TableCell>
                <TableCell align="right" style={{ fontFamily: 'UTM-Avo' }}>
                  {numberFormat(row.price)}
                </TableCell>
                <TableCell align="right">
                  <DeleteForeverRoundedIcon color="error" onClick={() => removePrice(i)} sx={{ cursor: 'pointer' }} />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="right"></TableCell>
              <TableCell align="right">
                <b style={{ fontFamily: 'UTM-Avo' }}>Tổng</b>
              </TableCell>
              <TableCell align="right">
                <b style={{ color: 'red', fontFamily: 'UTM-Avo' }}>{numberFormat(total)}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {resultCart.length > 0 && authDomain['GMAIL_NOTIFI'] && (
        <Grid item sx={{ mt: 1 }}>
          <Paper elevation={2}>
            <Card variant="outlined">
              <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                <div className={cx('order-sim')}>
                  <h2>Đặt mua sản phẩm </h2>
                  {/* <h3>- Miễn phí giao sim toàn quốc</h3>
                      <h3>- Nhận sim kiểm tra xong mới phải trả tiền</h3> */}
                </div>
              </CardContent>
            </Card>

            <Card variant="outlined">
              <CardContent sx={{ p: 0, '&:last-child': { pb: 0 }, mt: 1 }}>
                <form ref={form} onSubmit={handleSubmit} className={cx('order-sim-input')}>
                  <TextField
                    defaultValue={resultCart.map((item) => `${item.title} - ${numberFormat(item.price)}`).join('-')}
                    id="outlined-basic"
                    fullWidth
                    label="Sim mua"
                    variant="outlined"
                    name="sim"
                    type="text"
                    sx={{ display: 'none' }}
                  />
                  <TextField
                    defaultValue={nameUser}
                    onChange={handleUserName}
                    id="outlined-basic"
                    fullWidth
                    label="Họ và Tên"
                    variant="outlined"
                    name="userName"
                    type="text"
                    required
                    error={nameUser.length < 4}
                  />
                  <TextField
                    defaultValue={phoneUser}
                    onChange={handlePhoneUser}
                    id="outlined-basic"
                    fullWidth
                    label="Số điện thoại liên hệ"
                    variant="outlined"
                    name="phoneUser"
                    type="number"
                    required
                    error={phoneUser.length < 10}
                  />
                  <TextField
                    defaultValue={addressUser}
                    onChange={handleAddressUser}
                    id="outlined-basic"
                    fullWidth
                    label="Địa chỉ giao hàng"
                    variant="outlined"
                    name="addressUser"
                    type="text"
                    required
                    error={addressUser.length < 15}
                  />
                  <TextField
                    defaultValue={noteUser}
                    onChange={(e) => setNoteUser(e.target.value)}
                    id="outlined-basic"
                    fullWidth
                    label="Yêu cầu khác (Không bắt buộc)"
                    variant="outlined"
                    name="noteUser"
                    type="text"
                  />
                  <TextField
                    defaultValue={numberFormat(total)}
                    id="outlined-basic"
                    fullWidth
                    label="Tổng tiền trả khi nhận sim"
                    variant="outlined"
                    name="price"
                    type="text"
                    sx={{ display: 'none' }}
                  />
                  <div> </div>
                  <Typography variant="h5" color="error">
                    <b>{messError}</b>
                  </Typography>
                  <ButtonUI
                    sx={{ p: 1, fontSize: '19px' }}
                    color="error"
                    borderBtn="#ff0000"
                    borderRadius={4}
                    fullWidth
                    disabled={statusOrder}
                    type="submit"
                  >
                    Đặt Mua
                  </ButtonUI>
                </form>
                <div className={cx('order-sim-input')}>
                  {statusOrder && <LinearProgress color="error" />}
                  <ButtonUI sx={{ fontSize: '19px' }} borderRadius={4} fullWidth>
                    {authDomain['HOTTLINE_SIM'] ? ` Hoặc Gọi mua hàng: ${authDomain['HOTTLINE_SIM']}` : ''}
                  </ButtonUI>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <b>HƯỚNG DẪN MUA HÀNG</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={cx('guide-order-sim')}>
                        <p>
                          <b>Bước 1: ĐẶT HÀNG</b>
                          <br />
                          Quý khách điền đầy đủ thông tin và đặt mua{' '}
                          {authDomain['HOTTLINE_SIM'] ? `, hoặc liên hệ ${authDomain['HOTTLINE_SIM']}` : ``} để được đặt
                          hàng.
                        </p>
                        <p>
                          <b>Bước 2: XÁC NHẬN ĐƠN HÀNG</b>
                          <br />
                          Khi nhận được đơn hàng của quý khách, nhân viên bán hàng sẽ kiểm tra và gọi điện lại báo cho
                          quý khách xác nhận đơn hàng.
                        </p>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      )}
    </Container>
  );
}
