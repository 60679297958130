import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function SimpleSnackbar({ Notifi = '' }) {
  return (
    <div>
      <Snackbar open={true} autoHideDuration={6000} message={Notifi} />
    </div>
  );
}
