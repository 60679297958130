import { useEffect, useRef, useState } from 'react';
import HomeTwo from './HomeTwo';
import Hopmenh from '../../HopMenh/Hopmenh';
function HomeThree() {
  const search = window.location.search;
  const pathname = window.location.pathname;

  return (
    <>{(search.length === 0 || search.indexOf('fbclid') !== -1) && pathname.length === 1 ? <Hopmenh /> : <HomeTwo />}</>
  );
}

export default HomeThree;
