import { numberFormatDeleteDots } from '../../../utils/helper';
import { LogicCategory } from './LogicCategory';
import { LogicMenhSim } from './LogicMenhSim';
import { LogicTranhSo } from './LogicTranhSo';
import { LogicTotalDauDit } from './LogicTotalDauDit';
import { LogicNetWork } from './LogicNetWork';

export const LogicSearch = (
  simResult,
  setSimDataSort,
  actionNetWork,
  actionBtnSort,
  actionRangePrice,
  actionMenuCate,
  textSearch,
  actionTranhso,
  isSttTranhso,
  actionSimStart,
  actionSimEnd,
) => {
  const filterAllSim = [...simResult]
    .filter((data) => {
      if (actionNetWork) {
        let dataSimNW = LogicNetWork(data.simSearch, data.netWork)?.includes(actionNetWork);

        return dataSimNW;
      } else {
        return data;
      }
    })
    .filter((dataCate) => {
      if (actionMenuCate) {
        if (actionMenuCate.includes('dd-')) {
          let dataSim = LogicCategory(dataCate.simSearch, actionMenuCate, true)?.includes(actionMenuCate);
          return dataSim;
        } else if (
          actionMenuCate === 'menh-kim' ||
          actionMenuCate === 'menh-moc' ||
          actionMenuCate === 'menh-thuy' ||
          actionMenuCate === 'menh-hoa' ||
          actionMenuCate === 'menh-tho'
        ) {
          const menhSim =
            actionMenuCate === 'menh-kim'
              ? 'Kim'
              : actionMenuCate === 'menh-moc'
              ? 'Mộc'
              : actionMenuCate === 'menh-thuy'
              ? 'Thủy'
              : actionMenuCate === 'menh-hoa'
              ? 'Hỏa'
              : 'Thổ';
          let dataSim = LogicMenhSim(dataCate.simSearch)?.includes(menhSim);
          return dataSim;
        } else if (
          actionMenuCate === 'phong-thuy' ||
          actionMenuCate === 'than-tai' ||
          actionMenuCate === 'loc-phat' ||
          actionMenuCate === 'phat-loc' ||
          actionMenuCate === 'tho-dia' ||
          actionMenuCate === 'taxi' ||
          actionMenuCate === 'taxi-tien' ||
          actionMenuCate === 'sanh-tien' ||
          actionMenuCate === 'tam-hoa' ||
          actionMenuCate === 'tu-quy' ||
          actionMenuCate === 'tu-quy-giua' ||
          actionMenuCate === 'ngu-quy-giua' ||
          actionMenuCate === 'nam-sinh'
        ) {
          let dataSim = LogicCategory(dataCate.simSearch, actionMenuCate)?.includes(actionMenuCate);
          return dataSim;
        } else {
          let dataSim = dataCate.cateSims?.split(',').includes(actionMenuCate);
          return dataSim;
        }
      } else {
        return dataCate;
      }
    })
    .filter((dataSearch) => {
      if (textSearch) {
        let dataSimSearch;
        const checkCountSim = textSearch.indexOf('*');
        if (checkCountSim >= 0) {
          const checkStartSim = textSearch.slice(0, checkCountSim);
          const checkEndSim = textSearch.slice(checkCountSim + 1);
          const checkCountSimBetWeen = checkEndSim.indexOf('*');
          const checkSimBetWeen = textSearch.slice(checkCountSim + 1, checkCountSimBetWeen + checkCountSim + 1);
          if (checkSimBetWeen) {
            const checkSimBetWeenEndSim = textSearch.slice(checkCountSimBetWeen + checkCountSim + 2);
            checkSimBetWeenEndSim
              ? (dataSimSearch =
                  dataSearch.simSearch.slice(0, checkCountSim) === checkStartSim &&
                  dataSearch.simSearch
                    .slice(checkCountSim, 10 - checkSimBetWeenEndSim.length)
                    .includes(checkSimBetWeen) &&
                  dataSearch.simSearch.slice(-checkSimBetWeenEndSim.length) === checkSimBetWeenEndSim)
              : (dataSimSearch =
                  dataSearch.simSearch.slice(0, checkCountSim) === checkStartSim &&
                  dataSearch.simSearch
                    .slice(checkCountSim, 10 - checkSimBetWeenEndSim.length)
                    .includes(checkSimBetWeen));
          }
          if (checkStartSim && checkEndSim && !checkSimBetWeen) {
            dataSimSearch =
              dataSearch.simSearch.slice(0, checkCountSim) === checkStartSim &&
              dataSearch.simSearch.slice(-checkEndSim.length) === checkEndSim;
          }
          if (checkStartSim && !checkEndSim && !checkSimBetWeen) {
            dataSimSearch = dataSearch.simSearch?.slice(0, checkCountSim) === checkStartSim;
          }
        } else {
          dataSimSearch = dataSearch.simSearch?.slice(-textSearch.length) === textSearch;
        }
        return dataSimSearch;
      } else {
        return dataSearch;
      }
    })
    .filter((dataRangePrice) => {
      if (actionRangePrice === 1) {
        return dataRangePrice;
      } else if (actionRangePrice === 2) {
        return numberFormatDeleteDots(dataRangePrice.price) < 1000000;
      } else if (actionRangePrice === 3) {
        return (
          numberFormatDeleteDots(dataRangePrice.price) >= 1000000 &&
          numberFormatDeleteDots(dataRangePrice.price) <= 2000000
        );
      } else if (actionRangePrice === 4) {
        return (
          numberFormatDeleteDots(dataRangePrice.price) >= 2000000 &&
          numberFormatDeleteDots(dataRangePrice.price) <= 3000000
        );
      } else if (actionRangePrice === 5) {
        return (
          numberFormatDeleteDots(dataRangePrice.price) >= 3000000 &&
          numberFormatDeleteDots(dataRangePrice.price) <= 5000000
        );
      } else if (actionRangePrice === 6) {
        return (
          numberFormatDeleteDots(dataRangePrice.price) >= 5000000 &&
          numberFormatDeleteDots(dataRangePrice.price) <= 10000000
        );
      } else if (actionRangePrice === 7) {
        return (
          numberFormatDeleteDots(dataRangePrice.price) >= 10000000 &&
          numberFormatDeleteDots(dataRangePrice.price) <= 20000000
        );
      } else if (actionRangePrice === 8) {
        return (
          numberFormatDeleteDots(dataRangePrice.price) >= 20000000 &&
          numberFormatDeleteDots(dataRangePrice.price) <= 50000000
        );
      } else if (actionRangePrice === 9) {
        return numberFormatDeleteDots(dataRangePrice.price) > 50000000;
      }
    })
    .filter((dataTranhSo) => {
      if (actionTranhso.length > 0) {
        if (parseInt(isSttTranhso) === 1) {
          let sausocuoi = dataTranhSo.simSearch.slice(-6);
          let dataSimTS = LogicTranhSo(sausocuoi, actionTranhso);
          return dataSimTS;
        } else {
          let dataSimTS = LogicTranhSo(dataTranhSo.simSearch, actionTranhso);
          return dataSimTS;
        }
      } else {
        return dataTranhSo;
      }
    })
    .filter((dataTotalSimStart) => {
      if (actionSimStart['sodau']) {
        const resultSimStart = LogicTotalDauDit(
          dataTotalSimStart.simSearch,
          actionSimStart['sodau'],
          actionSimStart['totalSoDau'],
          '',
          '',
        );
        return resultSimStart[0];
      } else {
        return dataTotalSimStart;
      }
    })
    .filter((dataTotalSimEnd) => {
      if (actionSimEnd['socuoi']) {
        const resultSimEnd = LogicTotalDauDit(
          dataTotalSimEnd.simSearch,
          '',
          '',
          actionSimEnd['socuoi'],
          actionSimEnd['totalSoCuoi'],
        );
        return resultSimEnd[1];
      } else {
        return dataTotalSimEnd;
      }
    })
    .sort((a, b) => {
      if (actionBtnSort === 1) {
        return a.price;
      } else if (actionBtnSort === 2) {
        return numberFormatDeleteDots(a.price) - numberFormatDeleteDots(b.price);
      } else if (actionBtnSort === 3) {
        return numberFormatDeleteDots(b.price) - numberFormatDeleteDots(a.price);
      }
    });
  // console.log('searchactionMenuCate', filterAllSim);
  setSimDataSort([...filterAllSim]);
};
