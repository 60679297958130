import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';

import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import SimCardIcon from '@mui/icons-material/SimCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import styles from './digishop.module.scss';
import config from '../../config';
import auth from '../../config/auth';
import { Link } from 'react-router-dom';
import { Badge, Grid, IconButton, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { ThemeContext } from '../../component/ThemeContext/ThemeContext';
import * as userIDService from '../../services/apiUsers';
const cx = classNames.bind(styles);

function About() {
  const hostname = window.location.hostname;
  const [authDomain, setAuthDomain] = useState({});
  const fetchApiUserID = async () => {
    const resultAPISIM = await userIDService.listUserID(hostname);
    if (resultAPISIM.length > 0) {
      setAuthDomain(resultAPISIM[0]);
    }
  };
  useEffect(() => {
    fetchApiUserID();
    document.title = 'Cách thức đặt sim và giao dịch';
  });
  return (
    <div className={cx('mt-60')}>
      <div className={cx('inner')}>
        <Grid container item>
          <Grid item md={6} xs={12} sx={{ mb: 2, mt: -5, ml: 'auto', mr: 'auto', zIndex: 10 }}>
            <Paper
              elevation={3}
              className={cx('box-shadow-menu')}
              sx={{ p: '4px 2px', display: 'flex', alignItems: 'center' }}
            >
              <Grid item md={4} xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Badge color={'default'} overlap="circular" badgeContent=" " variant="dot">
                    <IconButton className={cx('no-active-cate')} aria-label="directions" component={Link} to="/">
                      <SimCardIcon className={cx('color-cate')} />
                    </IconButton>
                  </Badge>
                  <b>Sim Số</b>
                </Box>
              </Grid>

              <Grid item md={4} xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Badge color={'default'} overlap="circular" badgeContent=" " variant="dot">
                    <IconButton
                      className={cx('no-active-cate')}
                      aria-label="directions"
                      component={Link}
                      to="/kiem-tra-goi-cuoc-vinaphone"
                    >
                      <SystemSecurityUpdateGoodIcon className={cx('color-cate')} />
                    </IconButton>
                  </Badge>
                  <b>Kiểm Tra Gói Cước</b>
                </Box>
              </Grid>

              <Grid item md={4} xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Badge color={'success'} overlap="circular" badgeContent=" " variant="dot">
                    <IconButton className={cx('active-cate')} aria-label="directions" component={Link} to="/about">
                      <LocalShippingIcon className={cx('color-cate')} />
                    </IconButton>
                  </Badge>
                  <b>Giao Dịch</b>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Accordion expanded={true} sx={{ mt: '65px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>
            <b>HƯỚNG DẪN MUA SIM</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={cx('guide-order-sim')}>
            <p>
              <b>Bước 1: ĐẶT SIM</b>
              <br />
              Quý khách điền đầy đủ thông tin và đặt mua, hoặc liên hệ {authDomain['HOTTLINE_SIM']} để được đặt hàng.
            </p>
            <p>
              <b>Bước 2: XÁC NHẬN ĐƠN HÀNG</b>
              <br />
              Khi nhận được đơn hàng của quý khách, nhân viên bán hàng sẽ kiểm tra số trong kho của chúng tôi và gọi
              điện lại báo cho quý khách về tình trạng sim quý khách đã đặt mua.
            </p>
            <p>
              <b>Bước 3 &amp; 4: ĐĂNG KÝ THÔNG TIN VÀ GIAO SIM</b>
              <br />▪ Cách 1: Giao sim tận nhà miễn phí trong ngày
              <br />– Chúng tôi sẽ có giao dịch viên giao sim và đăng ký chính chủ tại nơi quý khách đang ở hiện tại.
              <br />– Quý khách kiểm tra đúng chính chủ, đúng số sim quý khách mới phải thanh toán
            </p>
            <p>
              ▪ Cách 2: Nhận sim tại cửa hàng Viettel, Vinaphone, Mobifone, Vietnammobile, Itelcom gần nhất
              <br />– Giao dịch viên của chúng tôi sẽ cùng quý khách ra điểm giao dịch gần nhất để giao dịch.
              <br />– Quý khách kiểm tra đúng chính chủ, đúng số sim quý khách mới phải thanh toán.
            </p>
            <p>
              ▪ Cách 3: Đặt cọc và thanh toán tiền còn lại khi nhận sim đối sim giá dưới 1tr: Quý khách đảm bảo việc mua
              hàng bằng cách đặt cọc tối thiểu 10% giá trị sim qua chuyển khoản hoặc mã thẻ cào. Chúng tôi sẽ gửi bưu
              điện phát sim đến tay quý khách và thu tiền còn lại (Hệ thống bưu điện trên cả nước đều cung cấp dịch vụ
              thu hộ tiền cho người bán – gọi tắt là COD. Theo đó, bưu điện sẽ giao hàng (sim) đến tận tay quý khách và
              thu tiền cho chúng tôi)
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default About;
